// @ts-nocheck
import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "./LeftAccordian.scss";

export const LeftAccordian: React.FC = () => {
  return (
    <div className="Apps">
      <Tabs>
        <TabList>
          <Tab>
             Turn Ideas Into Reality
          </Tab>
          <Tab>
             Revolutionize Your Meetings
          </Tab>
          <Tab>
             Elevate your teaching experience
          </Tab>
          <Tab>
             Design With Ease
          </Tab>
          <Tab>
             Seamless Integrations
          </Tab>
        </TabList>

        <TabPanel>
          <div className="panel-content">
            <div className="leftUlLiData new">
              <p>Effortlessly brainstorm, collaborate and bring your ideas to life with eFlok!</p>
              <ul>
                <li> <img loading="lazy"
                    src={`${window.location.origin}/suCArrow.svg`}
                    alt="EfloK canvas board"
                  />Unleash creativity: Collaborate effortlessly and bring ideas to life with eFlok.</li>
                <li> <img loading="lazy"
                    src={`${window.location.origin}/suCArrow.svg`}
                    alt="EfloK canvas board"
                  />Real-time collaboration: Work together seamlessly, regardless of location, to turn ideas into action.</li>
                <li> <img loading="lazy"
                    src={`${window.location.origin}/suCArrow.svg`}
                    alt="EfloK canvas board"
                  />Visualize concepts: Use mind maps, diagrams, and sketches to make ideas tangible.</li>
                <li><img loading="lazy"
                    src={`${window.location.origin}/suCArrow.svg`}
                    alt="EfloK canvas board"
                  />Foster innovation: Encourage creativity and problem-solving with a platform designed for ideation.</li>
                <li> <img loading="lazy"
                    src={`${window.location.origin}/suCArrow.svg`}
                    alt="EfloK canvas board"
                  />Streamline workflows: From idea generation to execution, eFlok streamlines collaboration for efficient progress.</li>
              </ul>
            </div>
            <div className="imageInfoTab">
            <img
              loading="lazy"
              src={`${window.location.origin}/leftAccordian/Turn-Ideas-into-Reality.png`}
              alt="EfloK canvas board"
            />
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="panel-content">
          <div className="leftUlLiData new">
            <p>From remote team meetings to workshops, eFlok has everything you need to make your meetings productive and engaging!
            </p>
              <ul>
                <li> <img loading="lazy"
                    src={`${window.location.origin}/suCArrow.svg`}
                    alt="EfloK canvas board"
                  />Boost productivity: Transform your meetings into productive sessions using eFlok's collaborative features and tools.</li>
                <li> <img loading="lazy"
                    src={`${window.location.origin}/suCArrow.svg`}
                    alt="EfloK canvas board"
                  />Engage participants: Create engaging workshops and training sessions with eFlok's interactive widgets and real-time collaboration.
                  </li>
                <li> <img loading="lazy"
                    src={`${window.location.origin}/suCArrow.svg`}
                    alt="EfloK canvas board"
                  />Seamless remote collaboration: Connect and collaborate effortlessly with remote team members, ensuring effective communication and engagement.</li>
                <li><img loading="lazy"
                    src={`${window.location.origin}/suCArrow.svg`}
                    alt="EfloK canvas board"
                  />Efficient agenda management: Organize and manage meeting agendas within eFlok, keeping discussions focused and goals on track.</li>
                <li> <img loading="lazy"
                    src={`${window.location.origin}/suCArrow.svg`}
                    alt="EfloK canvas board"
                  />Track action items: Assign and monitor action items from meetings, fostering accountability and progress on tasks.
                  </li>
              </ul>
            </div>
            <div className="imageInfoTab">
            <img
              loading="lazy"
              src={`${window.location.origin}/leftAccordian/Revolutionize-Your-Meetings.png`}
              alt="EfloK canvas board"
            />
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="panel-content">
          <div className="leftUlLiData new">
            <p>eFlok offers a powerful digital whiteboard solution for online classes and remote learning that helps you deliver an interactive and immersive learning experience to your students!
             </p>
              <ul>
                <li> <img loading="lazy"
                    src={`${window.location.origin}/suCArrow.svg`}
                    alt="EfloK canvas board"
                  />Engage students: Deliver an interactive learning experience with eFlok's digital whiteboard solution.
                  </li>
                <li> <img loading="lazy"
                    src={`${window.location.origin}/suCArrow.svg`}
                    alt="EfloK canvas board"
                  />Immersive teaching: Create engaging online classes and remote learning experiences using eFlok's powerful tools.</li>
                <li> <img loading="lazy"
                    src={`${window.location.origin}/suCArrow.svg`}
                    alt="EfloK canvas board"
                  />Foster collaboration: Encourage student interaction and group work through eFlok's collaborative environment.</li>
                <li><img loading="lazy"
                    src={`${window.location.origin}/suCArrow.svg`}
                    alt="EfloK canvas board"
                  />Visualize concepts: Enhance understanding with visual elements, diagrams, and multimedia integration.</li>
                <li> <img loading="lazy"
                    src={`${window.location.origin}/suCArrow.svg`}
                    alt="EfloK canvas board"
                  />Flexible instruction: Adapt teaching methods to different learning styles using eFlok's versatile features.
                  </li>
              </ul>
            </div>
            <div className="imageInfoTab">
            <img
              loading="lazy"
              src={`${window.location.origin}/leftAccordian/Elevate-your-teaching-experience.png`}
              alt="EfloK canvas board"
            />
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="panel-content">
          <div className="leftUlLiData new">
            <p>Unleash your creativity and design like a pro with eFlok's powerful and intuitive design features!
             </p>
              <ul>
                <li> <img loading="lazy"
                    src={`${window.location.origin}/suCArrow.svg`}
                    alt="EfloK canvas board"
                  />Unleash creativity: Tap into your creative potential and design like a professional using eFlok's powerful features.</li>
                <li> <img loading="lazy"
                    src={`${window.location.origin}/suCArrow.svg`}
                    alt="EfloK canvas board"
                  />Intuitive design tools: Access a wide range of intuitive design tools that make it easy to create visually stunning designs.</li>
                <li> <img loading="lazy"
                    src={`${window.location.origin}/suCArrow.svg`}
                    alt="EfloK canvas board"
                  />Seamless collaboration: Collaborate with team members or clients in real-time to create designs together, regardless of location.</li>
                <li><img loading="lazy"
                    src={`${window.location.origin}/suCArrow.svg`}
                    alt="EfloK canvas board"
                  />Versatility and flexibility: Design various assets, including presentations, graphics, and prototypes, with ease and flexibility.
                  </li>
                <li> <img loading="lazy"
                    src={`${window.location.origin}/suCArrow.svg`}
                    alt="EfloK canvas board"
                  />Professional results: Achieve professional-quality designs and presentations that make a lasting impression.</li>

              </ul>
            </div>
            <div className="imageInfoTab">
            <img loading="lazy"
              src={`${window.location.origin}/leftAccordian/Design-With-Ease.png`}
              alt="EfloK canvas board"
            />
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="panel-content">
          <div className="leftUlLiData new">
            <p>eFlok integrates seamlessly with all your favorite productivity tools and platforms, making it the ultimate collaboration tool for modern businesses and teams!</p>
              <ul>
                <li> <img loading="lazy"
                    src={`${window.location.origin}/suCArrow.svg`}
                    alt="EfloK canvas board"
                  />Connect productivity tools: Integrate eFlok with your favorite productivity tools for streamlined collaboration.</li>
                <li> <img loading="lazy"
                    src={`${window.location.origin}/suCArrow.svg`}
                    alt="EfloK canvas board"
                  />Streamline workflows: Seamlessly incorporate eFlok into your existing workflow, eliminating the need for multiple applications.</li>
                <li> <img loading="lazy"
                    src={`${window.location.origin}/suCArrow.svg`}
                    alt="EfloK canvas board"
                  />Centralized collaboration: Bring all team collaboration efforts together by integrating eFlok with preferred platforms.</li>
                <li><img loading="lazy"
                    src={`${window.location.origin}/suCArrow.svg`}
                    alt="EfloK canvas board"
                  />Boost efficiency: Access and work on eFlok boards within familiar productivity tools, enhancing productivity.
                  </li>
                <li> <img loading="lazy"
                    src={`${window.location.origin}/suCArrow.svg`}
                    alt="EfloK canvas board"
                  />Modern collaboration: Stay up to date with the latest technologies through eFlok's seamless integrations.</li>
              </ul>
            </div>
            <div className="imageInfoTab">
            <img
              loading="lazy"
              src={`${window.location.origin}/leftAccordian/Seamless-Integrations.png`}
              alt="EfloK canvas board"
            />
            </div>
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
};
