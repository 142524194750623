// @ts-nocheck
import { Backdrop, Fade, Modal } from "@material-ui/core";
import React, { useContext, useEffect, useRef, useState } from "react";
import "./boardDetailsModal.scss";
import { getParticularRoomInfo } from "../../appState";
import { getFullCollabLink } from "../../excalidraw-app/data";
import { APIService } from "../../services/api/api-service";
import { URLS } from "../../constants/urls";
import { HTTP_RESPONSE } from "../../enums/http-responses.enum";
import { updateRoomInfo } from "../../services/dashboard-service";
import { TeamContext } from "../../context/TeamContext";
import { randomId } from "../../random";
import { getUserDetails } from "../../services/auth-service";
import { Redirect } from "react-router-dom";
import { ROUTES } from "../../constants/constants";
import { duplicateBoardFromDashboard } from "../../excalidraw-app/data/firebase";

export const BoardDetailsModal: React.FC<any> = ({
  setOpenBoardDetails,
  openBoardDetails,
  eventDetails,
  setIsUserModal,
  setLeaveBoardModal,
}) => {
  const context = useContext(TeamContext);
  const { organisation } = context.state;
  const { setOrganisation } = context;
  const inputRef = useRef(null);
  const [boardDetails, setBoardDetails] = useState<any>({});
  const [isOwner, setIsOwner] = useState<boolean>(false);
  const [thumbnail, setThumbnail] = useState<any>(
    `${window.location.origin}/logoCard.svg`,
  );

  const handleCloseBoard = () => {
    setOpenBoardDetails(false);
  };

  useEffect(() => {
    getRoomInfo();
  }, []);

  const getRoomInfo = () => {
    getParticularRoomInfo(eventDetails.meetingId).then(async (eventDetail) => {
      setBoardDetails(eventDetail);
      if (eventDetail.thumbnail) {
        setThumbnail(
          `https://sharewhiteboard.s3.amazonaws.com/${eventDetail.thumbnail}`,
        );
      }
      setIsOwner(eventDetails.initator == getUserDetails()._id);
    });
  };

  const handleFileChange = async (event: any) => {
    const file = event.target.files[0];
    setThumbnail(URL.createObjectURL(file));
    const formData = new FormData();
    formData.append("document", file, file.name);
    formData.append("roomId", boardDetails.meetingId);
    const response = await APIService.Instance.post(
      URLS.ROOM_UPDATE_THUMBNAIL,
      formData,
      {},
    );
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      updateData();
    }
  };

  const handleBlur = async () => {
    const res = await updateRoomInfo(
      boardDetails.meetingId,
      boardDetails.eventName,
      boardDetails.eventDescription,
    );
    getRoomInfo();
    updateData();
  };

  const updateData = () => {
    organisation.lastClickAt = randomId();
    setOrganisation(organisation);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="custommodal board-details-main"
      open={openBoardDetails}
      onClose={handleCloseBoard}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openBoardDetails}>
        <div
          className="dialog__container-3utJq dialog__container_medium-1XKqH dialogContainer-1rEQ0"
          data-testid="board-info-modal"
        >
          <div className="dialog__contentWrapper-2gM-U">
            <div
              className="dialog__content-3J1HW dialogContent-2HqoA"
              no-prevent-default="true"
              data-testid="dialog-container-content"
            >
              <div className="dialogBody-3cYqk dialogBody-1JG91">
                <div className="picture-1o61S pictureEditable-36EMh">
                  <div className="image-main">
                    <img src={thumbnail} alt="" />
                  </div>
                  {isOwner && (
                    <button
                      type="button"
                      className="c-dZpov c-dZpov-gURzhh-variant-secondary"
                      onClick={() => {
                        inputRef?.current.click();
                      }}
                    >
                      Change thumbnail
                    </button>
                  )}

                  {isOwner && (
                    <input
                      ref={inputRef}
                      style={{ display: "none" }}
                      className="file-upload"
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                    />
                  )}
                </div>
                <div className="info-2-jne">
                  <div>
                    <input
                      className="boardInfoTitle-zbPz5 input-1AYRN"
                      disabled={!isOwner}
                      type="text"
                      value={boardDetails.eventName}
                      onChange={(e) => {
                        setBoardDetails({
                          ...boardDetails,
                          eventName: e.target.value,
                        });
                      }}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div>
                    <textarea
                      disabled={!isOwner}
                      className="descriptionInput-22szU input-1AYRN"
                      placeholder="Description"
                      defaultValue={boardDetails.eventDescription}
                      onChange={(e) => {
                        setBoardDetails({
                          ...boardDetails,
                          eventDescription: e.target.value,
                        });
                      }}
                      onBlur={handleBlur}
                    />
                  </div>
                  <div className="table all">
                    <div className="tr tr-2k_fL trBoardClassification-eFHdz"></div>
                    <div className="tr tr-2k_fL">
                      <span className="th th-24PiA">Owner</span>
                      <span className="td td-2DUow">
                        <span>
                          {boardDetails && boardDetails.initatorName
                            ? boardDetails.initatorName
                            : "-"}
                        </span>
                      </span>
                    </div>
                    <div className="tr tr-2k_fL">
                      <span className="th th-24PiA">Created</span>
                      <span className="td td-2DUow">
                        <span>
                          {boardDetails && boardDetails.createdAt
                            ? new Date(boardDetails.createdAt).toDateString()
                            : "-"}
                        </span>
                      </span>
                    </div>
                    <div className="tr tr-2k_fL">
                      <span className="th th-24PiA">Last modified</span>
                      <span className="td td-2DUow">
                        <span>
                          {boardDetails && boardDetails.updatedAt
                            ? new Date(boardDetails.updatedAt).toDateString()
                            : "-"}
                        </span>
                      </span>
                    </div>
                    {/*<div className="tr tr-2k_fL">*/}
                    {/*      <span*/}
                    {/*        data-testid="board-info-modal-location"*/}
                    {/*        className="th th-24PiA"*/}
                    {/*      >*/}
                    {/*        Location*/}
                    {/*      </span>*/}
                    {/*  <div className="td td-2DUow tdLocation-3s3u1">*/}
                    {/*        <span data-testid="board-info-modal-team-name">*/}
                    {/*          Test,{" "}*/}
                    {/*        </span>*/}
                    {/*    <span*/}
                    {/*      className="projectNameLink-3fXVk"*/}
                    {/*      data-testid="board-info-modal-project-name"*/}
                    {/*    >*/}
                    {/*          no project*/}
                    {/*        </span>*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                  </div>
                </div>
                <div className="dialogFooter-1F-MR">
                  <div className="deleteBtn-YYx-9">
                    <button
                      data-testid="board-info-modal-delete"
                      type="button"
                      className="c-elTseK c-elTseK-exQvpM-size-small c-elTseK-BjOby-cv c-elTseK-dNQwFp-cv"
                      onClick={() => {
                        handleCloseBoard();
                        setLeaveBoardModal(true)
                      }}
                    >
                      <span>Leave Board</span>
                    </button>
                  </div>

                  <div className="duplicateBtn-AcJbE">
                    <button
                      data-testid="board-info-modal-duplicate"
                      type="button"
                      className="c-elTseK c-elTseK-exQvpM-size-small c-elTseK-BjOby-cv c-elTseK-dNQwFp-cv"
                      onClick={() => {
                        handleCloseBoard();
                        window.location.replace(`${ROUTES.SHAREBOARD}#room=${boardDetails.meetingId}`);
                      }}
                    >
                      <span>Enter Board</span>
                    </button>
                  </div>
                  {isOwner && (
                    <div className="shareBtn-2vyEI">
                      <button
                        data-testid="board-info-modal-share"
                        type="button"
                        className="c-elTseK c-elTseK-exQvpM-size-small c-elTseK-BjOby-cv c-elTseK-dNQwFp-cv"
                        onClick={() => {
                          handleCloseBoard();
                          setIsUserModal(true)
                        }}
                      >
                        <span>Share</span>
                      </button>
                    </div>
                  )}
                  <div className="duplicateBtn-AcJbE">
                    <button
                      data-testid="board-info-modal-duplicate"
                      type="button"
                      className="c-elTseK c-elTseK-exQvpM-size-small c-elTseK-BjOby-cv c-elTseK-dNQwFp-cv"
                      onClick={() => {
                        duplicateBoardFromDashboard(boardDetails?.meetingId?.split(",")[0], boardDetails?.meetingId?.split(",")[1]).then(res => {
                          if (res && res.status) {
                            window.location.reload();
                          }
                        });
                      }}
                    >
                      <span>Duplicate</span>
                    </button>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};
