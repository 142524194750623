import { CUSTOM_EVENTS } from "../../constants/constants";
import jwtDecode from "jwt-decode";
export class UtilityService {
  public static triggerEvent(
    eventName: string,
    element: HTMLElement | Document,
    data: any = null,
  ) {
    element.dispatchEvent(new CustomEvent(eventName, { detail: { ...data } }));
  }

  public static showNotification(type: string, message: string) {
    UtilityService.triggerEvent(CUSTOM_EVENTS.NOTIFICATION, document, {
      message,
      type,
    });
  }

  public static secondsToTime(seconds: number) {
    const h = Math.floor(seconds / 3600)
      .toString()
      .padStart(2, "0");
    const m = Math.floor((seconds % 3600) / 60)
      .toString()
      .padStart(2, "0");
    const s = Math.floor(seconds % 60)
      .toString()
      .padStart(2, "0");

    return `${h}:${m}:${s}`;
  }

  //1 =>Pass date and time, 2=>Pass only date
  public static formateDate(selectDate: any, datetime = 1) {
    var date = new Date(selectDate);
    var mnth = (date.getMonth() + 1);//("0" + (date.getMonth() + 1)).slice(-2);
    var day = ("0" + date.getDate()).slice(-2);
    const hours = ("0" + date.getHours()).slice(-2);
    const minutes = ("0" + date.getMinutes()).slice(-2);

    var monthsName = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var formatedDate = [day, monthsName[mnth], date.getFullYear()].join(", ")
    var formatedTime = [hours, minutes].join(":");
    return datetime == 1 ? formatedDate + ' ' + formatedTime : formatedDate;
  }

  public static formateDateStrtoYMD(selectDate: any) {
    const yyyy = selectDate.getFullYear();
    var mm: any = selectDate.getMonth() + 1; // Months start at 0!
    var dd: any = selectDate.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const formattedToday = yyyy + '-' + mm + '-' + dd;

    return formattedToday;
  }

  public static capitalizeFirstLetter(string = "") {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  }


  public static getJWTExpiryTime(token: string) {
    const decodedToken: any = jwtDecode(token);
    const expTime = decodedToken?.exp;
    return expTime;
  }
}
