import { AppState } from "../types";
import { ButtonIconSelect } from "../components/ButtonIconSelect";
import { ColorPicker } from "../components/ColorPicker";
import { IconPicker } from "../components/IconPicker";
import {
  ArrowheadArrowIcon,
  ArrowheadBarIcon,
  ArrowheadDotIcon,
  ArrowheadNoneIcon,
  EdgeRoundIcon,
  EdgeSharpIcon,
  FillCrossHatchIcon,
  FillHachureIcon,
  FillSolidIcon,
  SloppinessArchitectIcon,
  SloppinessArtistIcon,
  SloppinessCartoonistIcon,
  StrokeStyleDashedIcon,
  StrokeStyleDottedIcon,
  StrokeStyleSolidIcon,
  StrokeWidthIcon,
  TextAlignCenterIcon,
  TextAlignLeftIcon,
  TextAlignRightIcon,
} from "../components/icons";
import {
  DEFAULT_FONT_FAMILY,
  DEFAULT_FONT_SIZE,
} from "../constants";
import {
  getNonDeletedElements,
  isTextElement,
  redrawTextBoundingBox,
} from "../element";
import { mutateElement, newElementWith } from "../element/mutateElement";
import { isBoundToContainer, isLinearElement, isLinearElementType } from "../element/typeChecks";
import {
  Arrowhead,
  ExcalidrawElement,
  ExcalidrawLinearElement,
  ExcalidrawTextElement,
  FontFamily,
  FontStyle,
  FontWeight, orderedBy,
  TextBaseLine,
} from "../element/types";
import { getLanguage, t } from "../i18n";
import { randomInteger } from "../random";
import {
  canChangeSharpness,
  canHaveArrowheads,
  getCommonAttributeOfSelectedElements,
  getTargetElements,
  isSomeElementSelected,
  getSelectedElements,
} from "../scene";
import Scene from "../scene/Scene";
import { register } from "./register";
import { arrayToMap } from "../utils";
import { getBoundTextElementId, getContainerElement } from "../element/textElement";
import { BoldIconSelect } from "../components/BoldIconSelect";
import {
  boldIcon,
  italicIcon, orderedList,
  strikeIcon,
  underLineIcon, unOrderedList,
} from "../defaultIcons";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FontFamilySelectDropdown } from "../components/FontFamilySelectDropdown";
import { KEYS } from "../keys";
import { trackEvent } from "../analytics";
const FONT_SIZE_RELATIVE_INCREASE_STEP = 0.1;

interface styles {
  fontStyle: FontStyle;
  fontWeight: FontWeight;
  textBaseLine: TextBaseLine;
  baseline: number;
  orderedBy: orderedBy;
}

const changeProperty = (
  elements: readonly ExcalidrawElement[],
  appState: AppState,
  callback: (element: ExcalidrawElement) => ExcalidrawElement,
  includeBoundText = false,
) => {
  const selectedElementIds = arrayToMap(
    getSelectedElements(elements, appState, {
      includeBoundTextElement: includeBoundText,
    }),
  );

  return elements.map((element) => {
    if (
      selectedElementIds.get(element.id) ||
      element.id === appState.editingElement?.id
    ) {
      return callback(element);
    }
    return element;
  });
};

const getFormValue = function <T>(
  elements: readonly ExcalidrawElement[],
  appState: AppState,
  getAttribute: (element: ExcalidrawElement) => T,
  defaultValue: T,
): T {
  const editingElement = appState.editingElement;
  const nonDeletedElements = getNonDeletedElements(elements);
  return (
    (editingElement && getAttribute(editingElement)) ??
    (isSomeElementSelected(nonDeletedElements, appState)
      ? getCommonAttributeOfSelectedElements(
        nonDeletedElements,
        appState,
        getAttribute,
      )
      : defaultValue) ??
    defaultValue
  );
};

const offsetElementAfterFontResize = (
  prevElement: ExcalidrawTextElement,
  nextElement: any,
) => {
  if (isBoundToContainer(nextElement)) {
    return nextElement;
  }
  return mutateElement(
    nextElement,
    {
      x:
        prevElement.textAlign === "left"
          ? prevElement.x
          : prevElement.x +
          (prevElement.width - nextElement.width) /
          (prevElement.textAlign === "center" ? 2 : 1),
      // centering vertically is non-standard, but for Excalidraw I think
      // it makes sense
      y: prevElement.y + (prevElement.height - nextElement.height) / 2,
    },
    false,
  );
};
const changeFontSize = (
  elements: readonly ExcalidrawElement[],
  appState: AppState,
  getNewFontSize: (element: ExcalidrawTextElement) => number,
  fallbackValue?: ExcalidrawTextElement["fontSize"],
) => {
  const newFontSizes = new Set<number>();

  return {
    elements: changeProperty(
      elements,
      appState,
      (oldElement) => {
        if (isTextElement(oldElement)) {
          const newFontSize = getNewFontSize(oldElement);
          newFontSizes.add(newFontSize);

          let newElement: ExcalidrawTextElement = newElementWith(oldElement, {
            fontSize: newFontSize,
          });
          redrawTextBoundingBox(newElement, getContainerElement(oldElement));

          newElement = offsetElementAfterFontResize(oldElement, newElement);

          return newElement;
        }

        return oldElement;
      },
      true,
    ),
    appState: {
      ...appState,
      // update state only if we've set all select text elements to
      // the same font size
      currentItemFontSize:
        newFontSizes.size === 1
          ? [...newFontSizes][0]
          : fallbackValue ?? appState.currentItemFontSize,
    },
    commitToHistory: true,
  };
};

export const getStickyNoteTextElement = (element: any) => {
  const boundTextElementId = getBoundTextElementId(element);
  const elm = Scene.getScene(element).getNonDeletedElem().filter(
    (fl) => fl.id === boundTextElementId,
  );
  if (elm && elm.length) {
    return elm[0];
  }
  return undefined;
};


export const actionChangeStrokeColor = register({
  name: "changeStrokeColor",
  trackEvent: false,
  perform: (elements, appState, value) => {
    let highlighterDefaultColor = appState.highlighterDefaultColor;
    // if (appState.elementType === "highlighterPen") {
    //   highlighterDefaultColor = value;
    // }
    return {
      elements: changeProperty(elements, appState, (el) =>
        newElementWith(el, {
          strokeColor: value,
        }),
      ),
      appState: {
        ...appState,
        currentItemStrokeColor: value,
        highlighterDefaultColor,
      },
      commitToHistory: true,
    };
  },
  PanelComponent: ({ elements, appState, updateData }) => (
    <>
      {/* <h3 aria-hidden="true">{t("labels.stroke")}</h3> */}
      <ColorPicker
        type="elementStroke"
        label={t("labels.stroke")}
        color={getFormValue(
          elements,
          appState,
          (element) => element.strokeColor,
          appState.currentItemStrokeColor,
        )}
        onChange={updateData}
      />
    </>
  ),
});

export const actionChangeFontColor = register({
  name: "changeFontColor",
  trackEvent: false,
  perform: (elements, appState, value) => {
    let highlighterDefaultColor = appState.highlighterDefaultColor;
    // if (appState.elementType === "highlighterPen") {
    //   highlighterDefaultColor = value;
    // }

    return {
      elements: changeProperty(
        elements,
        appState,
        (el) => {
          return isTextElement(el)
            ? newElementWith(el, { strokeColor: value })
            : el;
        },
        true,
      ),
      appState: {
        ...appState,
        currentItemStrokeColor: value,
        highlighterDefaultColor,
      },
      commitToHistory: true,
    };
  },
  PanelComponent: ({ elements, appState, updateData }) => (
    <>
      {/* <h3 aria-hidden="true">{t("labels.stroke")}</h3> */}
      <ColorPicker
        type="elementFontColor"
        label={t("labels.font")}
        color={getFormValue(
          elements,
          appState,
          // (element) => element.strokeColor,
          (element) => {
            let elem = element;
            if (getBoundTextElementId(element)) {
              if (getStickyNoteTextElement(element)) {
                elem = getStickyNoteTextElement(element);
              }
            }
            return isTextElement(elem) && elem.strokeColor;
          },
          appState.currentItemStrokeColor,
        )}
        onChange={updateData}
      />
    </>
  ),
});

export const actionChangeBackgroundColor = register({
  name: "changeBackgroundColor",
  trackEvent: false,
  perform: (elements, appState, value) => {
    return {
      elements: changeProperty(elements, appState, (el) =>
        newElementWith(el, {
          backgroundColor: value,
        }),
      ),
      appState: { ...appState, currentItemBackgroundColor: value },
      commitToHistory: true,
    };
  },
  PanelComponent: ({ elements, appState, updateData }) => (
    <>
      <ColorPicker
        type="elementBackground"
        label={t("labels.background")}
        color={getFormValue(
          elements,
          appState,
          (element) => element.backgroundColor,
          appState.currentItemBackgroundColor,
        )}
        onChange={updateData}
      />
    </>
  ),
});

export const actionChangeFillStyle = register({
  name: "changeFillStyle",
  trackEvent: false,
  perform: (elements, appState, value, app) => {
    trackEvent(
      "element",
      "changeFillStyle",
      `${value} (${app.device.isMobile ? "mobile" : "desktop"})`,
    );
    return {
      elements: changeProperty(elements, appState, (el) =>
        newElementWith(el, {
          fillStyle: value,
        }),
      ),
      appState: { ...appState, currentItemFillStyle: value },
      commitToHistory: true,
    };
  },
  PanelComponent: ({ elements, appState, updateData }) => (
    <fieldset>
      {/* <legend>{t("labels.fill")}</legend> */}
      <ButtonIconSelect
        options={[
          {
            value: "hachure",
            text: t("labels.hachure"),
            icon: <FillHachureIcon appearance={appState.appearance} />,
          },
          {
            value: "cross-hatch",
            text: t("labels.crossHatch"),
            icon: <FillCrossHatchIcon appearance={appState.appearance} />,
          },
          {
            value: "solid",
            text: t("labels.solid"),
            icon: <FillSolidIcon appearance={appState.appearance} />,
          },
        ]}
        group="fill"
        value={getFormValue(
          elements,
          appState,
          (element) => element.fillStyle,
          appState.currentItemFillStyle,
        )}
        onChange={(value) => {
          updateData(value);
        }}
      />
    </fieldset>
  ),
});

export const actionChangeStrokeWidth = register({
  name: "changeStrokeWidth",
  trackEvent: false,
  perform: (elements, appState, value) => {
    return {
      elements: changeProperty(elements, appState, (el) =>
        newElementWith(el, {
          strokeWidth: value,
        }),
      ),
      appState: { ...appState, currentItemStrokeWidth: value },
      commitToHistory: true,
    };
  },
  PanelComponent: ({ elements, appState, updateData }) => (
    <fieldset>
      {/* <legend>{t("labels.strokeWidth")}</legend> */}
      <ButtonIconSelect
        group="strokeWidth"
        options={[
          {
            value: 1,
            text: t("labels.thin"),
            icon: (
              <StrokeWidthIcon
                appearance={appState.appearance}
                strokeWidth={2}
              />
            ),
          },
          {
            value: 2,
            text: t("labels.bold"),
            icon: (
              <StrokeWidthIcon
                appearance={appState.appearance}
                strokeWidth={6}
              />
            ),
          },
          {
            value: 4,
            text: t("labels.extraBold"),
            icon: (
              <StrokeWidthIcon
                appearance={appState.appearance}
                strokeWidth={10}
              />
            ),
          },
        ]}
        value={getFormValue(
          elements,
          appState,
          (element) => element.strokeWidth,
          appState.currentItemStrokeWidth,
        )}
        onChange={(value) => updateData(value)}
      />
    </fieldset>
  ),
});

export const actionChangeSloppiness = register({
  name: "changeSloppiness",
  trackEvent: false,
  perform: (elements, appState, value) => {
    return {
      elements: changeProperty(elements, appState, (el) =>
        newElementWith(el, {
          seed: randomInteger(),
          roughness: value,
        }),
      ),
      appState: { ...appState, currentItemRoughness: value },
      commitToHistory: true,
    };
  },
  PanelComponent: ({ elements, appState, updateData }) => (
    <fieldset>
      {/* <legend>{t("labels.sloppiness")}</legend> */}
      <ButtonIconSelect
        group="sloppiness"
        options={[
          {
            value: 0,
            text: t("labels.architect"),
            icon: <SloppinessArchitectIcon appearance={appState.appearance} />,
          },
          {
            value: 1,
            text: t("labels.artist"),
            icon: <SloppinessArtistIcon appearance={appState.appearance} />,
          },
          {
            value: 2,
            text: t("labels.cartoonist"),
            icon: <SloppinessCartoonistIcon appearance={appState.appearance} />,
          },
        ]}
        value={getFormValue(
          elements,
          appState,
          (element) => element.roughness,
          appState.currentItemRoughness,
        )}
        onChange={(value) => updateData(value)}
      />
    </fieldset>
  ),
});

export const actionChangeStrokeStyle = register({
  name: "changeStrokeStyle",
  trackEvent: false,
  perform: (elements, appState, value) => {
    return {
      elements: changeProperty(elements, appState, (el) =>
        newElementWith(el, {
          strokeStyle: value,
        }),
      ),
      appState: { ...appState, currentItemStrokeStyle: value },
      commitToHistory: true,
    };
  },
  PanelComponent: ({ elements, appState, updateData }) => (
    <fieldset>
      {/* <legend>{t("labels.strokeStyle")}</legend> */}
      <ButtonIconSelect
        group="strokeStyle"
        options={[
          {
            value: "solid",
            text: t("labels.strokeStyle_solid"),
            icon: <StrokeStyleSolidIcon appearance={appState.appearance} />,
          },
          {
            value: "dashed",
            text: t("labels.strokeStyle_dashed"),
            icon: <StrokeStyleDashedIcon appearance={appState.appearance} />,
          },
          {
            value: "dotted",
            text: t("labels.strokeStyle_dotted"),
            icon: <StrokeStyleDottedIcon appearance={appState.appearance} />,
          },
        ]}
        value={getFormValue(
          elements,
          appState,
          (element) => element.strokeStyle,
          appState.currentItemStrokeStyle,
        )}
        onChange={(value) => updateData(value)}
      />
    </fieldset>
  ),
});

export const actionChangeOpacity = register({
  name: "changeOpacity",
  trackEvent: false,
  perform: (elements, appState, value) => {
    return {
      elements: changeProperty(elements, appState, (el) =>
        newElementWith(el, {
          opacity: value,
        }),
      ),
      appState: { ...appState, currentItemOpacity: value },
      commitToHistory: true,
    };
  },
  PanelComponent: ({ elements, appState, updateData }) => (
    <label className="control-label">
      {/* {t("labels.opacity")} */}
      <input
        type="range"
        min="0"
        max="100"
        step="10"
        onChange={(event) => updateData(+event.target.value)}
        onWheel={(event) => {
          event.stopPropagation();
          const target = event.target as HTMLInputElement;
          const STEP = 10;
          const MAX = 100;
          const MIN = 0;
          const value = +target.value;

          if (event.deltaY < 0 && value < MAX) {
            updateData(value + STEP);
          } else if (event.deltaY > 0 && value > MIN) {
            updateData(value - STEP);
          }
        }}
        value={
          getFormValue(
            elements,
            appState,
            (element) => element.opacity,
            appState.currentItemOpacity,
          ) ?? undefined
        }
      />
    </label>
  ),
});

export const actionChangeFontSize = register({
  name: "changeFontSize",
  trackEvent: false,
  perform: (elements, appState, value) => {
    return changeFontSize(elements, appState, () => value, value);
    // const newFontSizes = new Set<number>();
    //
    // return {
    //   elements: changeProperty(
    //     elements,
    //     appState,
    //     (el) => {
    //       if (isTextElement(el)) {
    //         let element: ExcalidrawTextElement = newElementWith(el, {
    //           fontSize: value,
    //         });
    //         newFontSizes.add(value);
    //         redrawTextBoundingBox(element, getContainerElement(el));
    //
    //         element = offsetElementAfterFontResize(el, element);
    //
    //         return element;
    //       }
    //       return el;
    //     },
    //     true,
    //   ),
    //   appState: {
    //     ...appState,
    //     // update state only if we've set all select text elements to
    //     // the same font size
    //     currentItemFontSize:
    //       newFontSizes.size === 1
    //         ? [...newFontSizes][0]
    //         : value ?? appState.currentItemFontSize,
    //   },
    //   commitToHistory: true,
    // };
  },
  PanelComponent: ({ elements, appState, updateData }) => {
    const options = [
      { value: 10, title: "10" },
      { value: 12, title: "12" },
      { value: 14, title: "14" },
      { value: 18, title: "18" },
      { value: 24, title: "24" },
      { value: 36, title: "36" },
      { value: 48, title: "48" },
      { value: 64, title: "64" },
      { value: 80, title: "80" },
      { value: 144, title: "144" },
      { value: 288, title: "288" },
    ];

    let value = getFormValue(
      elements,
      appState,
      (element) => {
        let elem = element;
        if (element.type === "stickyNote") {
          if (getStickyNoteTextElement(element)) {
            elem = getStickyNoteTextElement(element);
          }
        }
        return isTextElement(elem) && elem.fontSize;
      },
      appState.currentItemFontSize || DEFAULT_FONT_SIZE,
    );

    if (!value) {
      value = appState.currentItemFontSize;
    }

    const handleUp = () => {
      const index = options.findIndex((p) => p.value == value);
      if (options[index + 1]) {
        value = options[index + 1].value;
        updateData(options[index + 1].value);
      }
    };

    const handleDown = () => {
      const index = options.findIndex((p) => p.value == value);
      if (options[index - 1]) {
        value = options[index - 1].value;
        updateData(options[index - 1].value);
      }
    };

    return (
      <fieldset className="fontSizeTool">
        {/* <legend>{t("labels.fontSize")}</legend> */}
        <Autocomplete
          disableClearable
          disableListWrap
          openOnFocus
          id="free-solo-demo"
          freeSolo
          value={value.toString()}
          onChange={(e, newValue) => updateData(newValue)}
          options={options.map((option) => option.title)}
          renderInput={(params) => (
            <TextField {...params} margin="none" variant="outlined" />
          )}
        />

        {/*<SelectDropdown*/}
        {/*  group="font-size"*/}
        {/*  options={options}*/}
        {/*  value={value}*/}
        {/*  onChange={(value) => updateData(value)}*/}
        {/*/>*/}
        <div>
          <img
            width="20"
            height="20"
            src={`${window.location.origin}/upArrow.png`}
            alt="Increase"
            className="upArrow"
            onClick={handleUp}
          />
          <img
            width="20"
            height="20"
            src={`${window.location.origin}/downArrow.png`}
            alt="Decrease"
            className="downArrow"
            onClick={handleDown}
          />
        </div>
      </fieldset>
    );
  },
});

export const actionChangeFontFamily = register({
  name: "changeFontFamily",
  trackEvent: false,
  perform: (elements, appState, value) => {
    return {
      elements: changeProperty(
        elements,
        appState,
        (oldElement) => {
          if (isTextElement(oldElement)) {
            const newElement: ExcalidrawTextElement = newElementWith(oldElement, {
              fontFamily: value,
              lineHeight: 1.25 as ExcalidrawTextElement["lineHeight"],
            });
            redrawTextBoundingBox(newElement, getContainerElement(oldElement));
            return newElement;
          }

          return oldElement;
        },
        true,
      ),
      appState: {
        ...appState,
        currentItemFontFamily: value,
      },
      commitToHistory: true,
    };
  },
  PanelComponent: ({ elements, appState, updateData }) => {
    const options: { value: FontFamily; text: string }[] = [
      { value: 13, text: "Abril Fatface" },
      { value: 1, text: "Arial" },
      { value: 14, text: "Bangers" },
      { value: 2, text: "Brush Script MT" },
      { value: 15, text: "Caveat" },
      { value: 3, text: "Courier New" },
      { value: 4, text: "Cascadia" },
      { value: 16, text: "EB Garamond" },
      { value: 17, text: "Fredoka One" },
      { value: 5, text: "Garamond" },
      { value: 6, text: "Georgia" },
      { value: 18, text: "Graduate" },
      { value: 19, text: "IBM Plex Mono" },
      { value: 20, text: "Lemon Tuesday" },
      { value: 21, text: "Nixie One" },
      { value: 22, text: "Noto Sans" },
      { value: 23, text: "OpenSans" },
      { value: 24, text: "Permanent Marker" },
      { value: 25, text: "Rammetto One" },
      { value: 26, text: "Roboto" },
      { value: 27, text: "Spoof" },
      { value: 7, text: "Helvetica" },
      { value: 8, text: "Tahoma" },
      { value: 9, text: "Times New Roman" },
      { value: 10, text: "Trebuchet MS" },
      { value: 11, text: "Verdana" },
      { value: 12, text: "Virgil" },
    ];

    return (
      <fieldset className="fontFamilyTool">
        {/* <legend>{t("labels.fontFamily")}</legend> */}

        <FontFamilySelectDropdown<FontFamily | false>
          group="font-family"
          options={options}
          value={getFormValue(
            elements,
            appState,
            (element) => {
              let elem = element;
              // if (element.type === "stickyNote") {
              if (getBoundTextElementId(element)) {
                if (getStickyNoteTextElement(element)) {
                  elem = getStickyNoteTextElement(element);
                }
              }
              return isTextElement(elem) && elem.fontFamily;
            },
            appState.currentItemFontFamily || DEFAULT_FONT_FAMILY,
          )}
          onChange={(value) => {
            updateData(value);
          }}
        />
      </fieldset>
    );
  },
});

export const actionChangeFontWeight = register({
  name: "changeFontWeight",
  trackEvent: false,
  perform: (elements, appState, value) => {
    return {
      elements: changeProperty(
        elements,
        appState,
        (el) => {
          if (isTextElement(el)) {
            const style = {
              fontWeight: el.fontWeight,
              fontStyle: el.fontStyle,
              textBaseLine: el.textBaseLine,
              baseline: el.baseline,
            } as styles;
            if (value === "bold") {
              if (el.fontWeight === "bold") {
                style.fontWeight = "normal";
              } else if (el.fontWeight === "normal") {
                style.fontWeight = "bold";
              }
            }
            if (value === "italic") {
              if (el.fontStyle === "italic") {
                style.fontStyle = "normal";
              } else if (el.fontStyle === "normal") {
                style.fontStyle = "italic";
              }
            }

            if (value === "underline") {
              if (el.textBaseLine === "bottom") {
                style.textBaseLine = "normal";
              } else if (
                el.textBaseLine === "normal" ||
                el.textBaseLine === "middle"
              ) {
                style.textBaseLine = "bottom";
              }
            } else if (value === "strike") {
              if (el.textBaseLine === "middle") {
                style.textBaseLine = "normal";
              } else if (
                el.textBaseLine === "normal" ||
                el.textBaseLine === "bottom"
              ) {
                style.textBaseLine = "middle";
              }
            }

            const element: ExcalidrawTextElement = newElementWith(el, style);
            redrawTextBoundingBox(element, getContainerElement(el));
            return element;
          }

          return el;
        },
        true,
      ),
      appState: {
        ...appState,
        currentItemTextAlign: value,
      },
      commitToHistory: true,
    };
  },
  PanelComponent: ({ elements, appState, updateData }) => {
    return (
      <fieldset>
        {/* <legend>Font weight</legend> */}
        <BoldIconSelect
          group="font-weight"
          options={[
            { value: "bold", text: "Bold", icon: boldIcon },
            { value: "italic", text: "Italic", icon: italicIcon },
            { value: "underline", text: "Underline", icon: underLineIcon },
            { value: "strike", text: "Strike", icon: strikeIcon },
          ]}
          onChange={(value) => updateData(value)}
          value=""
        />
      </fieldset>
    );
  },
});

export const actionChangeFontList = register({
  name: "changeFontList",
  trackEvent: false,
  perform: (elements, appState, value) => {
    return {
      elements: changeProperty(elements, appState, (el) => {
          if (isTextElement(el)) {
            const style = { orderedBy: el.orderedBy } as styles;
            if (value === "bullet") {
              if (el.orderedBy === "bullet") {
                style.orderedBy = null;
              } else if (el.orderedBy === "number") {
                style.orderedBy = "bullet";
              } else {
                style.orderedBy = "bullet";
              }
            }
            if (value === "number") {
              if (el.orderedBy === "number") {
                style.orderedBy = null;
              } else if (el.orderedBy === "bullet") {
                style.orderedBy = "number";
              } else {
                style.orderedBy = "number";
              }
            }



            const element: ExcalidrawTextElement = newElementWith(el, style);
            let container = null;
            if (el.containerId) {
              container = Scene.getScene(el)!.getElement(el.containerId);
            }
            redrawTextBoundingBox(element, container);
            return element;
          }

          return el;
        },
        true,
      ),
      appState: {
        ...appState,
        currentItemTextAlign: value,
      },
      commitToHistory: true,
    };
  },
  PanelComponent: ({ elements, appState, updateData }) => {
    return (
      <fieldset>
        {/* <legend>Font weight</legend> */}
        <BoldIconSelect
          group="font-weight"
          options={[
            { value: "bullet", text: "Bold", icon: unOrderedList },
            { value: "number", text: "Italic", icon: orderedList },
          ]}
          onChange={(value) => updateData(value)}
          value=""
        />
      </fieldset>
    );
  },
});

// @ts-ignore
export const actionChangeTextAlign = register({
  name: "changeTextAlign",
  perform: (elements, appState, value) => {
    return {
      elements: changeProperty(
        elements,
        appState,
        (el) => {
          if (isTextElement(el)) {
            const element: ExcalidrawTextElement = newElementWith(el, {
              textAlign: value,
            });
            let container = null;
            if (el.containerId) {
              container = Scene.getScene(el)!.getElement(el.containerId);
            }
            redrawTextBoundingBox(element, container);
            return element;
          }

          return el;
        },
        true,
      ),
      appState: {
        ...appState,
        currentItemTextAlign: value,
      },
      commitToHistory: true,
    };
  },
  PanelComponent: ({ elements, appState, updateData }) => (
    <fieldset>
      {/* <legend>{t("labels.textAlign")}</legend> */}
      <ButtonIconSelect
        group="text-align"
        options={[
          {
            value: "left",
            text: t("labels.left"),
            icon: <TextAlignLeftIcon appearance={appState.appearance} />,
          },
          {
            value: "center",
            text: t("labels.center"),
            icon: <TextAlignCenterIcon appearance={appState.appearance} />,
          },
          {
            value: "right",
            text: t("labels.right"),
            icon: <TextAlignRightIcon appearance={appState.appearance} />,
          },
        ]}
        value={getFormValue(
          elements,
          appState,
          (element) => isTextElement(element) && element.textAlign,
          appState.currentItemTextAlign,
        )}
        onChange={(value) => updateData(value)}
      />
    </fieldset>
  ),
});

export const actionChangeSharpness = register({
  name: "changeSharpness",
  trackEvent: false,
  perform: (elements, appState, value) => {
    const targetElements = getTargetElements(
      getNonDeletedElements(elements),
      appState,
    );
    const shouldUpdateForNonLinearElements = targetElements.length
      ? targetElements.every((el) => !isLinearElement(el))
      : !isLinearElementType(appState.activeTool.type);
    const shouldUpdateForLinearElements = targetElements.length
      ? targetElements.every(isLinearElement)
      : isLinearElementType(appState.activeTool.type);
    return {
      elements: changeProperty(elements, appState, (el) =>
        newElementWith(el, {
          strokeSharpness: value,
        }),
      ),
      appState: {
        ...appState,
        currentItemStrokeSharpness: shouldUpdateForNonLinearElements
          ? value
          : appState.currentItemStrokeSharpness,
        currentItemLinearStrokeSharpness: shouldUpdateForLinearElements
          ? value
          : appState.currentItemLinearStrokeSharpness,
      },
      commitToHistory: true,
    };
  },
  PanelComponent: ({ elements, appState, updateData }) => (
    <fieldset>
      {/* <legend>{t("labels.edges")}</legend> */}
      <ButtonIconSelect
        group="edges"
        options={[
          {
            value: "sharp",
            text: t("labels.sharp"),
            icon: <EdgeSharpIcon appearance={appState.appearance} />,
          },
          {
            value: "round",
            text: t("labels.round"),
            icon: <EdgeRoundIcon appearance={appState.appearance} />,
          },
        ]}
        value={getFormValue(
          elements,
          appState,
          (element) => element.strokeSharpness,
          (canChangeSharpness(appState.activeTool.type) &&
            (isLinearElementType(appState.activeTool.type)
              ? appState.currentItemLinearStrokeSharpness
              : appState.currentItemStrokeSharpness)) ||
            null,
        )}
        onChange={(value) => updateData(value)}
      />
    </fieldset>
  ),
});

export const actionChangeArrowhead = register({
  name: "changeArrowhead",
  trackEvent: false,
  perform: (
    elements,
    appState,
    value: { position: "start" | "end"; type: Arrowhead },
  ) => {
    return {
      elements: changeProperty(elements, appState, (el) => {
        if (isLinearElement(el)) {
          const { position, type } = value;

          if (position === "start") {
            const element: ExcalidrawLinearElement = newElementWith(el, {
              startArrowhead: type,
            });
            return element;
          } else if (position === "end") {
            const element: ExcalidrawLinearElement = newElementWith(el, {
              endArrowhead: type,
            });
            return element;
          }
        }

        return el;
      }),
      appState: {
        ...appState,
        [value.position === "start"
          ? "currentItemStartArrowhead"
          : "currentItemEndArrowhead"]: value.type,
      },
      commitToHistory: true,
    };
  },
  PanelComponent: ({ elements, appState, updateData }) => {
    const isRTL = getLanguage().rtl;

    return (
      <fieldset>
        {/* <legend>{t("labels.arrowheads")}</legend> */}
        <div className="iconSelectList">
          <IconPicker
            label="arrowhead_start"
            options={[
              {
                value: null,
                text: t("labels.arrowhead_none"),
                icon: <ArrowheadNoneIcon appearance={appState.appearance} />,
                keyBinding: "q",
              },
              {
                value: "arrow",
                text: t("labels.arrowhead_arrow"),
                icon: (
                  <ArrowheadArrowIcon
                    appearance={appState.appearance}
                    flip={!isRTL}
                  />
                ),
                keyBinding: "w",
              },
              {
                value: "bar",
                text: t("labels.arrowhead_bar"),
                icon: (
                  <ArrowheadBarIcon
                    appearance={appState.appearance}
                    flip={!isRTL}
                  />
                ),
                keyBinding: "e",
              },
              {
                value: "dot",
                text: t("labels.arrowhead_dot"),
                icon: (
                  <ArrowheadDotIcon
                    appearance={appState.appearance}
                    flip={!isRTL}
                  />
                ),
                keyBinding: "r",
              },
            ]}
            value={getFormValue<Arrowhead | null>(
              elements,
              appState,
              (element) =>
                isLinearElement(element) && canHaveArrowheads(element.type)
                  ? element.startArrowhead
                  : appState.currentItemStartArrowhead,
              appState.currentItemStartArrowhead,
            )}
            onChange={(value) => updateData({ position: "start", type: value })}
          />
          <IconPicker
            label="arrowhead_end"
            group="arrowheads"
            options={[
              {
                value: null,
                text: t("labels.arrowhead_none"),
                keyBinding: "q",
                icon: <ArrowheadNoneIcon appearance={appState.appearance} />,
              },
              {
                value: "arrow",
                text: t("labels.arrowhead_arrow"),
                keyBinding: "w",
                icon: (
                  <ArrowheadArrowIcon
                    appearance={appState.appearance}
                    flip={isRTL}
                  />
                ),
              },
              {
                value: "bar",
                text: t("labels.arrowhead_bar"),
                keyBinding: "e",
                icon: (
                  <ArrowheadBarIcon
                    appearance={appState.appearance}
                    flip={isRTL}
                  />
                ),
              },
              {
                value: "dot",
                text: t("labels.arrowhead_dot"),
                keyBinding: "r",
                icon: (
                  <ArrowheadDotIcon
                    appearance={appState.appearance}
                    flip={isRTL}
                  />
                ),
              },
            ]}
            value={getFormValue<Arrowhead | null>(
              elements,
              appState,
              (element) =>
                isLinearElement(element) && canHaveArrowheads(element.type)
                  ? element.endArrowhead
                  : appState.currentItemEndArrowhead,
              appState.currentItemEndArrowhead,
            )}
            onChange={(value) => updateData({ position: "end", type: value })}
          />
        </div>
      </fieldset>
    );
  },
});

export const actionDecreaseFontSize = register({
  name: "decreaseFontSize",
  trackEvent: false,
  perform: (elements, appState, value) => {
    return changeFontSize(elements, appState, (element) =>
      Math.round(
        // get previous value before relative increase (doesn't work fully
        // due to rounding and float precision issues)
        (1 / (1 + FONT_SIZE_RELATIVE_INCREASE_STEP)) * element.fontSize,
      ),
    );
  },
  keyTest: (event) => {
    return (
      event[KEYS.CTRL_OR_CMD] &&
      event.shiftKey &&
      // KEYS.COMMA needed for MacOS
      (event.key === KEYS.CHEVRON_LEFT || event.key === KEYS.COMMA)
    );
  },
});

export const actionIncreaseFontSize = register({
  name: "increaseFontSize",
  trackEvent: false,
  perform: (elements, appState, value) => {
    return changeFontSize(elements, appState, (element) =>
      Math.round(element.fontSize * (1 + FONT_SIZE_RELATIVE_INCREASE_STEP)),
    );
  },
  keyTest: (event) => {
    return (
      event[KEYS.CTRL_OR_CMD] &&
      event.shiftKey &&
      // KEYS.PERIOD needed for MacOS
      (event.key === KEYS.CHEVRON_RIGHT || event.key === KEYS.PERIOD)
    );
  },
});
