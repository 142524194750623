import clsx from "clsx";
import React from "react";
import { useState } from "react";
import { ActionManager } from "../actions/manager";
import {
  boldicon,
  borderstyle,
  curve,
  fillstyle,
  fontstyle,
  layer,
  orderedList,
  strokestyle,
  strokewidth,
} from "../defaultIcons";
import { getNonDeletedElements } from "../element";
import { ExcalidrawElement, PointerType } from "../element/types";
import { t } from "../i18n";
import useIsMobile from "../is-mobile";
import {
  canChangeSharpness,
  canHaveArrowheads,
  getTargetElements,
  hasBackground,
  hasStroke,
  hasText,
} from "../scene";
import { SHAPES } from "../shapes";
import { AppState, UIAppState, Zoom } from "../types";
import { capitalizeString, isTransparent, setCursorForShape, updateActiveTool } from "../utils";
import { ToolButton } from "./ToolButton";
import { trackEvent } from "../analytics";

export const SelectedShapeActions = ({
  appState,
  elements,
  renderAction,
  filterKey,
}: {
  appState: UIAppState;
  elements: readonly ExcalidrawElement[];
  renderAction: ActionManager["renderAction"];
  filterKey: string;
}) => {
  const targetElements = getTargetElements(
    getNonDeletedElements(elements),
    appState,
  );
  const isEditing = Boolean(appState.editingElement);
  const isMobile = useIsMobile();
  // const isRTL = document.documentElement.getAttribute("dir") === "rtl";

  type toggleStateType = {
    [key: string]: boolean;
  };
  const [toggleState, setToggleState] = useState<toggleStateType>({
    fontFamily: false,
    fontStyle: false,
    fontSize: false,
    fontWeight: false,
    textAlign: false,
    layers: false,
    strokeWidth: false,
    strokeStyle: false,
    sloppiness: false,
    fillStyle: false,
    sharpness: false,
    groupingicons: false,
    fontList: false,
  });

  const updateToggle = (key: string) => {
    const resetState: toggleStateType = {
      fontFamily: false,
      fontStyle: false,
      fontSize: false,
      fontWeight: false,
      textAlign: false,
      layers: false,
      strokeWidth: false,
      strokeStyle: false,
      sloppiness: false,
      fillStyle: false,
      sharpness: false,
      groupingicons: false,
      fontList: false,
    };
    resetState[key] = !toggleState[key];
    setToggleState({
      ...resetState,
    });
  };

  const showFillIcons =
    hasBackground(appState.activeTool.type) ||
    targetElements.some(
      (element) =>
        hasBackground(element.type) && !isTransparent(element.backgroundColor),
    );
  const showChangeBackgroundIcons =
    hasBackground(appState.activeTool.type) ||
    targetElements.some((element) => hasBackground(element.type));

  const getOperation = (
    elements: readonly ExcalidrawElement[],
  ): "lock" | "unlock" =>
    elements.some((el) => !el.locked) ? "lock" : "unlock";

  return (
    <div className="panelColumn selectionpanel">
      {getOperation(targetElements) === "lock" && !appState.isBoardLocked && (
        <>
          {(hasStroke(appState.activeTool.type) ||
            targetElements.some((element) => hasStroke(element.type))) && (
            <>
              <div className="right-border p-10">
                <ToolButton
                  className={clsx("ReactionButton")}
                  onClick={() => {
                    updateToggle("strokeWidth");
                  }}
                  icon={strokewidth}
                  type="button"
                  title={"Stroke width"}
                  aria-label={"Stroke width"}
                />
              </div>
              {toggleState.strokeWidth ? (
                <div className="toolbaricons">
                  <div className="strokeWidth-div">
                    {renderAction("changeStrokeWidth")}
                  </div>
                </div>
              ) : null}
              <div className="right-border p-10">
                <ToolButton
                  className={clsx("ReactionButton")}
                  onClick={() => {
                    updateToggle("strokeStyle");
                  }}
                  icon={strokestyle}
                  type="button"
                  title={"Stroke Style"}
                  aria-label={"Stroke Style"}
                />
              </div>
              {toggleState.strokeStyle ? (
                <div className="toolbaricons">
                  <div className="stroke-style-div">
                    {renderAction("changeStrokeStyle")}
                  </div>
                </div>
              ) : null}

              <div className="right-border p-10">
                <ToolButton
                  className={clsx("ReactionButton")}
                  onClick={() => {
                    updateToggle("sloppiness");
                  }}
                  icon={curve}
                  type="button"
                  title={"Sloppiness"}
                  aria-label={"Sloppiness"}
                />
              </div>
              {toggleState.sloppiness ? (
                <div className="toolbaricons">
                  <div className="sloppiness-div">
                    {renderAction("changeSloppiness")}
                  </div>
                </div>
              ) : null}
            </>
          )}

          {(canChangeSharpness(appState.activeTool.type) ||
            targetElements.some((element) =>
              canChangeSharpness(element.type),
            )) && (
            <div className="right-border p-10">
              <div>
                <ToolButton
                  className={clsx("ReactionButton")}
                  onClick={() => {
                    updateToggle("sharpness");
                  }}
                  icon={borderstyle}
                  type="button"
                  title={"Sharpness"}
                  aria-label={"Sharpness"}
                />
              </div>
              {toggleState.sharpness ? (
                <div className="toolbaricons">
                  <div className="sharpness-section">
                    {renderAction("changeSharpness")}
                  </div>
                </div>
              ) : null}
            </div>
          )}

          {(hasText(appState.activeTool.type) ||
            targetElements.some((element) => hasText(element.type))) && (
            <>
              {/*<div>*/}
              {/*  <ToolButton*/}
              {/*    className={clsx("ReactionButton")}*/}
              {/*    onClick={() => {*/}
              {/*      updateToggle("fontFamily");*/}
              {/*    }}*/}
              {/*    icon={fontsize}*/}
              {/*    type="button"*/}
              {/*    title={"Font size"}*/}
              {/*    aria-label={"Font size"}*/}
              {/*  ></ToolButton>*/}
              {/*</div>*/}
              {/*{toggleState.fontFamily ? (*/}
              <div className="right-border p-10">
                {/*<div className="font-family-section">*/}
                {renderAction("changeFontFamily")}
              </div>
              <div className="right-border fontSize-main">
                {renderAction("changeFontSize")}
                {/*</div>*/}
              </div>
              {/*) : null}*/}
            </>
          )}
          <div className="color-style">
            {(hasText(appState.activeTool.type) ||
              targetElements.some((element) => hasText(element.type))) &&
              renderAction("changeFontColor")}
            {(hasStroke(appState.activeTool.type) ||
              targetElements.some((element) => hasStroke(element.type))) &&
              renderAction("changeStrokeColor")}
            {showChangeBackgroundIcons && renderAction("changeBackgroundColor")}

            {/* <div className="toolbaricons">
        {renderAction("changeOpacity")}
      </div> */}
            {showFillIcons && (
              <div>
                <div style={{ paddingLeft: "5px", paddingRight: "5px" }}>
                  <ToolButton
                    className={clsx("ReactionButton")}
                    onClick={() => {
                      updateToggle("fillStyle");
                    }}
                    icon={fillstyle}
                    type="button"
                    title={"Fill style"}
                    aria-label={"Fill style"}
                  />
                </div>
                {toggleState.fillStyle ? (
                  <div className="toolbaricons">
                    <div className="fill-style-div">
                      {showFillIcons && renderAction("changeFillStyle")}
                    </div>
                  </div>
                ) : null}
              </div>
            )}
          </div>

          {(hasText(appState.activeTool.type) ||
            targetElements.some((element) => hasText(element.type))) && (
            <>
              <div className="right-border p-10">
                <div>
                  <ToolButton
                    className={clsx("ReactionButton")}
                    onClick={() => {
                      updateToggle("fontWeight");
                    }}
                    icon={boldicon}
                    type="button"
                    title={"Bold"}
                    aria-label={"Bold"}
                  />
                </div>

                {toggleState.fontWeight ? (
                  <div className="toolbaricons">
                    <div className="font-weight-div">
                      {renderAction("changeFontWeight")}
                    </div>
                  </div>
                ) : null}

                {/*<div>*/}
                {/*  <ToolButton*/}
                {/*    className={clsx("ReactionButton")}*/}
                {/*    onClick={() => {*/}
                {/*      updateToggle("fontStyle");*/}
                {/*    }}*/}
                {/*    icon={textIcon}*/}
                {/*    type="button"*/}
                {/*    title={"Font style"}*/}
                {/*    aria-label={"Font style"}*/}
                {/*  />*/}
                {/*</div>*/}
                {/*{toggleState.fontStyle ? (*/}
                {/*  <div className="toolbaricons">*/}
                {/*    <div className="font-style-div">*/}
                {/*      {renderAction("changeFontStyle")}{" "}*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*) : null}*/}

                <div>
                  <ToolButton
                    className={clsx("ReactionButton")}
                    onClick={() => {
                      updateToggle("textAlign");
                    }}
                    icon={fontstyle}
                    type="button"
                    title={"Alignment"}
                    aria-label={"Alignment"}
                  />
                </div>

                {toggleState.textAlign ? (
                  <div className="toolbaricons">
                    <div className="font-style-div">
                      {renderAction("changeTextAlign")}
                    </div>
                  </div>
                ) : null}

                <div>
                  <ToolButton
                    className={clsx("ReactionButton")}
                    onClick={() => {
                      updateToggle("fontList");
                    }}
                    icon={orderedList}
                    type="button"
                    title={"Alignment"}
                    aria-label={"Alignment"}
                  />
                </div>

                {toggleState.fontList ? (
                  <div className="toolbaricons">
                    <div className="font-style-div">
                      {renderAction("changeFontList")}
                    </div>
                  </div>
                ) : null}

                {!isMobile && !isEditing && targetElements.length > 0 && (
                  <div className="link-div">
                    {targetElements.length === 1 &&
                      targetElements?.[0].type !== "file" &&
                      renderAction("hyperlink")}
                  </div>
                )}
              </div>
            </>
          )}

          {(canHaveArrowheads(appState.activeTool.type) ||
            targetElements.some((element) =>
              canHaveArrowheads(element.type),
            )) && (
            <>
              <div className="arrow-head-main right-border p-10">
                {renderAction("changeArrowhead")}
              </div>
            </>
          )}
        </>
      )}

      {!appState.isOnlyView && (
        <fieldset className="right-border p-10">
          <div className="buttonList">{renderAction("toggleComment")}</div>
        </fieldset>
      )}

      {!appState.isBoardLocked && (
        <fieldset className="right-border p-10">
          <div className="buttonList">{renderAction("toggleLock")}</div>
        </fieldset>
      )}

      {getOperation(targetElements) === "lock" && !appState.isBoardLocked && (
        <fieldset className="right-border p-10">
          {/* <legend>{t("labels.layers")}</legend> */}
          <div className="buttonList">
            <div>
              <ToolButton
                className={clsx("ReactionButton")}
                onClick={() => {
                  updateToggle("layers");
                }}
                icon={layer}
                type="button"
                title={"Layer"}
                aria-label={"Layer"}
              />
            </div>
            {toggleState.layers ? (
              <div className="toolbaricons layer-toolbar-icon">
                <div className="layers-div">
                  {renderAction("sendToBack")}
                  {renderAction("sendBackward")}
                  {renderAction("bringToFront")}
                  {renderAction("bringForward")}
                </div>
              </div>
            ) : null}
          </div>
        </fieldset>
      )}

      {!appState.isBoardLocked && (
        <fieldset className="right-border p-10">
          <div className="buttonList">
            {renderAction("deleteSelectedElements")}
          </div>
        </fieldset>
      )}

      {/*{targetElements.length > 1 && (*/}
      {/*  <div className="right-border groupings">*/}
      {/*    <div className="p-10">*/}
      {/*      <ToolButton*/}
      {/*        className={clsx("ReactionButton")}*/}
      {/*        onClick={() => {*/}
      {/*          updateToggle("groupingicons");*/}
      {/*        }}*/}
      {/*        icon={groupicon}*/}
      {/*        type="button"*/}
      {/*        title={"Group elements"}*/}
      {/*        aria-label={"Group elements"}*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*    {toggleState.groupingicons ? (*/}
      {/*      <div className="toolbaricons">*/}
      {/*        <fieldset>*/}
      {/*          /!* <legend>{t("labels.align")}</legend> *!/*/}
      {/*          <div className="buttonList">*/}
      {/*            {*/}
      {/*              // swap this order for RTL so the button positions always match their action*/}
      {/*              // (i.e. the leftmost button aligns left)*/}
      {/*            }*/}
      {/*            {isRTL ? (*/}
      {/*              <>*/}
      {/*                {renderAction("alignRight")}*/}
      {/*                {renderAction("alignHorizontallyCentered")}*/}
      {/*                {renderAction("alignLeft")}*/}
      {/*              </>*/}
      {/*            ) : (*/}
      {/*              <>*/}
      {/*                {renderAction("alignLeft")}*/}
      {/*                {renderAction("alignHorizontallyCentered")}*/}
      {/*                {renderAction("alignRight")}*/}
      {/*              </>*/}
      {/*            )}*/}
      {/*            {targetElements.length > 2 &&*/}
      {/*              renderAction("distributeHorizontally")}*/}
      {/*            <div className="iconRow">*/}
      {/*              {renderAction("alignTop")}*/}
      {/*              {renderAction("alignVerticallyCentered")}*/}
      {/*              {renderAction("alignBottom")}*/}
      {/*              {targetElements.length > 2 &&*/}
      {/*                renderAction("distributeVertically")}*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*        </fieldset>*/}
      {/*      </div>*/}
      {/*    ) : null}*/}
      {/*  </div>*/}
      {/*)}*/}
      {/*{!isMobile && !isEditing && targetElements.length > 0 && (*/}
      {/*  <>*/}
      {/*    /!* <legend>{t("labels.actions")}</legend> *!/*/}
      {/*    <div className="buttonList right-border group-selection-div">*/}
      {/*      /!* TODO: Find better way to stop these for certain tools *!/*/}
      {/*      /!* {renderAction("duplicateSelection")} *!/*/}
      {/*      /!* {renderAction("deleteSelectedElements")} *!/*/}
      {/*      {renderAction("group")}*/}
      {/*      {renderAction("ungroup")}*/}
      {/*      /!*{targetElements.length === 1 &&*!/*/}
      {/*      /!*  targetElements?.[0].type !== "file" &&*!/*/}
      {/*      /!*  renderAction("link")}*!/*/}
      {/*    </div>*/}
      {/*  </>*/}
      {/*)}*/}
    </div>
  );
};

// const LIBRARY_ICON = (
//   // fa-th-large
//   <svg viewBox="0 0 512 512">
//     <path d="M296 32h192c13.255 0 24 10.745 24 24v160c0 13.255-10.745 24-24 24H296c-13.255 0-24-10.745-24-24V56c0-13.255 10.745-24 24-24zm-80 0H24C10.745 32 0 42.745 0 56v160c0 13.255 10.745 24 24 24h192c13.255 0 24-10.745 24-24V56c0-13.255-10.745-24-24-24zM0 296v160c0 13.255 10.745 24 24 24h192c13.255 0 24-10.745 24-24V296c0-13.255-10.745-24-24-24H24c-13.255 0-24 10.745-24 24zm296 184h192c13.255 0 24-10.745 24-24V296c0-13.255-10.745-24-24-24H296c-13.255 0-24 10.745-24 24v160c0 13.255 10.745 24 24 24z" />
//   </svg>
// );


export const ShapesSwitcher = ({
  interactiveCanvas,
  activeTool,
  setAppState,
  onImageAction,
  appState,
  filterKey,
}: {
  interactiveCanvas: HTMLCanvasElement | null;
  activeTool: UIAppState["activeTool"];
  setAppState: React.Component<any, UIAppState>["setState"];
  onImageAction: (data: { pointerType: PointerType | null }) => void;
  appState: UIAppState;
  filterKey: string;
}) => {
  return (
    <>
      {SHAPES.map(({ value, icon, key, category }, index) => {
        // @ts-ignore
        const label = t(`toolBar.${value}`);
        const categoryname = category;
        const letter = typeof key === "string" ? key : key[0];
        let shortcut = `${capitalizeString(letter)} ${t("helpDialog.or")} ${
          index + 1
        }`;
        if (index + 1 > 9) {
          shortcut = `${capitalizeString(letter)}`;
        }

        if (categoryname === filterKey) {
          return (
            <div className="whitebg">
              <ToolButton
                className={`${categoryname} Shape`}
                key={value}
                type="radio"
                icon={icon}
                checked={appState.activeTool.type === value}
                name="editor-current-shape"
                title={`${capitalizeString(label)} — ${shortcut}`}
                // keyBindingLabel={`${index}`}
                aria-label={capitalizeString(label)}
                aria-keyshortcuts={shortcut}
                data-testid={value}
                id={categoryname}
                onPointerDown={({ pointerType }) => {
                  if (!appState.penDetected && pointerType === "pen") {
                    setAppState({
                      penDetected: true,
                      penMode: true,
                    });
                  }
                }}
                onChange={({ pointerType }) => {
                  if (appState.activeTool.type !== value) {
                    trackEvent("toolbar", value, "ui");
                  }
                  const nextActiveTool = updateActiveTool(appState, {
                    type: value,
                  });
                  setAppState({
                    activeTool: nextActiveTool,
                    activeEmbeddable: null,
                    multiElement: null,
                    selectedElementIds: {},
                    isStickyNote: false,
                  });
                  setCursorForShape(interactiveCanvas, {
                    ...appState,
                    activeTool: nextActiveTool,
                  });
                  if (value === "image") {
                    onImageAction({ pointerType });
                  }
                }}
              />
            </div>
          );
        }
      })}
    </>)
}

export const ZoomActions = ({
  renderAction,
  zoom,
}: {
  renderAction: ActionManager["renderAction"];
  zoom: Zoom;
}) => (
  <div style={{ cursor: "pointer" }}>
    <nav className="header-nav ms-auto">
      <ul className="d-flex align-items-center">
        <li className="nav-item dropdown pe-3">
          <span
            className="nav-link nav-profile d-flex align-items-center pe-0"
            data-bs-toggle="dropdown"
          >
            <span className="d-md-block dropdown-toggle ps-2">
              {" "}
              {(zoom.value * 100).toFixed(0)}%{" "}
            </span>
          </span>

          <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
            <li>
              <span className="dropdown-item d-flex align-items-center">
                <span>Zoom In</span> {renderAction("zoomIn")}
              </span>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>

            <li>
              <span className="dropdown-item d-flex align-items-center">
                <span>Zoom Out</span> {renderAction("zoomOut")}{" "}
              </span>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li>
              <span className="dropdown-item d-flex align-items-center">
                <span>Reset zoom</span> {renderAction("resetZoom")}{" "}
              </span>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
    {/* <div
      className="row zoomvalue"
      onClick={() => {
        // ToDo
        // change the implementation from classes to useState
        // ToDO
        const el = document.querySelector(".zoom-actions")?.classList;
        el?.contains("d-none") ? el.remove("d-none") : el?.add("d-none");
      }}
    >
      {(zoom.value * 100).toFixed(0)}%{" "}
      <i className="fa fa-angle-down" aria-hidden="true"></i>
    </div>
    <div className="zoom-actions d-none">
      <p>
        <span>Zoom In</span> {renderAction("zoomIn")}
      </p>
      <p>
        <span>Zoom Out</span> {renderAction("zoomOut")}{" "}
      </p>
      <p>
        <span>Reset zoom</span> {renderAction("resetZoom")}{" "}
      </p>
    </div> */}
  </div>
);
