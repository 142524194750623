import React, { useEffect, useRef, useState } from "react";
import {
  editboard,
  deleteIcon,
  duplicateIcon,
  shareIcon,
  copyLinkIcon,
  annotationIcon,
  generateExternalLink,
  generateMoveIcon,
  generateProctoringIcon,
  privateBoardIcon,
  boardDetailsIcon, transferOwnershipIcon,
} from "../../../components/icons";
import { ToolButton } from "../../../components/ToolButton";
import {
  ALERT_TYPE,
  CONSTANT,
  ROUTES,
} from "../../../constants/constants";
import { getUserDetails } from "../../../services/auth-service";
import { DownloadRecording } from "../../download-recording/DownloadRecording";
import { UserModal } from "../../user-modal/userModal";
import { VideoPlayer } from "../../video-player/VideoPlayer";
import "../dashboardMain/dashboardMain.scss";
import { UtilityService } from "../../../services/common/utilityService";
import { duplicateBoardFromDashboard } from "../../../excalidraw-app/data/firebase";
import { MoveBoardModal } from "../../move-board-modal/moveBoardModal";
import { useHistory } from "react-router-dom";
import { ExternalLink } from "../../external-link-generation/ExternalLink";
import { PrivateBoardModal } from "../../private-board-modal/privateBoardModal";
import { BoardDetailsModal } from "../../boardDetails/boardDetailsModal";
import { DeleteBoardModal } from "../../delete-board-modal/deleteBoardModal";
import { LeaveBoard } from "../../leave-board-modal/leaveBoard";
import { TransferOwnership } from "../../transfer-ownership/transferOwnership";
import { DeleteRecordingModal } from "../../delete-recording/deleteRecording";
import { getFullCollabLink } from "../../../excalidraw-app/data";

type MessageProps = {
  children?: React.ReactNode;
  roomInfo: any;
  changeIsFav: any;
  resumeMeetingBoard: any;
  setPassword: any;
  meetFilters: any;
  setMeetFilters: any;
};

export const MeetingCardList: React.FC<MessageProps> = ({
  roomInfo,
  changeIsFav,
  resumeMeetingBoard,
  setPassword,
  meetFilters,
  setMeetFilters,
}: MessageProps) => {
  const history = useHistory();
  const [isDownloadRecording, setIsDownloadRecording] = useState<boolean>(
    false,
  );
  const [isUserModal, setIsUserModal] = useState<boolean>(false);
  const [openBoardDetails, setOpenBoardDetails] = useState<boolean>(false);
  const [transferOwnershipModal, setTransferOwnershipModal] = useState<boolean>(false);
  const [openPrivateBoardModal, setOpenPrivateBoardModal] = useState<boolean>(
    false,
  );
  const [openDeleteBoardModal, setOpenDeleteBoardModal] = useState<boolean>(
    false,
  );
  const [recordingsArr, setRecordingsArr] = useState<string[]>([]);
  const [isVideoPlayerActive, setIsVideoPlayerActive] = useState<boolean>(
    false,
  );
  const [annotation, setAnnotaion] = useState<any[]>([]);
  const [toggleCard, setToggle] = useState(false);

  const [isMoveBoardModal, setIsMoveBoardModal] = useState<boolean>(false);
  const [leaveBoardModal, setLeaveBoardModal] = useState<boolean>(false);
  const [openExternalLinkModal, setOpenExternalLinkModal] = useState<boolean>(
    false,
  );
  const [deleteRecordingModal, setDeleteRecordingModal] = useState<any>(null);


  const isfav = roomInfo.collaborators.filter(
    (cb: any) =>
      cb.collaboratorId === getUserDetails()._id && cb.isFav === true,
  );

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setToggle(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  return (
    <>
      <div className="meetingcard">
        <div
          className="textcontent"
          onClick={(e) => {
            resumeMeetingBoard(roomInfo);
          }}
        >
          {/* <p
            className="title"
            title={roomInfo.eventName ? roomInfo.eventName : ""}
          >
            {roomInfo.eventName ? roomInfo.eventName : "Board Title"}
          </p>
          <p className="subtitle">
            {roomInfo.eventDescription
              ? roomInfo.eventDescription
              : "Board Description"}
          </p> */}
          <img
            style={{ width: 50 }}
            src={
              roomInfo.thumbnail
                ? `https://sharewhiteboard.s3.amazonaws.com/${roomInfo.thumbnail}`
                : `${window.location.origin}/logoCard.svg`
            }
            alt=""
          />
        </div>
        <p className="time">
          {/* {roomInfo.eventDate ? new Date(roomInfo.eventDate).toString() : "-NA-"} */}
          {roomInfo.eventName ? roomInfo.eventName : ""}
        </p>
        <div className="groupIconData" onClick={() => setToggle(!toggleCard)}>
          <img src={`${window.location.origin}/groupIcon.svg`} alt="" />
        </div>
        {toggleCard && (
          <div className="lockbtn" ref={wrapperRef}>
            <>
              {/*<div onClick={() => {*/}
              {/*  updateLockStatus(roomInfo);*/}
              {/*  setToggle(false);*/}
              {/*}}>*/}
              {/*  <ToolButton*/}
              {/*    key="lockboard"*/}
              {/*    type="button"*/}
              {/*    title={*/}
              {/*      roomInfo.isLocked*/}
              {/*        ? "Enable Edit for collaborators"*/}
              {/*        : "Disable Edit for collaborators"*/}
              {/*    }*/}
              {/*    aria-label={*/}
              {/*      roomInfo.isLocked*/}
              {/*        ? "Enable Edit for collaborators"*/}
              {/*        : "Disable Edit for collaborators"*/}
              {/*    }*/}
              {/*    className={"text-success"}*/}
              {/*    icon={editboard}*/}
              {/*  />*/}
              {/*  Edit board*/}
              {/*</div>*/}
              {/*<ToolButton*/}
              {/*  key="password"*/}
              {/*  type="button"*/}
              {/*  title={*/}
              {/*    roomInfo.isProtected*/}
              {/*      ? "Remove board password"*/}
              {/*      : "Protect your board with a secured password"*/}
              {/*  }*/}
              {/*  aria-label={*/}
              {/*    roomInfo.isProtected*/}
              {/*      ? "Remove board password"*/}
              {/*      : "Protect your board with a secured password"*/}
              {/*  }*/}
              {/*  icon={roomInfo.isProtected ? UNLOCK : LOCKICON}*/}
              {/*  onClick={() => {*/}
              {/*    setPassword();*/}
              {/*  }}*/}
              {/*/>*/}
              {/* <ToolButton
                  key="share"
                  type="button"
                  title="Share Meeting Link"
                  aria-label="Share Meeting Link"
                  icon={roomInfo.isProtected ? share : share}
                /> */}

                <div
                  onClick={() => {
                    setOpenBoardDetails(true);
                    setToggle(false);
                  }}
                >
                  <ToolButton
                    key="lockboard"
                    type="button"
                    title="Board Details"
                    aria-label="Board Details"
                    className={"text-success"}
                    icon={boardDetailsIcon}
                  />
                  Board Details
                </div>

              {roomInfo.initator == getUserDetails()._id && (
                <div
                  onClick={() => {
                    setIsUserModal(true);
                    setToggle(false);
                  }}
                >
                  <ToolButton
                    key="lockboard"
                    type="button"
                    title="Share"
                    aria-label="Share"
                    className={"text-success"}
                    icon={shareIcon}
                  />
                  Share
                </div>
              )}
              <div
                onClick={() => {
                  const link = `${process.env.REACT_APP_DOMAIN}/board#room=${roomInfo?.meetingId}`;
                  navigator.clipboard.writeText(link);
                  UtilityService.showNotification(
                    ALERT_TYPE.SUCCESS,
                    "Link copied to clipboard.",
                  );
                  setToggle(false);
                }}
              >
                <ToolButton
                  key="lockboard"
                  type="button"
                  title="Copy board link"
                  aria-label="Copy board link"
                  className={"text-success"}
                  icon={copyLinkIcon}
                />
                Copy board link
              </div>

              <div
                onClick={() => {
                  changeIsFav(roomInfo?.meetingId);
                  setToggle(false);
                }}
              >
                <ToolButton
                  key="lockboard"
                  type="button"
                  title="Download Recording"
                  aria-label="Download Recording"
                  icon={
                    <i
                      className={isfav.length ? "fa fa-star" : "fa fa-star-o"}
                    ></i>
                  }
                />
                {isfav.length ? "Unmark from starred" : "Mark as starred"}
              </div>

              <div
                onClick={() => {
                  setIsDownloadRecording(true);
                  setToggle(false);
                }}
              >
                <ToolButton
                  key="lockboard"
                  type="button"
                  title="Download Recording"
                  aria-label="Download Recording"
                  icon={annotationIcon}
                />
                Audio annotations
              </div>
              <div
                onClick={() => {
                  setToggle(false);
                  const uri = `${process.env.REACT_APP_DOMAIN}/board#room=${roomInfo?.meetingId}`;
                  window.open(uri, "_blank");
                }}
              >
                <ToolButton
                  key="duplicateBoard"
                  type="button"
                  title="Open Board In New Tab"
                  aria-label="Open Board In New Tab"
                  icon={generateExternalLink}
                />
                Open In New Tab
              </div>
              <div
                onClick={() => {
                  setToggle(false);
                  duplicateBoardFromDashboard(
                    roomInfo?.meetingId?.split(",")[0],
                    roomInfo?.meetingId?.split(",")[1],
                  ).then((res) => {
                    if (res && res.status) {
                      window.location.reload();
                    }
                  });
                }}
              >
                <ToolButton
                  key="duplicateBoard"
                  type="button"
                  title="Duplicate Board"
                  aria-label="Duplicate Board"
                  icon={duplicateIcon}
                />
                Duplicate
              </div>
              {roomInfo.initator == getUserDetails()._id && (
                <div
                  onClick={() => {
                    setOpenPrivateBoardModal(true);
                    setToggle(false);
                  }}
                >
                  <ToolButton
                    key="privateBoard"
                    type="button"
                    title="Make Board Private"
                    aria-label="Make Board Private"
                    icon={privateBoardIcon}
                  />
                  Make board private
                </div>
              )}
              {roomInfo.initator == getUserDetails()._id && (
                <div
                  onClick={() => {
                    setTransferOwnershipModal(true);
                    setToggle(false);
                  }}
                >
                  <ToolButton
                    key="transferOwnership"
                    type="button"
                    title="Transfer Ownership"
                    aria-label="Transfer Ownership"
                    icon={transferOwnershipIcon}
                  />
                  Transfer Ownership
                </div>
              )}
              {roomInfo.initator == getUserDetails()._id && (
                <div
                  onClick={() => {
                    setOpenDeleteBoardModal(true);
                    setToggle(false);
                  }}
                >
                  <ToolButton
                    key="deleteBoard"
                    type="button"
                    title="Delete Board"
                    aria-label="Delete Board"
                    icon={deleteIcon}
                  />
                  Delete
                </div>
              )}
              {roomInfo.initator == getUserDetails()._id && (
                <div
                  onClick={() => {
                    setOpenExternalLinkModal(true);
                    setToggle(false);
                  }}
                >
                  <ToolButton
                    key="lockboard"
                    type="button"
                    title="Copy board link"
                    aria-label="Copy board link"
                    className={"text-success"}
                    icon={editboard}
                  />
                  External link
                </div>
              )}
              {roomInfo.initator == getUserDetails()._id && (<div
                onClick={() => {
                  setIsMoveBoardModal(true);
                  setToggle(false);
                }}
              >
                <ToolButton
                  key="lockboard"
                  type="button"
                  title="Move Board"
                  aria-label="Move Board"
                  className={"text-success"}
                  icon={generateMoveIcon}
                />
                Move Board
              </div>)}
              {(roomInfo.isProctoring == "true" ||
                roomInfo.isProctoring == "false") && (
                <div
                  onClick={() => {
                    localStorage.setItem(
                      CONSTANT.PROCTORING_BOARD,
                      roomInfo?.meetingId,
                    );
                    history.push(ROUTES.PROCTORING_BOARDS);
                  }}
                >
                  <ToolButton
                    key="lockboard"
                    type="button"
                    title="View Proctoring Board List"
                    aria-label="Copy board link"
                    className={"text-success"}
                    icon={generateProctoringIcon}
                  />
                  Proctoring Board
                </div>
              )}
            </>
          </div>
        )}
      </div>

      {isUserModal ? (
        <UserModal
          isFromCard={true}
          setIsUserModal={setIsUserModal}
          meetingId={roomInfo.meetingId}
          recordingsArr={recordingsArr}
        />
      ) : null}
      {isDownloadRecording ? (
        <DownloadRecording
          isFromCard={true}
          setIsDownloadRecording={setIsDownloadRecording}
          meetingId={roomInfo.meetingId}
          roomInfo={roomInfo}
          recordingsArr={recordingsArr}
          setOpenDeleteModal={setDeleteRecordingModal}
        />
      ) : null}

      {deleteRecordingModal ? (
        <DeleteRecordingModal
          openDeleteModal={deleteRecordingModal}
          setOpenDeleteModal={setDeleteRecordingModal}
          roomInfo={roomInfo}
        />
      ) : null}

      {isVideoPlayerActive ? (
        <VideoPlayer
          setIsVideoPlayerActive={setIsVideoPlayerActive}
          recordingsArr={recordingsArr}
          annotation={annotation}
        />
      ) : null}
      {isMoveBoardModal ? (
        <MoveBoardModal
          isFromCard={true}
          setIsMoveBoardModal={setIsMoveBoardModal}
          meetingId={roomInfo.meetingId}
          meetFilters={meetFilters}
          setMeetFilters={setMeetFilters}
        />
      ) : null}
      {openExternalLinkModal ? (
        <ExternalLink
          openExternalLinkModal={openExternalLinkModal}
          setOpenExternalLink={setOpenExternalLinkModal}
          meetingId={roomInfo.meetingId}
          meetingData={roomInfo}
        />
      ) : null}

      {openPrivateBoardModal ? (
        <PrivateBoardModal
          openPrivateBoardModal={openPrivateBoardModal}
          setOpenPrivateBoardModal={setOpenPrivateBoardModal}
          meetingId={roomInfo.meetingId}
        />
      ) : null}

      {openBoardDetails ? (
        <BoardDetailsModal
          setOpenBoardDetails={setOpenBoardDetails}
          openBoardDetails={openBoardDetails}
          eventDetails={roomInfo}
          setIsUserModal={setIsUserModal}
          setLeaveBoardModal={setLeaveBoardModal}
        />
      ) : null}

      {openDeleteBoardModal ? (

        <DeleteBoardModal
          openDeleteBoardModal={openDeleteBoardModal}
          setOpenDeleteBoardModal={setOpenDeleteBoardModal}
          meetingId={roomInfo.meetingId}
        />
      ) : null}

      {leaveBoardModal ? (
        <LeaveBoard
          leaveBoardModal={leaveBoardModal}
          setLeaveBoardModal={setLeaveBoardModal}
          meetingId={roomInfo.meetingId}
        />
      ) : null}

      {transferOwnershipModal ? (
        <TransferOwnership transferOwnershipModal={transferOwnershipModal} setTransferOwnershipModal={setTransferOwnershipModal} meetingId={roomInfo.meetingId}/>
      ) : null}
    </>
  );
};
