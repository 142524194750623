import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { ROUTES } from "../../constants/constants";
import "./home.scss";
import "../../css/app.scss";
import "../../css/styles.scss";
import { HomeHeader } from "./homenavbar";
import { HomeFooter } from "./homefooter";
import { ImageSliderClient } from "../../magnum-components/ImageSliderClient/ImageSliderClient";
import { LeftAccordian } from "../../magnum-components/LeftAccordian/LeftAccordian";
import SimpleSlider from "../../magnum-components/ImageSliderBanner/ImageSliderBanner";

export const Homescreen: React.FC = () => {
  const [redirect, setRedirect] = useState<boolean>(false);
  useEffect(() => {
    // APIService.Instance.getToken() !== null && setRedirect(true);
  }, []);

  if (redirect) {
    // @ts-ignore
    return <Redirect to={ROUTES.DASHBOARD} />
  }

  return (
    <>
      <div className="px-0 mainwrapper homenavbar">
        <HomeHeader />
        <div className="bannerwrapper">
          <div className="banner-content">
            <div className="outerDataBnr">
              <div className="boxshadow">
                <SimpleSlider/>
              </div>
              <div className="banner-btn">
                <Link to={ROUTES.DASHBOARD} className="ban-btn">
                  Start collaborating
                </Link>
              </div>
            </div>
            <div className="row">
            <img loading="lazy"
              className="w-100"
              src={`${window.location.origin}/bannerSlideNew.jpg`}
              alt=""
            />
          </div>
          </div>
        </div>
        <div className="workTogetherInfo">
          <div className="bgDataInfoNew">
            <div className="container">
              <div className="infoInrData">
                <div className="conteData">
                  <h3>Transform <b>Learning</b> <br/> with eFlok</h3>
                  <p>From universities to corporate training, eFlok revolutionizes the way you learn. Our platform enables you to collaborate, create, and engage with your team, so you can achieve your goals faster.</p>
                  <Link to={ROUTES.LEARNING}>
                      <a>Click here to transform your learning
                    <img loading="lazy"
                        src={`${window.location.origin}/Arrow2.svg`}
                        alt=""
                      /></a>
                    </Link>
                </div>
                <div className="overview">
                <img
                  loading="lazy"
                  src={`${window.location.origin}/Transform-Learning-with-eFlok.jpg`}
                  alt=""
                />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="centerWrapper">
          <div className="connectData">
            <div className="connectLeft">
                  <img
                    loading="lazy"
                    src={`${window.location.origin}/Power-Up-Your-Creativity-With-eFlok.jpg`}
                    alt=""
                  />
            </div>
            <div className="connectLeft">
                  <div className="conteData">
                    <h3>Power Up Your  <b>Creativity</b>, <br/> with eFlok</h3>
                    <p>Unleash your team's creativity and bring your ideas to life with eFlok. Our platform provides the tools you need to design, prototype, and iterate, all in one place.</p>
                    <Link to={ROUTES.LEARNING2}>
                      <a>Click here to power up your creativity. <img style={{width:16, marginLeft:10}}
                        src={`${window.location.origin}/Arrow2.svg`}
                        alt=""
                      /></a>
                    </Link>
                  </div>
            </div>
          </div>
        </div>
        <div className="productFeature">
          <div className="center-wrapper">
            <h3>Product <b>Features</b></h3>
            <div className="featureCardData">
              <div className="cardCntWdth">
                <div className="cardIconInf">
                  <div className="cardIconwd">
                      <img loading="lazy"
                      src={`${window.location.origin}/useIcon1.svg`}
                      alt=""
                    />
                  </div>
                  <h4>Seamless integration with Learning Management Systems</h4>
                  <p>Integrate eFlok with your existing LMS for streamlined collaboration and enhanced productivity.</p>

                </div>
              </div>
              <div className="cardCntWdth">
                <div className="cardIconInf">
                  <div className="cardIconwd">
                      <img loading="lazy"
                      src={`${window.location.origin}/useIcon2.svg`}
                      alt=""
                    />
                  </div>
                  <h4>Video conferencing</h4>
                  <p>eFlok's integrated video conferencing feature allows you to connect with your team, customers, and partners from anywhere in the world. Conduct virtual meetings and collaborate on your board, all without leaving the platform.</p>

                </div>
              </div>
              <div className="cardCntWdth">
                <div className="cardIconInf">
                  <div className="cardIconwd">
                      <img loading="lazy"
                      src={`${window.location.origin}/useIcon3.svg`}
                      alt=""
                    />
                  </div>
                  <h4>Cross-platform functionality for maximum flexibility</h4>
                  <p>Access eFlok on any device, anytime, anywhere, and enjoy seamless collaboration with team members regardless of their location or device.</p>

                </div>
              </div>
              <div className="cardCntWdth">
                <div className="cardIconInf">
                  <div className="cardIconwd">
                      <img loading="lazy"
                      src={`${window.location.origin}/useIcon4.svg`}
                      alt=""
                    />
                  </div>
                  <h4>Comment and tag features to streamline communication</h4>
                  <p>Improve communication and increase efficiency with eFlok's powerful comment and tag features, allowing you to easily collaborate with team members.</p>

                </div>
              </div>
              <div className="cardCntWdth">
                <div className="cardIconInf">
                  <div className="cardIconwd">
                      <img loading="lazy"
                      src={`${window.location.origin}/useIcon5.svg`}
                      alt=""
                    />
                  </div>
                  <h4>Customizable templates</h4>
                  <p>With eFlok's customizable templates, you can create boards that fit your specific workflow needs. Choose from a variety of templates, or create your own from scratch, and streamline your team's collaboration process.</p>

                </div>
              </div>
              <div className="cardCntWdth">
                <div className="cardIconInf">
                  <div className="cardIconwd">
                      <img loading="lazy"
                      src={`${window.location.origin}/useIcon6.svg`}
                      alt=""
                    />
                  </div>
                  <h4>Project management</h4>
                  <p>With eFlok's project management features, you can keep your projects on track from start to finish. Set deadlines, assign tasks, and track progress all from within your board, and keep your team in sync with powerful collaboration tools.</p>
                </div>
              </div>
            </div>
            <div className="testimonialInfo">
              <h3>Why Choose  <b>The Product</b></h3>
              <LeftAccordian />
            </div>
          </div>
        </div>
        <div className="testimonialsData">
          <section className="w-100">
            <h1>Our <b>Testimonials</b></h1>
            <div className="sliderDataPart">
              <div className="container">
                <ImageSliderClient />
              </div>
            </div>
          </section>
        </div>
        <HomeFooter />
      </div>

    </>
  );
};
