// @ts-nocheck
import React, { useEffect, useContext, useState } from "react";
import "./DashboardHeader.scss";
import { getOrganisationDetails, getUserDetails, signOut } from "../../../services/auth-service";
import { APIService } from "../../../services/api/api-service";
import { Link, Redirect, useHistory } from "react-router-dom";
import { ALERT_TYPE, ROUTES } from "../../../constants/constants";
import { Dialog, DialogContent, DialogProps, Slide } from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { ProfileLayout } from "../../profile/profilelayout";
import { TeamContext } from '../../../context/TeamContext'
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";

import { Backdrop, Fade, Modal } from "@material-ui/core";
import {
  addTeamMembers,
  sendOrganisationUserInvitationApi,
  checkOrgAccess,
  getNotificationList, readNotification, changePasswordApi, sendOrganisationUserInvitationApiCSV
} from "../../../services/v2/team/team-service";
import { advanceSearch } from "../../../services/v2/search/search-service.ts";
import { randomId } from "../../../random";
import { NotificationsIcon } from "../Notifications/Notifications";
import Papa from "papaparse";
import { UtilityService } from "../../../services/common/utilityService";
import { ORGANISATION_ACCESS } from "../../../constants/constants";
import { CommonSocketService } from "../../../services/socket-services/common-socket.service";

interface SelectedOrgUser {
  organisationUserList?: any,
}

const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children?: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
  ) => {
    return <Slide direction="up" ref={ref} {...props} />;
  },
);

export const DashboardHeader: React.FC<SelectedOrgUser> = (props) => {
  const history = useHistory();

  const context = useContext(TeamContext)
  const { team, teamListArr, organisation } = context.state
  const { setTeam, setTeamListArr, setOrganisation } = context

  const [open, setOpen] = React.useState(false);
  const [openAddMember, setOpenAddMember] = React.useState(false);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("lg");
  const [permissionType, setPermissionType] = React.useState('can-edit');
  const [audioSelected, setAudioSelected] = React.useState(true);
  const [isCheckAll, setIsCheckAll] = useState<boolean>(false);
  const [isCheck, setIsCheck] = useState<any>([]);
  const [inviteOrgMemberVisble, setInviteOrgMemberVisble] = React.useState(false);
  const [inviteTeamMemberVisble, setInviteTeamMemberVisble] = React.useState(true);
  const [openNotification, setOpenNotification] = React.useState(false);

  const [submitButtonDisplay, setSubmitButtonDisplay] = React.useState(false);
  const [isDisplayBackButton, setIsDisplayBackButton] = React.useState(false);

  const [notifications, setNotifications] = React.useState([]);
  const [searchResult, setSearchResult] = React.useState([]);

  var selectedOrganisation = getOrganisationDetails();
  const currentLoginUser = getUserDetails();
  const commonSocketService = CommonSocketService.commonEventService;

  useEffect(() => {
    //if (organisation && organisation?._id) {
    if (selectedOrganisation && selectedOrganisation?.organisationId?._id) {
      (async () => {
        const checkOrg = await checkOrgAccess(selectedOrganisation);
        setInviteOrgMemberVisble(checkOrg)
      })();
    } else {
      setInviteOrgMemberVisble(false);
    }

    //Hide dashboard invite team members popup
    var currentRoute = history.location.pathname;
    if (currentRoute == ROUTES.DASHBOARD || currentRoute == ROUTES.ORGANISATION_USERS || currentRoute == ROUTES.ORGANISATION_INVITATION || currentRoute == ROUTES.SELECTED_PROJECT) {
      setInviteTeamMemberVisble(false);
    }

    //Back Button Visible code
    if (currentRoute == ROUTES.ORGANISATION_PROFILE || currentRoute == ROUTES.ORGANISATION_USERS || currentRoute == ROUTES.ORGANISATION_INVITATION || currentRoute == ROUTES.TEAM_PROFILE || currentRoute == ROUTES.TEAM_USERS) {
      setIsDisplayBackButton(true);
    }
  }, [organisation]);

  const joinGroupFun = () => {
    commonSocketService.joinGroup(
      {
        group: "eflok_common_channel",
        _id: currentLoginUser._id,
        name: currentLoginUser.name,
        userName: currentLoginUser.username,
      },
      (response: any) => {
        console.log(response);
      },
    );
  };

  /*Notification Part start*/
  useEffect(() => {
    commonSocketService.initChatRoom(joinGroupFun);
    commonSocketService.updateNotification((data: any) => {
      if (data.users.includes(getUserDetails()._id)) {
        notificationList();
      }
    });
    commonSocketService.getUpdateNotification((data: any) => {
      console.log(data);
      if (currentLoginUser && data.data.includes(currentLoginUser.username)) {
        console.log(data);
        notificationList();
      }
    });
    notificationList();
  }, []);

  const notificationList = () => {
    getNotificationList().then((res) => {
      if (res.status) {
        setNotifications(res.data);
      }
    });
  }

  const markAllAsRead = () => {
    readNotification().then((res) => {
      notificationList();
    })
    setOpenNotification(false);
  }

  /*Notification Part end*/

  const signOutFun = () => {
    const resp = signOut();
    if (resp === true) {
      window.location.reload();
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const openCraeteMemberModal = () => {
    setOpenAddMember(true);
  };

  const closeCraeteMemberModal = () => {
    setOpenAddMember(false);
    setSearchValue("");
    setIsCheck([]);
  }

  const addingTeamMember = () => {
    var membersArr: any = [];
    isCheck?.map((member: string) => {
      var memberList = {
        users: member,
        role: permissionType
      }
      membersArr.push(memberList);
    });

    let apiPayload = {
      team_id: team._id,
      members: membersArr
    }

    addTeamMembers(apiPayload).then((data: any) => {
      //setTeam
      let index = teamListArr.findIndex((v: { _id: string; }) => v._id === team._id)
      const tempArr = [...teamListArr]
      if (index != -1) {
        tempArr[index] = data.data
      }
      setTeamListArr(tempArr)
      setTeam(data.data)
      setOpenAddMember(false);
      setIsCheck([]);
      setSearchValue("");
    });
  };

  const handleSelectAll = (e: any) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(props?.organisationUserList.map((li: any) => li._id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e: any) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item: any) => item !== id));
    }
  };

  const [searchValue, setSearchValue] = useState("");

  const filterNames = (data: any) => {
    if (currentLoginUser._id === data._id) {
      return false;
    }
    let inputSearch = (searchValue) ? searchValue.toLowerCase() : "";
    let userName = (data.user_name) ? data.user_name.toLowerCase() : "";
    let firstName = (data.firstname) ? data.firstname.toLowerCase() : "";
    let lastName = (data.lastname) ? data.lastname.toLowerCase() : "";

    let response = userName.includes(inputSearch) || firstName.includes(inputSearch) || lastName.includes(inputSearch)
    return response;
  };

  const handlePermisionDropdown = (e: any) => {
    setPermissionType(e.target.value);
  }
  const handleAudioCheckBox = (e: any) => {
    setAudioSelected(!audioSelected);
  }

  useEffect(() => {
    if (isCheck.length > 0) {
      setSubmitButtonDisplay(true);
    } else {
      setSubmitButtonDisplay(false);
    }
  }, [isCheck]);
  const username = APIService.Instance.getUserName();

  //Organisation Invitation
  const [openInvitationMember, setOpenInvitationMember] = React.useState(false);
  const [invitationEmailRole, setInvitationEmailRole] = useState("partial-access");
  const [openModalNewSearch, setOpenModalNewSearch] = React.useState(false);


  const openInviteOrganisationModal = () => {
    setOpenInvitationMember(true);
  }
  const openModalSearch = () => {
    setOpenModalNewSearch(true)
  }
  const closedModalNewSearch = () => {
    setOpenModalNewSearch(false)
  }
  const closeInviteOrganisationModal = () => {
    setOpenInvitationMember(false);
    setSelectedInvitationEmail([]);
  }

  const handleSubmitOrganisationInvitation = () => {
    if (selectedOrganisation?.organisationId?._id && selectedInvitationEmail && invitationEmailRole)
      sendOrganisationUserInvitationApi({ email: selectedInvitationEmail, org_id: selectedOrganisation?.organisationId?._id, role: invitationEmailRole }).then((data: any) => {
        if (data.status) {
          selectedOrganisation.lastClickAt = randomId();
          setOrganisation(selectedOrganisation)
          closeInviteOrganisationModal();
          setInvitationEmailRole("partial-access");
          CommonSocketService.commonEventService.updateOrganisationNotifications({
            group: "eflok_common_channel",
            _id: currentLoginUser._id,
            name: currentLoginUser.name,
            userName: currentLoginUser.username,
            data: selectedInvitationEmail,
          });
        }
      });
  }

  const changeInviteOrgRole = (e: any) => {
    setInvitationEmailRole(e.target.value);
  }

  const handleBack = () => {
    history.goBack();
  }

  const [selectedInvitationEmail, setSelectedInvitationEmail] = useState([]);
  const handleChange = (tags: any) => setSelectedInvitationEmail(tags);


  const [openChangePassword, setOpenChangePassword] = React.useState(false);
  const [eventDetail, setEventDetail] = useState<any>({
    oldPassword: "",
    newPassword: "",
    confNewPassword: "",
  });

  const handleClickOpenChangePassword = () => {
    setOpenChangePassword(true);
  };

  const handleCloseChangePassword = () => {
    setOpenChangePassword(false);
  };

  const handleSubmitChangePassword = () => {
    changePasswordApi(eventDetail).then((data: any) => {
      if (data.status) {
        handleCloseChangePassword();
        setEventDetail({});
      }
    });
  }

  const allowedExtensions = ["csv"];
  const orgAccess = [ORGANISATION_ACCESS.PARTIAL_ACCESS, ORGANISATION_ACCESS.FULL_ACCESS];
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [file, setFile] = useState("");

  const handleFileChange = (e: any) => {
    setError("");

    if (e.target.files.length) {
      const inputFile = e.target.files[0];

      const fileExtension = inputFile?.type.split("/")[1];

      if (!allowedExtensions.includes(fileExtension)) {
        //setError("Please input a csv file");
        e.target.value = null;
        UtilityService.showNotification(ALERT_TYPE.ERROR, "Please input a csv file");
        return;
      }

      // If input type is correct set the state
      setFile(inputFile);
    }
  };
  const handleParse = () => {
    if (!file) return setError("Enter a valid file");
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const reader = new FileReader();

    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target.result, { header: true, skipEmptyLines: true });
      const parsedData = csv?.data;
      const teamArr: any = [];
      if (parsedData.length > 0) {
        const dataEmail = parsedData.map((item: any) => {
          if (!re.test(item.email)) {
            UtilityService.showNotification(ALERT_TYPE.ERROR, "Please enter valid email address.");
            closeInviteOrganisationModal();
            setInvitationEmailRole("partial-access");
            setFile("");
            return false;
          }

          if (!orgAccess.includes(item.access)) {
            UtilityService.showNotification(ALERT_TYPE.ERROR, "Please enter valid organisation access");
            closeInviteOrganisationModal();
            setInvitationEmailRole("partial-access");
            setFile("");
            return false;
          }
          teamArr.push(item);
          return true;
        });
      }

      if (parsedData.length == teamArr.length) {
        setData(parsedData);
      }
    };

    // @ts-ignore
    reader.readAsText(file);
  };

  useEffect(() => {
    if (selectedOrganisation?.organisationId?._id && data && data.length > 0)
      sendOrganisationUserInvitationApiCSV({data: data, org_id: selectedOrganisation?.organisationId?._id}).then((data: any) => {
        if (data.status) {
          selectedOrganisation.lastClickAt = randomId();
          setOrganisation(selectedOrganisation)
          closeInviteOrganisationModal();
          setInvitationEmailRole("partial-access");
          CommonSocketService.commonEventService.updateOrganisationNotifications({
            group: "eflok_common_channel",
            _id: currentLoginUser._id,
            name: currentLoginUser.name,
            userName: currentLoginUser.username,
            data
          });
        }
      });
  }, [data]);

  const search = (e) => {
    advanceSearch(
      selectedOrganisation?.organisationId?._id,
      e.target.value,
    ).then((data: any) => {
      if (data.status) {
        setSearchResult(data.data);
      } else {
        setSearchResult([]);
      }
    });
  }

  const onSearchResultClick = (result: any) => {
    if (result.ref_type === "project") {
      localStorage.setItem("selectedProject", result.ref_id);
      history.push(`${ROUTES.SELECTED_PROJECT}`);
    } else if (result.ref_type === "board") {
      history.push(`${ROUTES.SHAREBOARD}#room=${result.ref_id}`);
    }
  };

  return (
    <header id="header" className="header fixed-top d-flex align-items-center">
      <div className="d-flex align-items-duplicatecenter justify-content-between">
        <Link to={ROUTES.DASHBOARD}>
          <img
            className="logo-image"
            src={`${window.location.origin}/logo-180x180.png`}
            alt=""
          />
        </Link>
      </div>
      {isDisplayBackButton && <button className="rtb-btn rightDataBtn btn btn-secondary" onClick={handleBack}>Back</button>}
      <div className="searchBarData">
        <span onClick={(e) => {
                  openModalSearch();
                }}>
          <input type="text" placeholder="Type to search" />
          <i className="fa fa-search"></i>
          {/* <button className="searchInfo">Search</button> */}
        </span>
      </div>
      <nav className="header-nav ms-auto">
        <ul className="d-flex align-items-center">
          {inviteOrgMemberVisble &&
            <li className="nav-item newBtnFirst mobileView">
              <a onClick={(e) => {
                openInviteOrganisationModal();
              }}><i className="fa fa-user-plus" aria-hidden="true"></i> Invite organisation members</a>
            </li>
          }

          {/* {inviteTeamMemberVisble &&
            <li className="nav-item newBtnFirst">
              <a onClick={(e) => {
                openCraeteMemberModal();
              }}><i className="fa fa-user-plus" aria-hidden="true"></i> Invite team members</a>
            </li>
          } */}

          <li className="nav-item newBtnFirst">
            <a className="notificationButton" onClick={(e) => setOpenNotification(!openNotification)}>
              <i className="fa fa-bell" aria-hidden="true" />
              {notifications.filter(fl => !fl?.isRead).length > 0 && <span className="notification-count">{
                notifications.filter(fl => !fl?.isRead).length
              }</span>}
            </a>
          </li>
          {/* <li className="nav-item">
            <a href=""><i className="fa fa-comments-o" aria-hidden="true"></i></a>
          </li>
          <li className="nav-item">
            <a href=""><i className="fa fa-bell-o" aria-hidden="true"></i></a>
          </li> */}
          <li className="nav-item dropdown pe-3">
            <a
              className="nav-link nav-profile d-flex align-items-center pe-0"
              href="#"
              data-bs-toggle="dropdown"
            >
              <span className="d-md-block dropdown-toggle ps-2">
                <i className="fa fa-user-circle" title={username}></i>
              </span>
            </a>

            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
              <li>
                <a className="dropdown-item d-flex align-items-center" onClick={handleClickOpen}>
                  <i className="fa fa-user"></i>
                  <span>My Profile</span>
                </a>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <a className="dropdown-item d-flex align-items-center" onClick={handleClickOpenChangePassword}>
                  <i className="fa fa-key"></i>
                  <span>Change Password</span>
                </a>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              {/* <li>
                <a
                  className="dropdown-item d-flex align-items-center"
                  onClick={handleClickOpen}
                >
                  <i className="fa fa-gear"></i>
                  <span>Account Settings</span>
                </a>
              </li> */}
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>

              <li>
                <a
                  className="dropdown-item d-flex align-items-center"
                  onClick={() => {
                    signOutFun();
                  }}
                >
                  <i className="fa fa-sign-out"></i>
                  <span>Sign Out</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
      <Dialog
        className="loginModal"
        open={open}
        maxWidth={maxWidth}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <div>
            <ProfileLayout handleClose={handleClose} />
          </div>
        </DialogContent>
      </Dialog>

      {/* Team Invitation Member Modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="custommodal"
        open={openAddMember}
        onClose={closeCraeteMemberModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openAddMember}>
          <div className="createNewTeamOuter">
            <div className="innerScroll">
              <div className="closeBtnProfileTeam" onClick={closeCraeteMemberModal}>
                <i className="fa fa-close"></i>
              </div>
              <h5 id="transition-modal-title">
                Invite Team Members
              </h5>
              <div className="dialogBody-27a2n">
                <div className="createTeamTextBlock">
                  <p>You can select individual or multiple users</p>
                  <div className="row">
                    <div className="col-sm-7">
                      <span>Members will get access to all boards in this project.</span>
                    </div>
                    <div className="col-sm-5">
                      <select name="permission"
                        id="permission"
                        onChange={(e) => {
                          handlePermisionDropdown(e)
                        }}
                      >
                        {/* <option value="partial-access">Partial Access</option>
                        <option value="full-member">Full Access</option> */}
                        <option value="can-edit">Can Edit</option>
                        <option value="can-view">Can View</option>
                        <option value="can-comment">Can Comment</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="row">
                    <div className=" col-lg-2">
                      <input type="checkbox"
                        name="selectAll"
                        className="form-check-input"
                        id="selectAll"
                        onChange={handleSelectAll}
                        checked={isCheckAll}
                      >
                      </input>
                      <label className="select-all" htmlFor="selectAll">All</label>
                    </div>
                    <div className=" col-lg-10 search-box">
                      <input type="text"
                        placeholder="Search by email"
                        className="input-search"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                      />
                    </div>
                  </div>
                  <div>
                    <ul className="user-list-ui">
                      {props?.organisationUserList?.filter(filterNames).map((member: any) => {
                        return <li key={member._id} className="user-row">
                          <div className="row">
                            <label className="user-check-label" htmlFor={member._id}>
                              <div className="col-sm-2">
                                <div className="form-check form-check-inline">
                                  <input
                                    id={member._id}
                                    name={member.user_name}
                                    type="checkbox"
                                    className="form-check-input"
                                    onChange={handleClick}
                                    checked={isCheck.includes(member?._id)}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-2">
                                <img referrerPolicy="no-referrer" src={(member.imageUrl) ? member.imageUrl : "https://eu.ui-avatars.com/api/?name=" + member.firstname + " " + member.lastname + "&size=200&background=random&rounded=false&color=fff"}></img>
                              </div>
                              <div className="col-sm-8 user-detail">
                                <div className="user-name">{member.firstname + " " + member.lastname}</div>
                                <div className="user-email">{member.user_name}</div>
                              </div>
                            </label>
                          </div>
                        </li>;
                      })}
                    </ul>
                  </div>
                </div>
                <div className="dialogFooter">
                  {(submitButtonDisplay) ? (
                    <button className="rtb-btn rtb-btn--primary rtb-btn--medium" onClick={(e) => { addingTeamMember(); }}>Choose</button>
                  ) : (
                    <button className="rtb-btn btn btn-primary" disabled>Choose</button>
                  )}
                  <button className="rtb-btn rtb-btn--secondary rtb-btn--medium" onClick={closeCraeteMemberModal}>Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>

      {/* Organisation send Invitation Modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="custommodal"
        open={openInvitationMember}
        onClose={closeInviteOrganisationModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openInvitationMember}>
          <div className="createNewTeamOuter">
            <div className="innerScroll">
              <div className="closeBtnProfileTeam" onClick={closeInviteOrganisationModal}>
                <i className="fa fa-close"></i>
              </div>
              <h5 id="transition-modal-title">
                Invite organisation member via email.
              </h5>
              <div className="dialogBody-27a2n">
                <div className="">
                  <div className="row">
                    <div className=" col-lg-12 search-box">
                      {/* <input type="text"
                        placeholder="Search by email"
                        className="input-invitation"
                        value={invitationEmail}
                        onChange={(e) => setInvitationEmail(e.target.value)}
                      /> */}
                    </div>
                  </div>
                  <div className="todata row">
                    <div className="col-md-1 to-span-main">
                      <span className="to-span">To</span>
                    </div>
                    <div className="col-md-11">
                      <TagsInput
                        addOnBlur={true}
                        value={selectedInvitationEmail}
                        inputProps={{
                          placeholder:
                            "Enter email to invite members",
                        }}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className=" col-lg-12 search-box mt-3">
                      <select name="permission" id="permission" className="input-invitation"
                        onChange={(e) => { changeInviteOrgRole(e) }}
                      >
                        <option value="partial-access">Partial Access</option>
                        <option value="full-access">Full Access</option>
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className=" col-lg-12 search-box mt-3">
                      <input
                        onChange={handleFileChange}
                        onClick={(e: any) => (e.target.value = null)}
                        id="csvInput"
                        name="file"
                        type="File"
                      />
                    </div>
                  </div>
                </div>
                <div className="dialogFooter">
                  {(selectedInvitationEmail.length > 0) ? (
                    <button className="rtb-btn rtb-btn--primary rtb-btn--medium" onClick={(e) => { handleSubmitOrganisationInvitation(); }}>Send invitation</button>
                  ) : (
                    <button className="rtb-btn btn btn-primary" disabled>Send invitation</button>
                  )}
                  {(file) ? (
                    <button className="rtb-btn rtb-btn--primary rtb-btn--medium" onClick={(e) => { handleParse(); }}>Upload CSV</button>
                  ) : (
                    <button className="rtb-btn btn btn-primary" disabled>Upload CSV</button>
                  )}
                  <button className="rtb-btn rtb-btn--secondary rtb-btn--medium" onClick={closeInviteOrganisationModal}>Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
      {/* Change password Modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="custommodal"
        open={openChangePassword}
        onClose={handleCloseChangePassword}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openChangePassword}>
          <div className="createNewTeamOuter">
            <div className="innerScroll">
              <div className="closeBtnProfileTeam" onClick={handleCloseChangePassword}>
                <i className="fa fa-close"></i>
              </div>
              <h5 id="transition-modal-title">
                Change Password
              </h5>
              <div className="dialogBody-27a2n">
                <div className="">
                  <div className="row">
                    <div className=" col-lg-12 search-box">
                      <input type="password"
                        placeholder="Old Password"
                        className="input-invitation"
                        value={eventDetail.oldPassword}
                        onChange={(e) => {
                          setEventDetail({
                            ...eventDetail,
                            oldPassword: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className=" col-lg-12 search-box mt-1">
                      <input type="password"
                        placeholder="New password"
                        className="input-invitation"
                        value={eventDetail.newPassword}
                        onChange={(e) => {
                          setEventDetail({
                            ...eventDetail,
                            newPassword: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className=" col-lg-12 search-box mt-1">
                      <input type="password"
                        placeholder="Confirm new password"
                        className="input-invitation"
                        value={eventDetail.confNewPassword}
                        onChange={(e) => {
                          setEventDetail({
                            ...eventDetail,
                            confNewPassword: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="dialogFooter">
                  {(eventDetail.oldPassword != "" && eventDetail.newPassword != "" && eventDetail.confNewPassword != "") ? (
                    <button className="rtb-btn rtb-btn--primary rtb-btn--medium" onClick={(e) => { handleSubmitChangePassword(); }}>Change Password</button>
                  ) : (
                    <button className="rtb-btn btn btn-primary" disabled>Change Password</button>
                  )}
                  <button className="rtb-btn rtb-btn--secondary rtb-btn--medium" onClick={closeInviteOrganisationModal}>Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="custommodal"
        open={openModalNewSearch}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModalNewSearch}>
          <div className="modalSearch">
            <button className="crossData" onClick={closedModalNewSearch}><img src={`${window.location.origin}/blogCross.png`}/></button>
            <div className="outerSearchInput">
                <div className="outerSearchInputCenter">
                   <input autoFocus={true} direction="down" placeholder="Search" onKeyUp={search}/>
                   <div className="outerSearchSubmenut">
                     <i className="fa fa-search"></i>
                   </div>
                </div>
            </div>
            <div className="contentDataInfoSearch">
              <div className="container">
                {searchResult.map((result) => {
                  return (<div className="boadInfo cursor-pointer" onClick={() => onSearchResultClick(result)}>
                    <h3>{result.value}</h3>
                    <p>{`${result.type.toUpperCase()}
                    ${result.search_type && result.search_type !== "board" && result.search_type !== "project" ? result.search_type.toUpperCase() : ""}`}
                    </p>
                  </div>)
                })}
              </div>
            </div>
          </div>
        </Fade>
      </Modal>

      {openNotification && <NotificationsIcon notifications={notifications} markAllAsRead={markAllAsRead} />}
    </header>
  );
};

