import { getCollaborationLinkData } from "../data";
import { ExcalidrawElement, FileId } from "../../element/types";
import { getSceneVersion } from "../../element";
import Portal from "../collab/Portal";
import { restoreElements } from "../../data/restore";
import { duplicateBoardCall } from "../../magnum-components/dashboard/dashboardMain/dashboardMain";
import { saveAsTemplate } from "../../services/v2/board/board-service";
import { APIService } from "../../services/api/api-service";
import { URLS } from "../../constants/urls";
import { HTTP_RESPONSE } from "../../enums/http-responses.enum";
import { AppState, BinaryFileData, DataURL, BinaryFileMetadata } from "../../types";
import { decompressData } from "../../data/encode";
import { MIME_TYPES } from "../../constants";
import { FILE_CACHE_MAX_AGE_SEC } from "../app_constants";
import { getSyncableElements, SyncableExcalidrawElement } from ".";
import { decryptData, encryptData } from "../../data/encryption";
import { reconcileElements } from "../collab/reconciliation";


let FIREBASE_CONFIG: Record<string, any>;
try {
  FIREBASE_CONFIG = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);
} catch (error: any) {
  console.warn(
    `Error JSON parsing firebase config. Supplied value: ${
      process.env.REACT_APP_FIREBASE_CONFIG
    }`,
  );
  FIREBASE_CONFIG = {};
}
let firebasePromise: Promise<
  typeof import("firebase/app").default
> | null = null;

let firestorePromise: Promise<any> | null | true = null;
let firebaseStoragePromise: Promise<any> | null | true = null;

let isFirebaseInitialized = false;

// const loadFirebase = async () => {
//   const firebase = (
//     await import(/* webpackChunkName: "firebase" */ "firebase/app")
//   ).default;
//   await import(/* webpackChunkName: "firestore" */ "firebase/firestore");
//   const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);
//   if (!firebase.apps.length) {
//     firebase.initializeApp(firebaseConfig);
//   }
//   return firebase;
// };

const _loadFirebase = async () => {
  const firebase = (
    await import(/* webpackChunkName: "firebase" */ "firebase/app")
  ).default;

  if (!isFirebaseInitialized) {
    try {
      firebase.initializeApp(FIREBASE_CONFIG);
    } catch (error: any) {
      // trying initialize again throws. Usually this is harmless, and happens
      // mainly in dev (HMR)
      if (error.code === "app/duplicate-app") {
        console.warn(error.name, error.code);
      } else {
        throw error;
      }
    }
    isFirebaseInitialized = true;
  }

  return firebase;
};

// -----------------------------------------------------------------------------

const loadFirestore = async () => {
  const firebase = await _getFirebase();
  if (!firestorePromise) {
    firestorePromise = import(
      /* webpackChunkName: "firestore" */ "firebase/firestore"
      );
  }
  if (firestorePromise !== true) {
    await firestorePromise;
    firestorePromise = true;
  }
  return firebase;
};

const _getFirebase = async (): Promise<
  typeof import("firebase/app").default
  > => {
  if (!firebasePromise) {
    firebasePromise = _loadFirebase();
  }
  return firebasePromise;
};


// const getFirebase = async (): Promise<
//   typeof import("firebase/app").default
// > => {
//   if (!firebasePromise) {
//     firebasePromise = loadFirebase();
//   }
//   return await firebasePromise!;
// };

interface FirebaseStoredScene {
  sceneVersion: number;
  iv: firebase.default.firestore.Blob;
  ciphertext: firebase.default.firestore.Blob;
}

const encryptElements = async (
  key: string,
  elements: readonly ExcalidrawElement[],
): Promise<{ ciphertext: ArrayBuffer; iv: Uint8Array }> => {
  // const importedKey = await getImportedKey(key, "encrypt");
  // const iv = createIV();
  // const json = JSON.stringify(elements);
  // const encoded = new TextEncoder().encode(json);
  // const ciphertext = await window.crypto.subtle.encrypt(
  //   {
  //     name: "AES-GCM",
  //     iv,
  //   },
  //   importedKey,
  //   encoded,
  // );
  //
  // return { ciphertext, iv };
  const json = JSON.stringify(elements);
  const encoded = new TextEncoder().encode(json);
  const { encryptedBuffer, iv } = await encryptData(key, encoded);

  return { ciphertext: encryptedBuffer, iv };
};

const decryptElements = async (
  data: FirebaseStoredScene,
  roomKey: string,
): Promise<readonly ExcalidrawElement[]> => {
  const ciphertext = data.ciphertext.toUint8Array();
  const iv = data.iv.toUint8Array();

  const decrypted = await decryptData(iv, ciphertext, roomKey);
  const decodedData = new TextDecoder("utf-8").decode(
    new Uint8Array(decrypted),
  );
  return JSON.parse(decodedData);
};

class FirebaseSceneVersionCache {
  private static cache = new WeakMap<SocketIOClient.Socket, number>();
  static get = (socket: SocketIOClient.Socket) => {
    return FirebaseSceneVersionCache.cache.get(socket);
  };
  static set = (
    socket: SocketIOClient.Socket,
    elements: readonly SyncableExcalidrawElement[],
  ) => {
    FirebaseSceneVersionCache.cache.set(socket, getSceneVersion(elements));
  };
}

const firebaseSceneVersionCache = new WeakMap<SocketIOClient.Socket, number>();

export const isSavedToFirebase = (
  portal: Portal,
  elements: readonly ExcalidrawElement[],
): boolean => {
  if (portal.socket && portal.roomId && portal.roomKey) {
    const sceneVersion = getSceneVersion(elements);
    return firebaseSceneVersionCache.get(portal.socket) === sceneVersion;
  }
  // if no room exists, consider the room saved so that we don't unnecessarily
  // prevent unload (there's nothing we could do at that point anyway)
  return true;
};

const createFirebaseSceneDocument = async (
  firebase: ResolutionType<typeof loadFirestore>,
  elements: readonly SyncableExcalidrawElement[],
  roomKey: string,
) => {
  const sceneVersion = getSceneVersion(elements);
  const { ciphertext, iv } = await encryptElements(roomKey, elements);
  return {
    sceneVersion,
    ciphertext: firebase.firestore.Blob.fromUint8Array(
      new Uint8Array(ciphertext),
    ),
    iv: firebase.firestore.Blob.fromUint8Array(iv),
  } as FirebaseStoredScene;
};

export const saveToFirebase = async (
  portal: Portal,
  elements: readonly SyncableExcalidrawElement[],
  appState: AppState,
) => {
  const { roomId, roomKey, socket } = portal;
  if (
    // bail if no room exists as there's nothing we can do at this point
    !roomId ||
    !roomKey ||
    !socket ||
    isSavedToFirebase(portal, elements)
  ) {
    return false;
  }

  const firebase = await loadFirestore();
  const firestore = firebase.firestore();

  const docRef = firestore.collection("scenes").doc(roomId);

  const savedData = await firestore.runTransaction(async (transaction) => {
    const snapshot = await transaction.get(docRef);

    if (!snapshot.exists) {
      const sceneDocument = await createFirebaseSceneDocument(
        firebase,
        elements,
        roomKey,
      );

      transaction.set(docRef, sceneDocument);

      return {
        elements,
        reconciledElements: null,
      };
    }

    const prevDocData = snapshot.data() as FirebaseStoredScene;
    const prevElements = getSyncableElements(
      await decryptElements(prevDocData, roomKey),
    );

    const reconciledElements = getSyncableElements(
      reconcileElements(elements, prevElements, appState),
    );

    // const filteredElements = reconciledElements.filter((fl:any) => fl.originalText || fl.text)
    //   .map((mp: any) => {
    //     return {
    //       type: mp.type,
    //       id: mp.id,
    //       text: mp.text ? mp.text : "",
    //       originalText: mp.originalText ? mp.originalText : ""
    //     }
    //   })
    // await APIService.Instance.post(
    //   URLS.SAVE_META_DATA,
    //   { roomId: `${roomId},${roomKey}`, elements: filteredElements },
    //   {},
    // );

    const sceneDocument = await createFirebaseSceneDocument(
      firebase,
      reconciledElements,
      roomKey,
    );

    transaction.update(docRef, sceneDocument);
    return {
      elements,
      reconciledElements,
    };
  });

  FirebaseSceneVersionCache.set(socket, savedData.elements);

  return { reconciledElements: savedData.reconciledElements };
};

export const loadFromFirebase = async (
  roomId: string,
  roomKey: string,
  socket: SocketIOClient.Socket | null,
): Promise<readonly ExcalidrawElement[] | null> => {
  const firebase = await loadFirestore();
  const db = firebase.firestore();

  const docRef = db.collection("scenes").doc(roomId);
  const doc = await docRef.get();

  if (!doc.exists) {
    const response = await APIService.Instance.post(
      URLS.CHECK_PARENT_BOARD,
      { roomId: `${roomId},${roomKey}` },
      {},
    );
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const data = response.data.data;
      console.log("data=======>>>>>>>>>>>",data);
      if (data && data.parent) {
        const [parentRoomId, parentRoomKey] = data.parent.split(",");
        console.log("parentRoomKey",parentRoomKey,parentRoomId);
        const parentDocRef = db.collection("scenes").doc(parentRoomId);
        const parentDoc = await parentDocRef.get();
        if (!parentDoc.exists) {
          return null;
        }
        console.log("doc=====>>>",parentDoc.data());
        const storedScene = parentDoc.data() as FirebaseStoredScene;
        console.log("storedScene=====>>>",storedScene);
        const parentCiphertext = storedScene.ciphertext.toUint8Array();
        console.log("parentCiphertext=====>>>",parentCiphertext);
        const parentIv = storedScene.iv.toUint8Array();
        console.log("parentIv=====>>>",parentIv);
        const elements = await decryptElements(
          storedScene,
          parentRoomKey
        );

        const sceneVersion = getSceneVersion(elements);
        const { ciphertext, iv } = await encryptElements(roomKey, elements);
        const nextDocData = {
          sceneVersion,
          ciphertext: firebase.firestore.Blob.fromUint8Array(
            new Uint8Array(ciphertext),
          ),
          iv: firebase.firestore.Blob.fromUint8Array(iv),
        } as FirebaseStoredScene;

        const newDocRef = db.collection("scenes").doc(roomId);
        const didUpdate = await db.runTransaction(async (transaction: any) => {
          const doc = await transaction.get(newDocRef);
          if (!doc.exists) {
            transaction.set(docRef, nextDocData);
            return true;
          }

          const prevDocData = doc.data() as FirebaseStoredScene;
          if (prevDocData.sceneVersion >= nextDocData.sceneVersion) {
            return false;
          }
          transaction.update(docRef, nextDocData);
          return true;
        });
        if (didUpdate) {
          return restoreElements(elements, null);
        } else {
          return null;
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  } else {
    const storedScene = doc.data() as FirebaseStoredScene;
    const ciphertext = storedScene.ciphertext.toUint8Array();
    const iv = storedScene.iv.toUint8Array();
    const elements = getSyncableElements(
      await decryptElements(storedScene, roomKey),
    );
    if (socket) {
      FirebaseSceneVersionCache.set(socket, elements);
    }
    return restoreElements(elements, null);
  }
};

export const duplicateBoard = async (
  roomId: string,
  roomKey: string,
): Promise<{ status: boolean; data: any }> => {
  let firebase = null;
  firebase = await _getFirebase();
  const db = firebase.firestore();

  if (!db) {
    alert("Not Replicated");
    return { status: false, data: null };
  }

  const docRef = db.collection("scenes").doc(roomId);
  const doc = await docRef.get();
  if (!doc.exists) {
    return { status: false, data: null };
  }
  const storedScene = doc.data() as FirebaseStoredScene;
  const ciphertext = storedScene.ciphertext.toUint8Array();
  const iv = storedScene.iv.toUint8Array();
  const elements = await decryptElements(storedScene, roomKey);
  // eslint-disable-next-line no-console
  const roomArr = getCollaborationLinkData(window.location.href);
  const currentRoomId = Array.isArray(roomArr)
    ? `${roomArr[1]},${roomArr[2]}`
    : "";

  const meetResponse: any = await duplicateBoardCall(currentRoomId);
  if (meetResponse && meetResponse.meetingId) {
    [roomId, roomKey] = meetResponse.meetingId.split(",");
    const sceneVersion = getSceneVersion(elements);
    const { ciphertext, iv } = await encryptElements(roomKey, elements);

    const nextDocData = {
      sceneVersion,
      ciphertext: firebase.firestore.Blob.fromUint8Array(
        new Uint8Array(ciphertext),
      ),
      iv: firebase.firestore.Blob.fromUint8Array(iv),
    } as FirebaseStoredScene;

    const docRef = db.collection("scenes").doc(roomId);
    const didUpdate = await db.runTransaction(async (transaction: any) => {
      const doc = await transaction.get(docRef);
      if (!doc.exists) {
        transaction.set(docRef, nextDocData);
        return true;
      }

      const prevDocData = doc.data() as FirebaseStoredScene;
      if (prevDocData.sceneVersion >= nextDocData.sceneVersion) {
        return false;
      }

      transaction.update(docRef, nextDocData);
      return true;
    });

    if (didUpdate) {
      localStorage.removeItem("excalidraw-state");
      return { status: true, data: meetResponse };
    }
  }
  return { status: false, data: null };
};

export const duplicateBoardFromDashboard = async (
  roomId: string,
  roomKey: string,
): Promise<{ status: boolean; data: any }> => {
  let firebase = null;
  firebase = await _getFirebase();
  const db = firebase.firestore();

  if (!db) {
    alert("Not Replicated");
    return { status: false, data: null };
  }

  const docRef = db.collection("scenes").doc(roomId);
  const doc = await docRef.get();
  if (!doc.exists) {
    return { status: false, data: null };
  }
  const storedScene = doc.data() as FirebaseStoredScene;
  const ciphertext = storedScene.ciphertext.toUint8Array();
  const iv = storedScene.iv.toUint8Array();
  const elements = await decryptElements(storedScene, roomKey);
  // eslint-disable-next-line no-console
  //const roomArr = getCollaborationLinkData(window.location.href);
  const currentRoomId = `${roomId},${roomKey}`;

  const meetResponse: any = await duplicateBoardCall(currentRoomId);
  if (meetResponse && meetResponse.meetingId) {
    [roomId, roomKey] = meetResponse.meetingId.split(",");
    const sceneVersion = getSceneVersion(elements);
    const { ciphertext, iv } = await encryptElements(roomKey, elements);

    const nextDocData = {
      sceneVersion,
      ciphertext: firebase.firestore.Blob.fromUint8Array(
        new Uint8Array(ciphertext),
      ),
      iv: firebase.firestore.Blob.fromUint8Array(iv),
    } as FirebaseStoredScene;

    const docRef = db.collection("scenes").doc(roomId);
    const didUpdate = await db.runTransaction(async (transaction: any) => {
      const doc = await transaction.get(docRef);
      if (!doc.exists) {
        transaction.set(docRef, nextDocData);
        return true;
      }

      const prevDocData = doc.data() as FirebaseStoredScene;
      if (prevDocData.sceneVersion >= nextDocData.sceneVersion) {
        return false;
      }

      transaction.update(docRef, nextDocData);
      return true;
    });

    if (didUpdate) {
      localStorage.removeItem("excalidraw-state");
      return { status: true, data: meetResponse };
    }
  }
  return { status: false, data: null };
};

// @ts-ignore
export const saveBoardAsTemplate = async (
  data: any,
  formData: FormData,
): Promise<{ status: boolean; data: any }> => {
  let firebase:any = null;
  firebase = await _getFirebase();
  const db = firebase.firestore();

  if (!db) {
    alert("Something went wrong.");
    return { status: false, data: null };
  }

  const docRef = db.collection("scenes").doc(data.roomId);
  const doc = await docRef.get();
  if (!doc.exists) {
    return { status: false, data: null };
  }
  const storedScene = doc.data() as FirebaseStoredScene;
  const ciphertext = storedScene.ciphertext.toUint8Array();
  const iv = storedScene.iv.toUint8Array();
  const elements = await decryptElements(storedScene, data.roomKey);
  data.elements = elements.filter((el) => el.type !== "comment" && !el.isDeleted);

  formData.append("elements", JSON.stringify(data.elements));
  saveAsTemplate(formData).then(async (res) => {
    if (res && res.status) {
      const meetingId = res.data.meeting_id;
      const roomId = meetingId.split(",")[0];
      const roomKey = meetingId.split(",")[1];
      const finalElements = elements.filter((el) => el.type !== "comment" && !el.isDeleted);
      const sceneVersion = getSceneVersion(finalElements);
      const { ciphertext, iv } = await encryptElements(roomKey, finalElements);
      const nextDocData = {
        sceneVersion,
        ciphertext: firebase.firestore.Blob.fromUint8Array(
          new Uint8Array(ciphertext),
        ),
        iv: firebase.firestore.Blob.fromUint8Array(iv),
      } as FirebaseStoredScene;
      const docRef = db.collection("scenes").doc(roomId);
      const didUpdate = await db.runTransaction(async (transaction: any) => {
        const doc = await transaction.get(docRef);
        if (!doc.exists) {
          transaction.set(docRef, nextDocData);
          return true;
        }

        const prevDocData = doc.data() as FirebaseStoredScene;
        if (prevDocData.sceneVersion >= nextDocData.sceneVersion) {
          return false;
        }
        transaction.update(docRef, nextDocData);
        return true;
      });
      return { status: true, data: res.data };
    }
  });
  return { status: false, data: null };
};

export const createBoardFromTemplate = async (
  templateRoom: string,
  newRoom: string,
): Promise<{ status: boolean; data: any }> => {
  let firebase = null;
  firebase = await _getFirebase();
  const db = firebase.firestore();

  if (!db) {
    alert("Not Replicated");
    return { status: false, data: null };
  }

  const oldRoomId = templateRoom.split(",")[0];
  const oldRoomKey = templateRoom.split(",")[1];

  const docRef = db.collection("scenes").doc(oldRoomId);
  const doc = await docRef.get();
  if (!doc.exists) {
    return { status: false, data: null };
  }
  const storedScene = doc.data() as FirebaseStoredScene;
  const ciphertext = storedScene.ciphertext.toUint8Array();
  const iv = storedScene.iv.toUint8Array();
  const elements = await decryptElements(storedScene, oldRoomKey);
  // eslint-disable-next-line no-console
  const currentRoomId = templateRoom;

  const newRoomId = newRoom.split(",")[0];
  const newRoomKey = newRoom.split(",")[1];

    const sceneVersion = getSceneVersion(elements);
    const encryptElem = await encryptElements(newRoomKey, elements);
    const newCipherText = encryptElem.ciphertext;
    const newIv = encryptElem.iv;
    const nextDocData = {
      sceneVersion,
      ciphertext: firebase.firestore.Blob.fromUint8Array(
        new Uint8Array(newCipherText),
      ),
      iv: firebase.firestore.Blob.fromUint8Array(newIv),
    } as FirebaseStoredScene;

    const newDocRef = db.collection("scenes").doc(newRoomId);
    const didUpdate = await db.runTransaction(async (transaction: any) => {
      const doc = await transaction.get(newDocRef);
      if (!doc.exists) {
        transaction.set(newDocRef, nextDocData);
        return true;
      }

      const prevDocData = doc.data() as FirebaseStoredScene;
      if (prevDocData.sceneVersion >= nextDocData.sceneVersion) {
        return false;
      }

      transaction.update(newDocRef, nextDocData);
      return true;
    });

    if (didUpdate) {
      localStorage.removeItem("excalidraw-state");
      return { status: true, data: null };
    }

  return { status: false, data: null };
};

export const loadFilesFromFirebase = async (
  prefix: string,
  decryptionKey: string,
  filesIds: readonly FileId[],
) => {
  const loadedFiles: BinaryFileData[] = [];
  const erroredFiles = new Map<FileId, true>();

  await Promise.all(
    [...new Set(filesIds)].map(async (id) => {
      try {
        const url = `https://firebasestorage.googleapis.com/v0/b/${
          FIREBASE_CONFIG.storageBucket
        }/o/${encodeURIComponent(prefix.replace(/^\//, ""))}%2F${id}`;
        const response = await fetch(`${url}?alt=media`);
        if (response.status < 400) {
          const arrayBuffer = await response.arrayBuffer();

          const { data, metadata } = await decompressData<BinaryFileMetadata>(
            new Uint8Array(arrayBuffer),
            {
              decryptionKey,
            },
          );

          const dataURL = new TextDecoder().decode(data) as DataURL;

          loadedFiles.push({
            mimeType: metadata.mimeType || MIME_TYPES.binary,
            id,
            dataURL,
            created: metadata?.created || Date.now(),
            lastRetrieved: metadata?.created || Date.now(),
          });
        } else {
          erroredFiles.set(id, true);
        }
      } catch (error: any) {
        erroredFiles.set(id, true);
        console.error(error);
      }
    }),
  );

  return { loadedFiles, erroredFiles };
};

export const loadFirebaseStorage = async () => {
  const firebase = await _getFirebase();
  if (!firebaseStoragePromise) {
    firebaseStoragePromise = import(
      /* webpackChunkName: "storage" */ "firebase/storage"
      );
  }
  if (firebaseStoragePromise !== true) {
    await firebaseStoragePromise;
    firebaseStoragePromise = true;
  }
  return firebase;
};

export const saveFilesToFirebase = async ({
                                            prefix,
                                            files,
                                          }: {
  prefix: string;
  files: { id: FileId; buffer: Uint8Array }[];
}) => {
  const firebase = await loadFirebaseStorage();

  const erroredFiles = new Map<FileId, true>();
  const savedFiles = new Map<FileId, true>();

  await Promise.all(
    files.map(async ({ id, buffer }) => {
      try {
        await firebase
          .storage()
          .ref(`${prefix}/${id}`)
          .put(
            new Blob([buffer], {
              type: MIME_TYPES.binary,
            }),
            {
              cacheControl: `public, max-age=${FILE_CACHE_MAX_AGE_SEC}`,
            },
          );
        savedFiles.set(id, true);
      } catch (error: any) {
        erroredFiles.set(id, true);
      }
    }),
  );

  return { savedFiles, erroredFiles };
};
