import oc from "open-color";
import {
  DEFAULT_ELEMENT_PROPS,
  DEFAULT_FONT_FAMILY,
  DEFAULT_FONT_SIZE,
  DEFAULT_FONT_STYLE,
  DEFAULT_FONT_WEIGHT,
  DEFAULT_TEXT_ALIGN, EXPORT_SCALES,
  GRID_SIZE, THEME,
} from "./constants";
import { t } from "./i18n";
import { IEventModel } from "./models/room.model";
import { APIService } from "./services/api/api-service";
import { AppState, FlooredNumber, NormalizedZoomValue } from "./types";
import { getDateTime } from "./utils";
import { URLS } from "./constants/urls";
import { HTTP_RESPONSE } from "./enums/http-responses.enum";
const defaultExportScale = EXPORT_SCALES.includes(devicePixelRatio)
  ? devicePixelRatio
  : 1;

export const returnDefaultEventDetail = (): IEventModel => {
  return {
    eventName: "",
    eventDescription: "",
    eventDate: "",
    filesPinged: [],
    initatorUsername: "",
    isLocked: true,
    isProtected: true,
    notes: [],
    annotation: [],
    recordingMeta: [],
    collaborators: [],
    baseMeeting: "",
    initatorDetails: {},
  };
};

export const getParticularRoomInfo = async (
  roomId: string,
): Promise<IEventModel> => {
  try {
    const formData = new FormData();
    formData.append("roomId", roomId);
    const url = URLS.ROOM_GET;
    const response = await APIService.Instance.post(url, formData);
    let respdata: IEventModel;
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      respdata = response.data.data;
      return respdata;
    }
  } catch (err) {
    console.log("testing");
    // TODO add error handling
    APIService.Instance.removeToken();
  }
  return await returnDefaultEventDetail();
};

export const getNotes = async (
  roomId: string,
): Promise<IEventModel> => {
  try {
    const url = `${URLS.GET_NOTES}?meetingId=${roomId}`;
    const response = await APIService.Instance.get(url);
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      return response.data.data;
    }
  } catch (err) {
    console.log("testing");
    // TODO add error handling
    APIService.Instance.removeToken();
  }
  return await returnDefaultEventDetail();
};

export const getDefaultAppState = (): Omit<
  AppState,
  "offsetTop" | "offsetLeft" | "width" | "height"
> => {
  return {
    theme: THEME.LIGHT,
    exportWithDarkMode: false,
    shouldAddWatermark: false,
    contextMenu: null,
    eventDetail: returnDefaultEventDetail(),
    appearance: "light",
    collaborators: new Map(),
    currentChartType: "bar",
    currentItemBackgroundColor: "transparent",
    currentItemEndArrowhead: "arrow",
    currentItemFillStyle: "hachure",
    currentItemRoundness: "round",
    currentItemFontFamily: DEFAULT_FONT_FAMILY,
    currentItemFontWeight: DEFAULT_FONT_WEIGHT,
    currentItemFontStyle: DEFAULT_FONT_STYLE,
    currentItemFontSize: DEFAULT_FONT_SIZE,
    currentItemLinearStrokeSharpness: "round",
    currentItemOpacity: 100,
    currentItemRoughness: 0,

    currentItemStartArrowhead: null,
    currentItemStrokeColor: "#1e1e1e",
    currentItemStrokeSharpness: "sharp",
    currentItemStrokeStyle: "solid",
    currentItemStrokeWidth: 1,
    currentItemTextAlign: DEFAULT_TEXT_ALIGN,
    cursorButton: "up",
    draggingElement: null,
    editingElement: null,
    editingGroupId: null,
    editingLinearElement: null,
    elementLocked: false,
    elementType: "selection",
    activeEmbeddable: null,
    activeTool: {
      type: "selection",
      customType: null,
      locked: DEFAULT_ELEMENT_PROPS.locked,
      lastActiveTool: null,
    },
    penMode: false,
    penDetected: false,
    errorMessage: null,
    exportBackground: true,
    exportScale: defaultExportScale,
    exportEmbedScene: false,
    fileHandle: null,
    // gridSize: GRID_SIZE,
    gridSize: null,
    isBindingEnabled: true,
    isLibraryOpen: false,
    isLoading: false,
    isResizing: false,
    isRotating: false,
    lastPointerDownWith: "mouse",
    lastPointerLocation: null,
    multiElement: null,
    name: `${t("labels.untitled")}-${getDateTime()}`,
    openMenu: null,
    openSidebar: null,
    openPopup: null,
    openDialog: null,
    pasteDialog: { shown: false, data: null },
    previousSelectedElementIds: {},
    resizingElement: null,
    scrolledOutside: false,
    scrollX: 0 as FlooredNumber,
    scrollY: 0 as FlooredNumber,
    selectedElementIds: {},
    selectedGroupIds: {},
    selectionElement: null,
    selectedLinearElement: null,
    selectedElementsAreBeingDragged: false,
    // shouldAddWatermark: false,
    shouldCacheIgnoreZoom: false,
    showHelpDialog: false,
    showStats: false,
    startBoundElement: null,
    suggestedBindings: [],
    toastMessage: null,
    viewModeEnabled: false,
    viewBackgroundColor: "#f8f9fa",
    zenModeEnabled: false,
    zoom: { value: 1 as NormalizedZoomValue },
    isLanguageTranslatorActive: false,
    isTableActive: false,
    isCalcOpen: false,
    isCalculatorActive: false,
    isCurrencyConverterActive: false,
    isUnitConverterActive: false,
    isChatActive: false,
    isStickyNote: true,
    isCommentActive: false,
    selectedCategory: "null",
    showMenu: false,
    isMicActive: false,
    boardName: "Board Name",
    isAudioAnnotationActive: true,
    isLatexEditorActive: false,
    isHost: false,
    isMomNotesWindowActive: false,
    isBoardLocked: false,
    isCommentDisabled: false,
    isPasswordProtected: false,
    msgCount: 0,
    boardPass: "",
    isBlockedOnBoard: false,
    isOnlyView: false,
    isBoardEditableForCollaborator: true,
    baseMeeting: "",
    loader: { isActive: false, msg: "Loading..." },
    eventSettings: {
      isVideoRoomActive: false,
      isWaitingRoomActive: false,
    },
    isAllowedInRoom: false,
    highlighterDefaultColor: oc.green[4],
    showHyperlinkPopup: false,
    showCollaboratorCursor: false,
    lastPointerLocationComment: null,
    isTemplate: false,
    isGuest: false,
    mathElementId: "",
    mathFormulaText: "",
    connectorElement: null,
    frameRendering: { enabled: true, clip: true, name: true, outline: true },
    frameToHighlight: null,
    editingFrame: null,
    elementsToHighlight: null,
    pendingImageElementId: null,
    defaultSidebarDockedPreference: false,
    toast: null,
    snapLines: [],
    originSnapOffset: {
      x: 0,
      y: 0,
    },
    objectsSnapModeEnabled: true,
  };
};

// @ts-ignore
/**
 * Config containing all AppState keys. Used to determine whether given state
 *  prop should be stripped when exporting to given storage type.
 */
const APP_STATE_STORAGE_CONF = (<
  Values extends {
    /** whether to keep when storing to browser storage (localStorage/IDB) */
    browser: boolean;
    /** whether to keep when exporting to file/database */
    export: boolean;
    /** server (shareLink/collab/...) */
    server: boolean;
  },
  T extends Record<keyof AppState, Values>
>(
  config: { [K in keyof T]: K extends keyof AppState ? T[K] : never },
) => config)({
  eventDetail: { browser: true, export: false, server: false },
  appearance: { browser: true, export: false, server: false },
  collaborators: { browser: false, export: false, server: false },
  currentChartType: { browser: true, export: false, server: false },
  currentItemBackgroundColor: { browser: true, export: false, server: false },
  currentItemEndArrowhead: { browser: true, export: false, server: false },
  currentItemFillStyle: { browser: true, export: false, server: false },
  currentItemFontFamily: { browser: true, export: false, server: false },
  currentItemFontWeight: { browser: true, export: false, server: false },
  currentItemFontStyle: { browser: true, export: false, server: false },
  currentItemFontSize: { browser: true, export: false, server: false },
  currentItemLinearStrokeSharpness: { browser: true, export: false, server: false },
  currentItemOpacity: { browser: true, export: false, server: false },
  currentItemRoughness: { browser: true, export: false, server: false },
  currentItemStartArrowhead: { browser: true, export: false, server: false },
  currentItemStrokeColor: { browser: true, export: false, server: false },
  currentItemStrokeSharpness: { browser: true, export: false, server: false },
  currentItemStrokeStyle: { browser: true, export: false, server: false },
  currentItemStrokeWidth: { browser: true, export: false, server: false },
  currentItemTextAlign: { browser: true, export: false, server: false },
  cursorButton: { browser: true, export: false, server: false },
  draggingElement: { browser: false, export: false, server: false },
  editingElement: { browser: false, export: false, server: false },
  editingGroupId: { browser: true, export: false, server: false },
  editingLinearElement: { browser: false, export: false, server: false },
  elementLocked: { browser: true, export: false, server: false },
  elementType: { browser: true, export: false, server: false },
  errorMessage: { browser: false, export: false, server: false },
  exportBackground: { browser: true, export: false, server: false },
  exportEmbedScene: { browser: true, export: false, server: false },
  fileHandle: { browser: false, export: false, server: false },
  gridSize: { browser: true, export: true, server: false },
  height: { browser: false, export: false, server: false },
  isBindingEnabled: { browser: false, export: false, server: false },
  isLibraryOpen: { browser: false, export: false, server: false },
  isLoading: { browser: false, export: false, server: false },
  isResizing: { browser: false, export: false, server: false },
  isRotating: { browser: false, export: false, server: false },
  lastPointerDownWith: { browser: true, export: false, server: false },
  multiElement: { browser: false, export: false, server: false },
  name: { browser: true, export: false, server: false },
  offsetLeft: { browser: false, export: false, server: false },
  offsetTop: { browser: false, export: false, server: false },
  openMenu: { browser: true, export: false, server: false },
  pasteDialog: { browser: false, export: false, server: false },
  previousSelectedElementIds: { browser: true, export: false, server: false },
  resizingElement: { browser: false, export: false, server: false },
  scrolledOutside: { browser: true, export: false, server: false },
  scrollX: { browser: true, export: false, server: false },
  scrollY: { browser: true, export: false, server: false },
  selectedElementIds: { browser: true, export: false, server: false },
  selectedGroupIds: { browser: true, export: false, server: false },
  selectionElement: { browser: false, export: false, server: false },
  // shouldAddWatermark: { browser: true, export: false },
  shouldCacheIgnoreZoom: { browser: true, export: false, server: false },
  showHelpDialog: { browser: false, export: false, server: false },
  showStats: { browser: true, export: false, server: false },
  startBoundElement: { browser: false, export: false, server: false },
  suggestedBindings: { browser: false, export: false, server: false },
  toastMessage: { browser: false, export: false, server: false },
  viewBackgroundColor: { browser: true, export: true, server: false },
  width: { browser: false, export: false, server: false },
  zenModeEnabled: { browser: true, export: false, server: false },
  zoom: { browser: true, export: false, server: false },
  isCalculatorActive: { browser: true, export: false, server: false },
  isChatActive: { browser: true, export: false, server: false },
  isStickyNote: { browser: true, export: false, server: false },
  isCommentActive: { browser: true, export: false, server: false },
  selectedCategory: { browser: true, export: false, server: false },
  showMenu: { browser: true, export: false, server: false },
  isMicActive: { browser: true, export: false, server: false },
  boardName: { browser: true, export: true, server: false },
  isAudioAnnotationActive: { browser: true, export: false, server: false },
  isLatexEditorActive: { browser: true, export: false, server: false },
  isHost: { browser: true, export: false, server: false },
  isMomNotesWindowActive: { browser: true, export: false, server: false },
  isBoardLocked: { browser: true, export: false, server: false },
  isPasswordProtected: { browser: true, export: false, server: false },
  msgCount: { browser: true, export: false, server: false },
  boardPass: { browser: true, export: false, server: false },
  isBlockedOnBoard: { browser: true, export: false, server: false },
  isBoardEditableForCollaborator: { browser: true, export: false, server: false },
  baseMeeting: { browser: true, export: false, server: false },
  loader: { browser: true, export: false, server: false },
  eventSettings: { browser: true, export: false, server: false },
  isAllowedInRoom: { browser: true, export: false, server: false },
  highlighterDefaultColor: { browser: true, export: false, server: false },
  showHyperlinkPopup: { browser: false, export: false, server: false },
  isTemplate: { browser: false, export: false, server: false },
  isGuest: { browser: false, export: false, server: false },
  mathElementId: { browser: true, export: false, server: false },
  mathFormulaText: { browser: true, export: false, server: false },
  openSidebar: { browser: true, export: false, server: false },
  openPopup: { browser: true, export: false, server: false },
  defaultSidebarDockedPreference: { browser: true, export: false, server: false },
  toast: { browser: true, export: false, server: false },
  contextMenu: {
    browser: false,
    export: false,
    server: false,
  },
  shouldAddWatermark: {
    browser: false,
    export: false,
    server: false,
  },
  currentItemRoundness: {
    browser: false,
    export: false,
    server: false,
  },
  isCalcOpen: {
    browser: false,
    export: false,
    server: false,
  },
  isLanguageTranslatorActive: {
    browser: false,
    export: false,
    server: false,
  },
  isTableActive: {
    browser: false,
    export: false,
    server: false,
  },
  isCurrencyConverterActive: {
    browser: false,
    export: false,
    server: false,
  },
  isUnitConverterActive: {
    browser: false,
    export: false,
    server: false,
  },
  isCommentDisabled: {
    browser: false,
    export: false,
    server: false,
  },
  isOnlyView: {
    browser: false,
    export: false,
    server: false,
  },
  penMode: {
    browser: false,
    export: false,
    server: false,
  },
  penDetected: {
    browser: false,
    export: false,
    server: false,
  },
  showCollaboratorCursor: {
    browser: false,
    export: false,
    server: false,
  },
  lastPointerLocation: {
    browser: false,
    export: false,
    server: false,
  },
  lastPointerLocationComment: {
    browser: false,
    export: false,
    server: false,
  },
  connectorElement: {
    browser: false,
    export: false,
    server: false,
  },
  activeEmbeddable: {
    browser: false,
    export: false,
    server: false,
  },
  selectedLinearElement: {
    browser: false,
    export: false,
    server: false,
  },
  viewModeEnabled: {
    browser: false,
    export: false,
    server: false,
  },
  frameToHighlight: {
    browser: false,
    export: false,
    server: false,
  },
  frameRendering: {
    browser: false,
    export: false,
    server: false,
  },
  editingFrame: {
    browser: false,
    export: false,
    server: false,
  },
  elementsToHighlight: {
    browser: false,
    export: false,
    server: false,
  },
  theme: {
    browser: false,
    export: false,
    server: false,
  },
  pendingImageElementId: {
    browser: false,
    export: false,
    server: false,
  },
  openDialog: {
    browser: false,
    export: false,
    server: false,
  },
  exportScale: {
    browser: false,
    export: false,
    server: false,
  },
  exportWithDarkMode: {
    browser: false,
    export: false,
    server: false,
  },
  selectedElementsAreBeingDragged: {
    browser: false,
    export: false,
    server: false,
  },
  activeTool: {
    browser: false,
    export: false,
    server: false,
  },
  snapLines: { browser: false, export: false, server: false },
  originSnapOffset: { browser: false, export: false, server: false },
  objectsSnapModeEnabled: { browser: true, export: false, server: false },
});

const _clearAppStateForStorage = <
  ExportType extends "export" | "browser" | "server"
>(
  appState: Partial<AppState>,
  exportType: ExportType,
) => {

  type ExportableKeys = {
    [K in keyof typeof APP_STATE_STORAGE_CONF]: typeof APP_STATE_STORAGE_CONF[K][ExportType] extends true
      ? K
      : never;
  }[keyof typeof APP_STATE_STORAGE_CONF];
  const stateForExport = {} as { [K in ExportableKeys]?: typeof appState[K] };
  for (const key of Object.keys(appState) as (keyof typeof appState)[]) {
    const propConfig = APP_STATE_STORAGE_CONF[key];
    if (propConfig?.[exportType]) {
      // @ts-ignore see https://github.com/microsoft/TypeScript/issues/31445
      stateForExport[key] = appState[key];
    }
  }
  return stateForExport;
};

export const clearAppStateForLocalStorage = (appState: Partial<AppState>) => {
  return _clearAppStateForStorage(appState, "browser");
};

export const cleanAppStateForExport = (appState: Partial<AppState>) => {
  return _clearAppStateForStorage(appState, "export");
};

export const clearAppStateForDatabase = (appState: Partial<AppState>) => {
  return _clearAppStateForStorage(appState, "server");
};

export const isEraserActive = ({
                                 activeTool,
                               }: {
  activeTool: AppState["activeTool"];
}) => activeTool.type === "eraser";

export const isHandToolActive = ({
                                   activeTool,
                                 }: {
  activeTool: AppState["activeTool"];
}) => {
  return activeTool.type === "hand";
};
