// @ts-nocheck
import React, { useContext, useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import AsyncSelect from "react-select/async";
import "./dashboardSidebar.scss";
import {
  getOrgProjectMembersList,
  startMeeting,
} from "../dashboardMain/dashboardMain";
import { APIService } from "../../../services/api/api-service";
import {
  ROUTES,
  CONSTANT,
  USER_BOARDS,
  ALERT_TYPE,
} from "../../../constants/constants";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Backdrop, Fade, Modal } from "@material-ui/core";
import { AddTeam } from "../../../models/team-group.model";
import {
  ProjectListGroup,
  AddProject,
} from "../../../models/project-group.model";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

import {
  addTeam,
  getAllTeamList,
  leaveTeamMember,
  leaveTeamAdminApi,
  searchOrganisationsApi,
  addUserOrganisationAndSendMailApi,
  getCurrentUserOrgApi,
  checkOrgAccess,
  addOrganisationApi,
  removeOrganisationFromUser,
  removeOrganisationFromUserAdmin,
  deleteTeamApi,
  addTeamMembers,
  getScheduleMeetingListApi,
  transferTeamOwnership,
  getMonthlyScheduleMeetingListApi,
  getGoogleCalenderEventsApi,
  getOutlookCalenderEventsApi,
} from "../../../services/v2/team/team-service";
import {
  addProject,
  getAllProjectList,
} from "../../../services/v2/project/project-service";
import { TeamContext } from "../../../context/TeamContext";
import {
  getUserDetails,
  getOrganisationDetails,
  signOut,
} from "../../../services/auth-service";
import { randomId } from "../../../random";
import { BOARD_ACCESS } from "../../../constants/constants";
import TagsInput from "react-tagsinput";
import { UtilityService } from "../../../services/common/utilityService";
import { CommonSocketService } from "../../../services/socket-services/common-socket.service";
import { CreateTeamBoard } from "../../create-team-board/CreateTeamBoard";
import { CreateTeamProject } from "../../create-team-board/CreateTeamProject";
import { editIcon } from "../../../components/icons";
import { RescheduleMeeting } from "../../reschedule-meeting/RescheduleMeeting";
const moment = require("moment-timezone");

interface SelectedOrgUser {
  organisationUserList?: any;
}

export const DashboardSidebar: React.FC<SelectedOrgUser> = (props) => {
  const history = useHistory();
  const context = useContext(TeamContext);
  const {
    setTeam,
    setProject,
    setTeamListArr,
    setProjectListArr,
    setOrgListArr,
    setOrganisation,
  } = context;
  const {
    team,
    project,
    teamListArr,
    projectListArr,
    orgListArr,
    organisation,
  } = context.state;

  const renderEventContent = (eventInfo: any) => {
    if (eventInfo) {
      const isPast = moment(
        moment(eventInfo.event.start).format(),
      ).isSameOrAfter(moment().format());
      return (
        <>
          <div
            className={
              isPast
                ? "fc-daygrid-event-dot"
                : "fc-daygrid-event-dot fc-daygrid-event-dot-grey"
            }
          />
          <b>{moment(eventInfo.event.start).format("hh:mm A")}</b>&nbsp;&nbsp;
          <b>{eventInfo.event.title}</b>
        </>
      );
    }
  };

  const selectedOrganisation = getOrganisationDetails();
  const defaultScheduleMeetingCount = 4;

  const [calendarEvents, setCalendarEvents] = useState<any>([]);
  const [redirect, setRedirect] = useState<boolean>(false);
  const [requestingServer, setRequestingServer] = useState<boolean>(false);
  const [boardLink, setBoardLink] = useState("");
  const [calenderData, setCalenderData] = useState(new Date());
  const [timeZoneList, setTimeZoneList] = useState(moment.tz.names());
  const [eventDetail, setEventDetail] = useState<any>({
    eventName: "",
    eventDescription: "",
    eventDate: "",
    initatorUsername: "",
    notes: [],
    annotation: [],
    collaborators: [],
    initatorDetails: {},
    recordingMeta: [],
    team_id: "",
    project_id: "",
    organisation_id: "",
    org_project_id: "",
    participant_access: "",
    org_project_access: "",
    email_invitations: [],
    timezone: "America/New_York",
    co_host: "",
    schedule_meeting_access_type: 2,
    notification_duration_type: "minute",
    notification_duration_value: 10,
  });

  const [addTeamName, setaddTeamName] = useState<AddTeam>({
    name: "",
    organisation_id: "",
  });
  const [projectList, setprojectList] = React.useState<ProjectListGroup[]>([]);
  const [addProjectName, setaddProjectName] = useState<AddProject>({
    name: "",
    organisation_id: "",
  });
  const [isAdmin, setIsAdmin] = React.useState(false);
  const currentLoginUser = getUserDetails();

  const [scheduleMeetingList, setScheduleMeetingList] = React.useState<any>([]);
  const [
    allDataScheduleMeetingList,
    setAllDataScheduleMeetingList,
  ] = React.useState<any>([]);
  const [scheduleMeetingCount, setScheduleMeetingCount] = React.useState<any>(
    defaultScheduleMeetingCount,
  );
  const [isDisplayViewAll, setIsDisplayViewAll] = React.useState<any>(false);

  const [selectedInvitationEmail, setSelectedInvitationEmail] = useState([]);
  const handleChange = (tags: any) => {
    setEventDetail({
      ...eventDetail,
      email_invitations: tags,
    });
    setSelectedInvitationEmail(tags);
    const mergedUsers = [...orgProjectMembersList, ...tags].filter(
      (item, i, ar) => {
        return ar.indexOf(item) === i;
      },
    );
    // @ts-ignore
    setCoHostUserList(mergedUsers);
  };

  const startMeet = () => {
    setRequestingServer(true);
    //console.log("eventDetail", eventDetail);
    if (!eventDetail.timezone) {
      UtilityService.showNotification(
        ALERT_TYPE.ERROR,
        "Please select timezone",
      );
      return false;
    }
    startMeeting(2, eventDetail).then((data: any) => {
      setRequestingServer(false);
      if (data && data.boardLink) {
        setBoardLink(data.boardLink);
        setTimeout(() => {
          //setRedirect(true);
          //setRequestingServer(false);
          //handleCloseScheduleMeetingModal();
          // window.open(APIService.googleCalenderLink(boardLink), "_blank");
          const redirectLink = APIService.generateCalendarURL(
            eventDetail.eventName,
            moment(eventDetail.eventDate),
            moment(eventDetail.eventDate).add(1, "hour"),
            eventDetail.timezone,
            `You are invited to join eflok meeting by ${
              getUserDetails().displayName
            }.<br> <a href="${data.boardLink}">Click here to join</a>`,
            data.boardLink,
            data.collaborators.map((mp: any) => mp.collaboratorName),
          );
          window.open(redirectLink, "_blank");
          //history.push(ROUTES.DASHBOARD);
          window.location.reload();
        }, 1000);
      }
    });
  };

  const viewAllScheduleMeeting = () => {
    setIsDisplayViewAll(false);
    setScheduleMeetingCount(allDataScheduleMeetingList.length);
    setScheduleMeetingList(allDataScheduleMeetingList);
  };

  const [open, setOpen] = React.useState(false);
  const [openCreateTeam, setOpenCreateTeam] = React.useState(false);
  const [openCreateProject, setOpenCreateProject] = React.useState(false);

  const handleOpenScheduleMeetingModal = () => {
    setEventDetail({
      ...eventDetail,
      org_project_id: project?._id
        ? project?._id
        : organisation?.organisationId?._id,
      participant_access: "can-edit",
      org_project_access: "can-edit",
    });
    setOpen(true);
    onOrgProjectChange(
      project?._id ? project?._id : organisation?.organisationId?._id,
    );
  };

  const handleCloseScheduleMeetingModal = () => {
    setEventDetail({
      eventName: "",
      eventDescription: "",
      eventDate: "",
      initatorUsername: "",
      notes: [],
      annotation: [],
      collaborators: [],
      initatorDetails: {},
      recordingMeta: [],
      team_id: "",
      project_id: "",
      organisation_id: "",
      org_project_id: "",
      email_invitations: [],
      timezone: "America/New_York",
      co_host: "",
      schedule_meeting_access_type: 2,
      notification_duration_type: "minute",
      notification_duration_value: 10,
    });
    setOpen(false);
  };

  const handleOpenCraeteTeam = () => {
    setOpenCreateTeam(true);
  };

  const handleCloseCraeteTeam = () => {
    setOpenCreateTeam(false);
  };

  if (redirect === true) {
    window.open(APIService.googleCalenderLink(boardLink), "_blank");
  }
  const handleOpenCraeteProject = () => {
    setOpenCreateProject(true);
  };
  const handleCloseProject = () => {
    setOpenCreateProject(false);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const addingTeam = () => {
    const addTeamObj = {
      ...addTeamName,
      organisation_id: organisation.organisationId._id,
    };
    addTeam(addTeamObj).then((data: any) => {
      if (data.status) {
        setaddTeamName({ ...addTeamName, name: "" });
        localStorage.removeItem(CONSTANT.SELECTED_TEAM);
        handleCloseCraeteTeam();
        getTeamList();
      }
    });
  };

  const getTeamList = () => {
    if (organisation.organisationId && organisation.organisationId._id) {
      getAllTeamList(organisation.organisationId._id).then((result) => {
        if (result.data && result.data.length > 0) {
          const teamcheck = result.data.filter(
            (item: any) =>
              item._id == localStorage.getItem(CONSTANT.SELECTED_TEAM),
          );
          if (teamcheck == 0) {
            localStorage.removeItem(CONSTANT.SELECTED_TEAM);
          }

          setTeamListArr(result.data);
          const selectedTeam = localStorage.getItem(CONSTANT.SELECTED_TEAM);
          if (selectedTeam) {
            const teamObj = result.data.find(
              (v: { _id: string | null }) => v._id == selectedTeam,
            );
            if (teamObj) {
              setTeam(teamObj);
            }
          }
        } else {
          setTeamListArr([]);
          setTeam({});
          //setProject({})
        }
      });
    }
  };

  const addingProject = () => {
    //const projectObj = { ...addProjectName, team_id: team._id, organisation_id: organisation.organisationId._id }
    const projectObj = {
      ...addProjectName,
      organisation_id: organisation.organisationId._id,
    };
    addProject(projectObj).then((data: any) => {
      if (data.status) {
        setaddProjectName({ ...addProjectName, name: "" });
        handleCloseProject();
        getProjectList();
      }
    });
  };

  const getProjectList = () => {
    if (
      organisation &&
      organisation.organisationId &&
      organisation.organisationId._id
    ) {
      getAllProjectList(organisation.organisationId._id).then((result) => {
        if (result.data && result.data.length > 0) {
          const selectedProject = localStorage.getItem(
            CONSTANT.SELECTED_PROJECT,
          );
          setprojectList(result.data);
          setProjectListArr(result.data);
          if (selectedProject) {
            const projectObj = result.data.find(
              (v: { _id: string | null }) => v._id == selectedProject,
            );
            if (projectObj) {
              setProject(projectObj);
            }
          }
        } else {
          const redirectDashobard = !(
            history.location.pathname == USER_BOARDS.STARRED_BOARD ||
            history.location.pathname == USER_BOARDS.RECENT_BOARD ||
            history.location.pathname == ROUTES.INVITATION_BOARDS ||
            history.location.pathname == ROUTES.PROCTORING_BOARDS ||
            history.location.pathname == ROUTES.TRASH_BOARDS
          );
          if (redirectDashobard) {
            history.push(ROUTES.DASHBOARD);
          }
          setprojectList([]);
          setProjectListArr([]);
          setProject({});
        }
      });
    }
  };

  // const changeTeam = (team: any) => {
  //   localStorage.setItem(CONSTANT.SELECTED_TEAM, team._id);
  //   team.lastClickAt = randomId();
  //   setTeam(team);
  //   setProject({});
  //   localStorage.removeItem(CONSTANT.SELECTED_PROJECT);

  //   if (history.location.pathname == ROUTES.SELECTED_PROJECT || history.location.pathname == USER_BOARDS.RECENT_BOARD || history.location.pathname == USER_BOARDS.STARRED_BOARD) {
  //     history.push(ROUTES.DASHBOARD);
  //   }
  // }

  const handelLeaveTeamMember = () => {
    leaveTeamMember({
      team_id: team._id,
      leave_member_id: getUserDetails()._id,
    }).then((data: any) => {
      if (data.status) {
        closeTeamDetailsModal();
        getTeamList();
      }
    });
  };

  const handelLeaveTeamAdmin = () => {
    openCraeteMemberModal();
  };

  const [openAddMember, setOpenAddMember] = React.useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isCheck, setIsCheck] = useState<any>([]);
  const [userList, setuserList] = useState<any>([]);

  const openCraeteMemberModal = () => {
    setOpenAddMember(true);
  };

  const closeCraeteMemberModal = () => {
    setOpenAddMember(false);
  };

  const handleClickInputSearch = (e: any) => {
    const { id, checked } = e.target;
    //console.log("id", id, checked);
    setIsCheck([id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item: any) => item !== id));
    }
  };

  const leaveTeamAdminFilterSearch = (data: any) => {
    const inputSearch = searchValue ? searchValue.toLowerCase() : "";
    const userName = data?.users?.user_name
      ? data.users.user_name.toLowerCase()
      : "";
    const firstName = data?.users?.firstname
      ? data.users.firstname.toLowerCase()
      : "";
    const lastName = data?.users?.lastname
      ? data.users.lastname.toLowerCase()
      : "";

    const response =
      userName.includes(inputSearch) ||
      firstName.includes(inputSearch) ||
      lastName.includes(inputSearch);

    return response;
  };

  const handelChangeAdmin = () => {
    if (isCheck[0]) {
      leaveTeamAdminApi({
        team_id: team._id,
        leave_admin_member_id: getUserDetails()._id,
        new_admin_member_id: isCheck[0],
      }).then((data: any) => {
        //console.log("data", data);
        if (data.status) {
          closeCraeteMemberModal();
          getTeamList();
        }
      });
    }
  };

  const handleClickProject = (project: any) => {
    project.lastClickAt = randomId();
    setProject(project);
    localStorage.setItem(CONSTANT.SELECTED_PROJECT, project?._id);
    localStorage.removeItem(CONSTANT.SELECTED_TEAM);
    history.push(ROUTES.SELECTED_PROJECT);
  };

  const [openSearchOrg, setOpenSearchOrg] = React.useState(false);
  const [searchOrg, setSearchOrg] = useState("");
  const [noOrgMessage, setNoOrgMessage] = React.useState(false);
  const [currentDate, setCurrentDate] = React.useState("");

  const handleOpenSearchOrganization = () => {
    setOpenSearchOrg(true);
  };

  const handleCloseSearchOrganization = () => {
    setOpenSearchOrg(false);
  };

  const getOrgData = (input: any, callback: (options: any) => void) => {
    searchOrganisationsApi({ name: input }).then((data: any) => {
      setTimeout(() => {
        callback(data.data);
      }, 1000);
    });
  };

  const addOrganizationSearch = () => {
    addUserOrganisationAndSendMailApi({
      user_id: getUserDetails()._id,
      organisation_id: searchOrg,
      role: "partial-access",
    }).then((data: any) => {
      if (data.status) {
        setOpenSearchOrg(false);
        setSearchOrg("");
      }
    });
  };

  const monthlyEventList = async (startDate: any, endDate: any) => {
    const customEventsReq = getMonthlyScheduleMeetingListApi(
      startDate,
      endDate,
    );
    const googleEventsReq = getGoogleCalenderEventsApi(startDate, endDate);
    const outlookEventsReq = getOutlookCalenderEventsApi(startDate, endDate);
    const [customEvents, googleEvents, outlookEvents] = await Promise.all([
      customEventsReq,
      googleEventsReq,
      outlookEventsReq,
    ]);
    const dataSetOne = await processCustomEventsData(customEvents);
    const dataSetTwo = await processGoogleEventsData(googleEvents);
    const dataSetThree = await processOutlookEventsData(outlookEvents);
    setCalendarEvents([...dataSetOne, ...dataSetTwo, ...dataSetThree]);
  };

  const processCustomEventsData = async (customEvents: any) => {
    const array: any = [];
    if (
      customEvents.status &&
      customEvents.data &&
      customEvents.data.length > 0
    ) {
      return Promise.all(
        customEvents.data.map((d: any) => {
          const boardLink = `${process.env.REACT_APP_DOMAIN}/board#room=${d?.meetingId}`;
          array.push({
            ...d,
            title: d.eventName,
            url: boardLink,
            start: moment(d.eventDate).format(),
          });
        }),
      ).then(() => {
        return array;
      });
    }
    return [];
  };

  const processGoogleEventsData = async (googleEvents: any) => {
    const arr: any = [];
    if (
      googleEvents.status &&
      googleEvents.data &&
      googleEvents.data.length > 0
    ) {
      return Promise.all(
        googleEvents.data.map((d: any) => {
          arr.push({
            id: d.id,
            title: d.summary,
            url: d.htmlLink,
            start: moment(d.start.dateTime).format(),
          });
        }),
      ).then(() => {
        return arr;
      });
    }
    return [];
  };

  const processOutlookEventsData = async (outlookEvents: any) => {
    const arr: any = [];
    if (
      outlookEvents.status &&
      outlookEvents.data &&
      outlookEvents.data.length > 0
    ) {
      return Promise.all(
        outlookEvents.data.map((d: any) => {
          arr.push({
            id: d.id,
            title: d.subject,
            url: d.webLink,
            start: moment(d.start.dateTime).format(),
          });
        }),
      ).then(() => {
        return arr;
      });
    }
    return [];
  };

  const onDateClick = (e: any) => {
    setCalenderData(new Date(e.date));
    changeScheduleMeetingDate(new Date(e.date));
  };

  const handleDatesSet = (e: any) => {
    const startDate = moment(e.view.title).startOf("month");
    const endDate = moment(e.view.title).endOf("month");
    monthlyEventList(startDate, endDate);
  };

  const getOrganisationList = () => {
    getCurrentUserOrgApi().then((response: any) => {
      if (response.status) {
        if (response.data && response.data.length > 0) {
          setOrgListArr(response.data);
          if (selectedOrganisation && selectedOrganisation?._id) {
            const organisationObj = response.data.find(
              (v: any) =>
                v?.organisationId?._id ==
                selectedOrganisation?.organisationId?._id,
            );
            if (organisationObj?._id) {
              setOrganisation(organisationObj); //CONTEXT
            } else {
              localStorage.setItem(
                CONSTANT.SELECTED_ORGANISATION,
                JSON.stringify(response.data[0]),
              );
              setOrganisation(response.data[0]); //CONTEXT
            }
          } else {
            localStorage.setItem(
              CONSTANT.SELECTED_ORGANISATION,
              JSON.stringify(response.data[0]),
            ); //LOCAL STORAGE
            setOrganisation(response.data[0]); //CONTEXT
          }
          setNoOrgMessage(false);
        } else {
          setNoOrgMessage(true);
          handleCreateOrg();
        }
      }
    });
  };

  const changeOrganisation = (organisationObj: any) => {
    localStorage.setItem(
      CONSTANT.SELECTED_ORGANISATION,
      JSON.stringify(organisationObj),
    ); //LOCAL STORAGE
    organisationObj.lastClickAt = randomId();
    setOrganisation(organisationObj); //CONTEXT

    setTeam({});
    setProject({});
    localStorage.removeItem(CONSTANT.SELECTED_TEAM);
    localStorage.removeItem(CONSTANT.SELECTED_PROJECT);

    if (
      history.location.pathname == ROUTES.SELECTED_PROJECT ||
      history.location.pathname == USER_BOARDS.STARRED_BOARD ||
      history.location.pathname == USER_BOARDS.RECENT_BOARD ||
      history.location.pathname == ROUTES.INVITATION_BOARDS ||
      history.location.pathname == ROUTES.PROCTORING_BOARDS ||
      history.location.pathname == ROUTES.TRASH_BOARDS
    ) {
      history.push(ROUTES.DASHBOARD);
    }
  };

  const getScheduleMeetingList = (
    orgId: any,
    selectedDate: any = new Date().toJSON().slice(0, 10),
  ) => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const currentDate = new Date();

    getScheduleMeetingListApi(orgId, selectedDate).then((result) => {
      if (result.status && result.data && result.data.length > 0) {
        result.data.map((meeting: any) => {
          const systemSpecificTime = new Date(
            (typeof meeting.eventDate === "string"
              ? new Date(meeting.eventDate)
              : meeting.eventDate
            ).toLocaleString("en-US", { timeZone: userTimeZone }),
          );
          return (meeting.eventDate = systemSpecificTime);
        });

        //if (result.data.length > defaultScheduleMeetingCount || new Date().toJSON().slice(0, 10) == selectedDate && result.data.length !== 0) {
        if (result.data.length > defaultScheduleMeetingCount) {
          setIsDisplayViewAll(true);
        }

        const sortedDate = result.data.sort(
          (a: any, b: any) => a.eventDate - b.eventDate,
        );
        //console.log("sortedDate", sortedDate);
        setAllDataScheduleMeetingList(sortedDate);

        if (new Date().toJSON().slice(0, 10) > selectedDate) {
          setScheduleMeetingList(sortedDate);
        } else {
          const filterData = sortedDate.filter((i: any) => {
            return i.eventDate >= currentDate;
          });
          setScheduleMeetingList(filterData);
        }

        //if (new Date().toJSON().slice(0, 10) == selectedDate && scheduleMeetingList !== allDataScheduleMeetingList && sortedDate !== 0) {
        if (new Date().toJSON().slice(0, 10) == selectedDate) {
          const todayPastMeeting = sortedDate.filter((i: any) => {
            return i.eventDate <= currentDate;
          });
          if (todayPastMeeting.length) {
            setIsDisplayViewAll(true);
          }
        }
      } else {
        setScheduleMeetingList([]);
      }
    });
  };

  const changeScheduleMeetingDate = (selectedDate: any) => {
    setIsDisplayViewAll(false);
    setScheduleMeetingCount(defaultScheduleMeetingCount);
    getScheduleMeetingList(
      organisation?.organisationId?._id,
      UtilityService.formateDateStrtoYMD(selectedDate),
    );
  };

  useEffect(() => {
    monthlyEventList(moment().startOf("month"), moment().endOf("month"));
    if (organisation && Object.keys(organisation).length) {
      getTeamList();
      getProjectList();
      if (currentDate) {
        getScheduleMeetingList(organisation?.organisationId?._id, currentDate);
      } else {
        getScheduleMeetingList(organisation?.organisationId?._id);
      }
      (async () => {
        const checkOrg = await checkOrgAccess(organisation, 2);
        setIsAdmin(checkOrg);
      })();
    } else {
      setIsAdmin(false);
    }

    setEventDetail({
      ...eventDetail,
      organisation_id: organisation?.organisationId?._id,
    });
  }, [organisation, organisation.lastClickAt]);

  useEffect(() => {
    if (team && team.members && team.members.length > 0) {
      const arrRemoveCurrentUser = team.members.filter(
        (item: any) => item.users._id !== getUserDetails()._id,
      ); //REMOVE CURRENT USER
      setuserList(team.members);
      //console.log("userList", team.members);
    }
  }, [team]);

  useEffect(() => {
    if (project) {
      const updateProjects = projectList.map((v: any) => {
        if (v._id === project._id) {
          return project;
        }
        return v;
      });
      setprojectList(updateProjects);
      setEventDetail({ ...eventDetail, project_id: project?._id });
    }
  }, [project]);

  useEffect(() => {
    getOrganisationList();
  }, []);

  // Add Org
  const [openAddOrg, setOpenAddOrg] = React.useState(false);
  const [orgName, setOrgName] = useState("");

  const handleCreateOrg = () => {
    setOpenAddOrg(true);
  };

  const handleCloseCreateOrg = () => {
    setOpenAddOrg(false);
    setOrgName("");
    getOrganisationList();
  };

  const addingOrganization = () => {
    if (!orgName) {
      UtilityService.showNotification(
        ALERT_TYPE.ERROR,
        "Please enter organisation name.",
      );
      return;
    }
    addOrganisationApi({ name: orgName }).then((data: any) => {
      if (data.status) {
        setOrgName("");
        setOpenAddOrg(false);
        getOrganisationList();
      }
    });
  };

  const handelLeaveOrgMember = () => {
    const apiPayload = {
      organisation_id: organisation.organisationId._id,
      user_id: getUserDetails()._id,
    };
    removeOrganisationFromUser(apiPayload).then((data: any) => {
      if (data.status) {
        if (data.data.organisation && data.data.organisation.length > 0) {
          localStorage.setItem(
            CONSTANT.SELECTED_ORGANISATION,
            JSON.stringify(data.data.organisation[0]),
          ); //LOCAL STORAGE
          setOrganisation(data.data.organisation[0]);
          setOrgListArr(data.data.organisation);
          setNoOrgMessage(false);
        } else {
          localStorage.removeItem(CONSTANT.SELECTED_ORGANISATION);
          setOrgListArr([]);
          setOrganisation({});

          setNoOrgMessage(true);
          handleCreateOrg();
        }
        funCloseConfirmMemberModal();
      }
    });
  };

  const handelLeaveOrgAdmin = () => {
    openNewOrgAdminModal();
  };

  //Confirm Box Start code
  const [openConfirmModal, setOpenConfirmModal] = React.useState(false);
  const [openConfirmMemberModal, setOpenConfirmMemberModal] = React.useState(
    false,
  );

  const funOpenConfirmModal = () => {
    setOpenConfirmModal(true);
  };

  const funCloseConfirmModal = () => {
    setOpenConfirmModal(false);
  };

  const handelOpenConfirmBox = () => {
    funOpenConfirmModal();
  };

  //MEMBER

  const funOpenConfirmMemberModal = () => {
    setOpenConfirmMemberModal(true);
  };

  const funCloseConfirmMemberModal = () => {
    setOpenConfirmMemberModal(false);
  };

  //Confirm Box End code //handelLeaveOrgMember

  //NEW ORG ADMIN ASSIGN CODE
  const [openOrgNewAdmin, setOpenOrgNewAdmin] = React.useState(false);
  const [newOrgAdminSearchValue, setNewOrgAdminSearchValue] = useState("");
  const [newOrgAdminIsCheck, setNewOrgAdminIsCheck] = useState<any>([]);
  const [submitButtonDisplay, setSubmitButtonDisplay] = React.useState(false);

  const openNewOrgAdminModal = () => {
    setOpenOrgNewAdmin(true);
  };

  const closeNewOrgAdminModal = () => {
    setOpenOrgNewAdmin(false);
  };

  const newOrgAdminClickInputSearch = (e: any) => {
    const { id, checked } = e.target;
    //console.log("id", id, checked);
    setNewOrgAdminIsCheck([id]);
    if (!checked) {
      setNewOrgAdminIsCheck(
        newOrgAdminIsCheck.filter((item: any) => item !== id),
      );
    }
  };

  useEffect(() => {
    if (newOrgAdminIsCheck.length > 0) {
      setSubmitButtonDisplay(true);
    } else {
      setSubmitButtonDisplay(false);
    }
  }, [newOrgAdminIsCheck]);

  const leaveOrgAdminFilterSearch = (data: any) => {
    if (currentLoginUser._id == data._id) {
      return false;
    }

    const inputSearch = newOrgAdminSearchValue
      ? newOrgAdminSearchValue.toLowerCase()
      : "";
    const userName = data?.user_name ? data.user_name.toLowerCase() : "";
    const firstName = data?.firstname ? data.firstname.toLowerCase() : "";
    const lastName = data?.lastname ? data.lastname.toLowerCase() : "";

    const response =
      userName.includes(inputSearch) ||
      firstName.includes(inputSearch) ||
      lastName.includes(inputSearch);

    return response;
  };

  const handelLeaveOrgAdminApi = () => {
    if (newOrgAdminIsCheck[0]) {
      const apiPayload = {
        organisation_id: organisation.organisationId._id,
        leave_admin_member_id: getUserDetails()._id,
        new_admin_member_id: newOrgAdminIsCheck[0],
      };
      removeOrganisationFromUserAdmin(apiPayload).then((data: any) => {
        if (data.status) {
          if (data.data.organisation && data.data.organisation.length > 0) {
            setOrgListArr(data.data.organisation);
            localStorage.setItem(
              CONSTANT.SELECTED_ORGANISATION,
              JSON.stringify(data.data.organisation[0]),
            ); //LOCAL STORAGE
            setOrganisation(data.data.organisation[0]);
            closeNewOrgAdminModal();
            funCloseConfirmModal();
          } else {
            localStorage.removeItem(CONSTANT.SELECTED_ORGANISATION);
            setOrganisation({});
            closeNewOrgAdminModal();
            funCloseConfirmModal();
            setNoOrgMessage(true);
            handleCreateOrg();
          }
        }
      });
    }
  };

  const BoardInThisOrganisation = () => {
    setTeam({});
    setProject({});
    localStorage.removeItem(CONSTANT.SELECTED_TEAM);
    localStorage.removeItem(CONSTANT.SELECTED_PROJECT);

    if (selectedOrganisation && selectedOrganisation?._id) {
      selectedOrganisation.lastClickAt = randomId();
      setOrganisation(selectedOrganisation); //CONTEXT
    }
    history.push(ROUTES.DASHBOARD);
  };

  const changeTeam = (team: any) => {
    localStorage.setItem(CONSTANT.SELECTED_TEAM, team._id);
    team.lastClickAt = randomId();
    setTeam(team);
    openTeamDetailsModal();
  };

  //TEAM MODEL
  const [openTeamModal, setOpenTeamModal] = React.useState(false);

  const openTeamDetailsModal = () => {
    getTeamList();
    setOpenTeamModal(true);
  };

  const closeTeamDetailsModal = () => {
    setOpenTeamModal(false);
  };

  const deleteTeams = () => {
    deleteTeamApi({ team_id: team._id }).then((data: any) => {
      if (data.data.status) {
        funCloseTeamDeleteConfirmModal();
        setTeam({});
        localStorage.removeItem(CONSTANT.SELECTED_TEAM);
        closeTeamDetailsModal();

        //Remove Team list deleted team
        if (teamListArr.length > 0) {
          const reamoveTeamIndex = teamListArr.findIndex(
            (teamObj: any) => teamObj._id == team._id,
          );
          if (reamoveTeamIndex !== -1) {
            teamListArr.splice(reamoveTeamIndex, 1);
            setTeamListArr(teamListArr);
          }
        }
      }
    });
  };

  //Add Team Members
  const [openAddTeamMember, setOpenAddTeamMember] = React.useState(false);
  const [permissionType, setPermissionType] = React.useState(
    BOARD_ACCESS.CAN_EDIT,
  );
  const [isCheckAllTeamMember, setIsCheckAllTeamMember] = useState<boolean>(
    false,
  );
  const [isCheckTeamMember, setIsCheckTeamMember] = useState<any>([]);
  const [addteamSearchValue, setAddteamSearchValue] = useState("");
  const [addTeamButtonDisplay, setAddTeamButtonDisplay] = React.useState(false);
  const [transferOwnership, setTransferOwnership] = React.useState(false);
  const [
    openCreateTeamBoardModal,
    setOpenCreateTeamBoardModal,
  ] = React.useState(false);
  const [
    openCreateTeamProjectModal,
    setOpenCreateTeamProjectModal,
  ] = React.useState(false);
  const [newOwner, setNewOwner] = React.useState("");

  const handlePermisionDropdown = (e: any) => {
    setPermissionType(e.target.value);
  };

  const openInviteTeamMemberModal = () => {
    setOpenAddTeamMember(true);
  };

  const CloseInviteTeamMemberModal = () => {
    setOpenAddTeamMember(false);
  };

  const SelectAllTeamMembers = (e: any) => {
    setIsCheckAllTeamMember(!isCheckAllTeamMember);
    setIsCheckTeamMember(props?.organisationUserList.map((li: any) => li._id));
    if (isCheckAllTeamMember) {
      setIsCheckTeamMember([]);
    }
  };
  const selectTeamMemberCheckBox = (e: any) => {
    const { id, checked } = e.target;
    setIsCheckTeamMember([...isCheckTeamMember, id]);
    if (!checked) {
      setIsCheckTeamMember(
        isCheckTeamMember.filter((item: any) => item !== id),
      );
    }
  };

  const addTeamMemberFilter = (data: any) => {
    if (currentLoginUser._id == data._id) {
      return false;
    }
    const inputSearch = addteamSearchValue
      ? addteamSearchValue.toLowerCase()
      : "";
    const userName = data?.user_name ? data.user_name.toLowerCase() : "";
    const firstName = data?.firstname ? data.firstname.toLowerCase() : "";
    const lastName = data?.lastname ? data.lastname.toLowerCase() : "";
    const response =
      userName.includes(inputSearch) ||
      firstName.includes(inputSearch) ||
      lastName.includes(inputSearch);
    return response;
  };

  useEffect(() => {
    if (isCheckTeamMember.length > 0) {
      setAddTeamButtonDisplay(true);
    } else {
      setAddTeamButtonDisplay(false);
    }
  }, [isCheckTeamMember]);

  const addingTeamMember = () => {
    const membersArr: any = [];
    isCheckTeamMember?.map((member: string) => {
      const memberList = {
        users: member,
        role: permissionType,
      };
      membersArr.push(memberList);
    });

    const apiPayload = {
      team_id: team._id,
      members: membersArr,
    };
    //console.log("apiPayload", apiPayload);

    addTeamMembers(apiPayload).then((data: any) => {
      //setTeam
      if (data.status) {
        const index = teamListArr.findIndex(
          (v: { _id: string }) => v._id === team._id,
        );
        const tempArr = [...teamListArr];
        if (index != -1) {
          tempArr[index] = data.data;
        }
        setTeamListArr(tempArr);
        setTeam(data.data);
        CloseInviteTeamMemberModal();
        setIsCheckTeamMember([]);
        setAddteamSearchValue("");
        setIsCheckAllTeamMember(false);
        CommonSocketService.commonEventService.updateOrganisationNotifications({
          group: "eflok_common_channel",
          _id: currentLoginUser._id,
          name: currentLoginUser.name,
          userName: currentLoginUser.username,
          data: data.data.members.map((user: any) => user.users.user_name),
        });
      }
    });
  };

  //Confirm Box Start code
  const [
    openTeamDeleteConfirmModal,
    setOpenTeamDeleteConfirmModal,
  ] = React.useState(false);
  const [
    openRemoveTeamMemberConfirmModal,
    setOpenRemoveTeamMemberConfirmModal,
  ] = React.useState(false);
  const [teamRemoveUser, setTeamRemoveUser] = React.useState("");
  const [coHostUserList, setCoHostUserList] = React.useState([]);
  const [orgProjectMembersList, setOrgProjectMembersList] = React.useState([]);
  const [openRescheduleModal, setOpenRescheduleModal] = React.useState(false);
  const [rescheduleMeetingId, setRescheduleMeetingId] = React.useState("");

  const funOpenTeamDeleteConfirmModal = () => {
    setOpenTeamDeleteConfirmModal(true);
  };
  const funCloseTeamDeleteConfirmModal = () => {
    setOpenTeamDeleteConfirmModal(false);
  };

  const funOpenRemoveTeamMemberConfirmModal = (userId: any) => {
    setTeamRemoveUser(userId);
    setOpenRemoveTeamMemberConfirmModal(true);
  };

  const funCloseRemoveTeamMemberConfirmModal = () => {
    setOpenRemoveTeamMemberConfirmModal(false);
  };

  const funRemoveTeamMember = () => {
    if (teamRemoveUser && team._id) {
      leaveTeamMember({
        team_id: team._id,
        leave_member_id: teamRemoveUser,
      }).then((data: any) => {
        funCloseRemoveTeamMemberConfirmModal();
        if (data.status) {
          getTeamList();
        }
      });
    }
  };

  const signOutFun = () => {
    const resp = signOut();
    if (resp === true) {
      window.location.reload();
    }
  };
  const [toggle, setToggle] = useState(true);

  const confirmTransferOwnership = () => {
    if (newOwner && team._id) {
      transferTeamOwnership({ team_id: team._id, new_owner: newOwner }).then(
        (data: any) => {
          setTransferOwnership(false);
          if (data.status) {
            getTeamList();
          }
        },
      );
    }
  };

  const onOrgProjectChange = (id: any) => {
    if (id) {
      getOrgProjectMembersList(id).then((data: any) => {
        const users = data.data.map((mp: any) => mp.user_name);
        setOrgProjectMembersList(users);
        const mergedUsers = [...users, ...eventDetail.email_invitations].filter(
          (item, i, ar) => {
            return ar.indexOf(item) === i;
          },
        );
        // @ts-ignore
        setCoHostUserList(mergedUsers);
        // console.log({ ...coHostUserList, ...users });
      });
    } else {
      setCoHostUserList(eventDetail.email_invitations);
    }
  };

  const handleChangeScheduleAccessRadio = (e: any) => {
    setEventDetail({
      ...eventDetail,
      schedule_meeting_access_type: e.target.value,
    });
  };

  const openRescheduleMeetingPopUp = (id: string) => {
    setOpenRescheduleModal(true);
    setRescheduleMeetingId(id);
  };

  return (
    <>
      <div className="threeLineMenuDash" onClick={() => setToggle(!toggle)}>
        <span className="lineMenu"></span>
        <span className="lineMenu"></span>
        <span className="lineMenu"></span>
      </div>
      {toggle && (
        <div className="sidebarwrapper dashboard-section-content float-left mobile">
          <div className="leftNavStart">
            <ul>
              {orgListArr?.map((orgObj: any, i: number) => {
                return (
                  <li
                    key={orgObj?.organisationId?._id}
                    //title={orgObj?.organisationId?.name}
                    className={
                      (
                        getOrganisationDetails() == null
                          ? i == 0
                          : getOrganisationDetails().organisationId?._id ==
                            orgObj?.organisationId?._id
                      )
                        ? "active tooltip-class"
                        : "tooltip-class"
                    }
                    onClick={(e) => {
                      changeOrganisation(orgObj);
                    }}
                  >
                    <span className="tooltiptext">
                      {orgObj?.organisationId?.name}
                    </span>
                    <img
                      referrerPolicy="no-referrer"
                      src={
                        orgObj?.organisationId?.isCustomLogo
                          ? orgObj?.organisationId?.logo
                          : `https://eu.ui-avatars.com/api/?name=${orgObj?.organisationId?.name}&size=200&background=random&rounded=false&color=fff`
                      }
                      alt={orgObj?.organisationId?.name}
                    />
                  </li>
                );
              })}

              {/*<li title="Send organisation invitation">*/}
              {/*  <a style={{ paddingRight: "0px" }} onClick={(e) => {*/}
              {/*    handleOpenSearchOrganization();*/}
              {/*  }}><i className="fa fa-search-plus"></i></a>*/}
              {/*</li>*/}
              <li title="Add organisation">
                <a
                  style={{ paddingRight: "0px" }}
                  onClick={(e) => {
                    handleCreateOrg();
                  }}
                >
                  <i className="fa fa-plus"></i>
                </a>
              </li>
            </ul>
          </div>

          {/* Create Team Modal */}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="custommodal"
            open={openCreateTeam}
            onClose={handleCloseCraeteTeam}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openCreateTeam}>
              <div className="createNewTeamOuter">
                <div className="innerScroll">
                  <div
                    className="closeBtnProfileTeam"
                    onClick={handleCloseCraeteTeam}
                  >
                    <i className="fa fa-close"></i>
                  </div>
                  <h5 id="transition-modal-title">Create new team</h5>
                  <div className="dialogBody-27a2n">
                    <div className="createTeamTextBlock">
                      <p>
                        Name your team before choosing a new plan subscription.
                      </p>
                    </div>
                    <div className="inputDataTeam">
                      <input
                        type="text"
                        placeholder="Enter team name"
                        value={addTeamName.name}
                        onChange={(e) => {
                          setaddTeamName({
                            ...addTeamName,
                            name: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="dialogFooter">
                      <button
                        className="rtb-btn rtb-btn--primary rtb-btn--medium"
                        onClick={(e) => {
                          addingTeam();
                        }}
                      >
                        Continue
                      </button>
                      <button
                        className="rtb-btn rtb-btn--secondary rtb-btn--medium"
                        onClick={handleCloseCraeteTeam}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </Modal>

          {/* Create Project Modal */}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="custommodal"
            open={openCreateProject}
            onClose={handleCloseProject}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openCreateProject}>
              <div className="createNewTeamOuter">
                <div className="innerScroll">
                  <div
                    className="closeBtnProfileTeam"
                    onClick={handleCloseProject}
                  >
                    <i className="fa fa-close"></i>
                  </div>
                  <h5 id="transition-modal-title">Create new Project</h5>
                  <div className="dialogBody-27a2n">
                    <div className="createTeamTextBlock">
                      <p>
                        Name your project before choosing a new plan
                        subscription.
                      </p>
                    </div>
                    <div className="inputDataTeam">
                      <input
                        type="text"
                        placeholder="Enter project name"
                        value={addProjectName.name}
                        onChange={(e) => {
                          setaddProjectName({
                            ...addProjectName,
                            name: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="dialogFooter">
                      <button
                        className="rtb-btn rtb-btn--primary rtb-btn--medium"
                        onClick={(e) => {
                          addingProject();
                        }}
                      >
                        Continue
                      </button>
                      <button
                        className="rtb-btn rtb-btn--secondary rtb-btn--medium"
                        onClick={handleCloseProject}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </Modal>

          {/* Leave Team Model */}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="custommodal"
            open={openAddMember}
            onClose={closeCraeteMemberModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openAddMember}>
              <div className="createNewTeamOuter">
                <div className="innerScroll">
                  <div
                    className="closeBtnProfileTeam"
                    onClick={closeCraeteMemberModal}
                  >
                    <i className="fa fa-close"></i>
                  </div>
                  <h5 id="transition-modal-title">Leave Team</h5>
                  <div className="dialogBody-27a2n">
                    <div className="createTeamTextBlock">
                      <p>
                        By leaving the team, you will lose access to all its
                        boards. So please select new team admin.
                      </p>
                    </div>
                    <div className="">
                      <div className="row">
                        <div className=" col-lg-2"></div>
                        <div className=" col-lg-10 search-box">
                          <input
                            type="text"
                            placeholder="Search by email"
                            className="input-search"
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                          />
                        </div>
                      </div>
                      <div>
                        <ul className="user-list-ui">
                          {userList
                            .filter(leaveTeamAdminFilterSearch)
                            .map((member: any) => {
                              return (
                                <li key={member.users._id} className="user-row">
                                  <div className="row">
                                    <label
                                      className="user-check-label"
                                      htmlFor={member.users._id}
                                    >
                                      <div className="col-sm-2">
                                        <div className="form-check form-check-inline">
                                          <input
                                            id={member.users._id}
                                            name={member.users.user_name}
                                            type="checkbox"
                                            className="form-check-input"
                                            onChange={handleClickInputSearch}
                                            checked={isCheck.includes(
                                              member.users?._id,
                                            )}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-sm-2">
                                        <img
                                          referrerPolicy="no-referrer"
                                          src={
                                            member.users.imageUrl
                                              ? member.users.imageUrl
                                              : `https://eu.ui-avatars.com/api/?name=${member.users.firstname} ${member.users.lastname}&size=200&background=random&rounded=false&color=fff`
                                          }
                                        ></img>
                                      </div>
                                      <div className="col-sm-8 user-detail">
                                        <div className="user-name">
                                          {`${member.users.firstname} ${member.users.lastname}`}
                                        </div>
                                        <div className="user-email">
                                          {member.users.user_name}
                                        </div>
                                      </div>
                                    </label>
                                  </div>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                    <div className="dialogFooter">
                      <button
                        className="rtb-btn rtb-btn--primary rtb-btn--medium"
                        onClick={(e) => {
                          handelChangeAdmin();
                        }}
                      >
                        Choose
                      </button>
                      <button
                        className="rtb-btn rtb-btn--secondary rtb-btn--medium"
                        onClick={closeCraeteMemberModal}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </Modal>

          {/* Search Organization Modal */}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="custommodal"
            open={openSearchOrg}
            onClose={handleCloseSearchOrganization}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openSearchOrg}>
              <div className="createNewTeamOuter search-org-main">
                <div className="innerScroll">
                  <div
                    className="closeBtnProfileTeam"
                    onClick={handleCloseSearchOrganization}
                  >
                    <i className="fa fa-close"></i>
                  </div>
                  <h5 id="transition-modal-title">Search organization</h5>
                  <div className="dialogBody-27a2n">
                    {/* <div className="createTeamTextBlock">
                    <p>Name your team before choosing a new plan subscription.</p>
                  </div> */}
                    <div className="inputDataTeam">
                      {/* <input type="text"
                      placeholder="Search organization name"
                      value={searchOrg.name}
                      onChange={(e) => {
                        setSearchOrg({
                          ...searchOrg,
                          name: e.target.value,
                        });
                      }}
                    /> */}
                      <AsyncSelect
                        onChange={(e: any) => setSearchOrg(e.value)}
                        cacheOptions
                        defaultOptions
                        loadOptions={getOrgData}
                      />
                    </div>
                    <div className="dialogFooter">
                      <button
                        className="rtb-btn rtb-btn--primary rtb-btn--medium"
                        onClick={(e) => {
                          addOrganizationSearch();
                        }}
                      >
                        Send Request
                      </button>
                      <button
                        className="rtb-btn rtb-btn--secondary rtb-btn--medium"
                        onClick={handleCloseSearchOrganization}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </Modal>

          {/* Search Organization Modal */}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="custommodal"
            open={openAddOrg}
            onClose={handleCloseCreateOrg}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openAddOrg}>
              <div className="createNewTeamOuter search-org-main">
                <div className="innerScroll">
                  {!noOrgMessage && (
                    <div
                      className="closeBtnProfileTeam"
                      onClick={handleCloseCreateOrg}
                    >
                      <i className="fa fa-close"></i>
                    </div>
                  )}
                  {noOrgMessage && (
                    <div className="alert alert-danger">
                      You currently do not have any active organisation. Please
                      add an organisation to continue
                    </div>
                  )}
                  <h5 id="transition-modal-title">Add organisation</h5>
                  <div className="dialogBody-27a2n">
                    <div className="inputDataTeam">
                      <input
                        type="text"
                        placeholder="Add organisation name"
                        value={orgName}
                        onChange={(e) => {
                          setOrgName(e.target.value);
                        }}
                      />
                    </div>
                    <div className="dialogFooter">
                      <button
                        className="rtb-btn rtb-btn--primary rtb-btn--medium"
                        onClick={(e) => {
                          addingOrganization();
                        }}
                      >
                        Create
                      </button>
                      {!noOrgMessage && (
                        <button
                          className="rtb-btn rtb-btn--secondary rtb-btn--medium"
                          onClick={handleCloseCreateOrg}
                        >
                          Cancel
                        </button>
                      )}
                      {noOrgMessage && (
                        <button
                          className="rtb-btn rtb-btn--secondary rtb-btn--medium"
                          onClick={signOutFun}
                        >
                          Logout
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </Modal>

          {/* Leave Org Admin Modal*/}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="custommodal"
            open={openOrgNewAdmin}
            onClose={closeNewOrgAdminModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openOrgNewAdmin}>
              <div className="createNewTeamOuter">
                <div className="innerScroll">
                  <div
                    className="closeBtnProfileTeam"
                    onClick={closeNewOrgAdminModal}
                  >
                    <i className="fa fa-close"></i>
                  </div>
                  <h5 id="transition-modal-title">Leave Organisation</h5>
                  <div className="dialogBody-27a2n">
                    {props?.organisationUserList?.length > 1 ? (
                      <>
                        <div className="createTeamTextBlock">
                          <p>
                            By leaving the organisation, you will lose access to
                            all access this organisation.
                          </p>
                        </div>
                        <div className="row">
                          <div className=" col-lg-2"></div>
                          <div className=" col-lg-10 search-box">
                            <input
                              type="text"
                              placeholder="Search by email"
                              className="input-search"
                              value={newOrgAdminSearchValue}
                              onChange={(e) =>
                                setNewOrgAdminSearchValue(e.target.value)
                              }
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="createTeamTextBlock alert alert-danger">
                          <p>
                            Please add any member first in this{" "}
                            <b>{organisation?.organisationId?.name}</b>{" "}
                            organisation. Then you can assign admin access to
                            that user and can leave.
                          </p>
                        </div>
                      </>
                    )}
                    <div>
                      <ul className="user-list-ui">
                        {props.organisationUserList
                          .filter(leaveOrgAdminFilterSearch)
                          .map((member: any) => {
                            return (
                              <li key={member._id} className="user-row">
                                <div className="row">
                                  <label
                                    className="user-check-label"
                                    htmlFor={member._id}
                                  >
                                    <div className="col-sm-2">
                                      <div className="form-check form-check-inline">
                                        <input
                                          id={member._id}
                                          name={member.user_name}
                                          type="checkbox"
                                          className="form-check-input"
                                          onChange={newOrgAdminClickInputSearch}
                                          checked={newOrgAdminIsCheck.includes(
                                            member._id,
                                          )}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-sm-2">
                                      <img
                                        referrerPolicy="no-referrer"
                                        src={
                                          member.imageUrl
                                            ? member.imageUrl
                                            : `https://eu.ui-avatars.com/api/?name=${member.firstname} ${member.lastname}&size=200&background=random&rounded=false&color=fff`
                                        }
                                      ></img>
                                    </div>
                                    <div className="col-sm-8 user-detail">
                                      <div className="user-name">
                                        {`${member.firstname} ${member.lastname}`}
                                      </div>
                                      <div className="user-email">
                                        {member.user_name}
                                      </div>
                                    </div>
                                  </label>
                                </div>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                    <div className="dialogFooter">
                      {submitButtonDisplay ? (
                        <button
                          className="rtb-btn rtb-btn--primary rtb-btn--medium"
                          onClick={handelOpenConfirmBox}
                        >
                          Make Admin
                        </button>
                      ) : props?.organisationUserList?.length > 1 ? (
                        <button className="btn btn-lg btn-primary" disabled>
                          Make Admin
                        </button>
                      ) : (
                        ""
                      )}
                      <button
                        className="rtb-btn rtb-btn--secondary rtb-btn--medium"
                        onClick={closeNewOrgAdminModal}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </Modal>

          {/* Confirm Modal Org Admin*/}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="custommodal"
            open={openConfirmModal}
            onClose={funCloseConfirmModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openConfirmModal}>
              <div className="createNewTeamOuter">
                <div className="innerScroll">
                  <div
                    className="closeBtnProfileTeam"
                    onClick={funCloseConfirmModal}
                  >
                    <i className="fa fa-close"></i>
                  </div>
                  <h5 id="transition-modal-title">Confirm box</h5>
                  <div className="dialogBody-27a2n">
                    <div className="createTeamTextBlock">
                      <p>Are you sure you want leave this organisation.</p>
                    </div>
                    <div className="dialogFooter">
                      <button
                        className="rtb-btn rtb-btn--primary rtb-btn--medium"
                        onClick={handelLeaveOrgAdminApi}
                      >
                        Confirm
                      </button>
                      <button
                        className="rtb-btn rtb-btn--secondary rtb-btn--medium"
                        onClick={funCloseConfirmModal}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </Modal>

          {/* Confirm Modal Org Member*/}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="custommodal"
            open={openConfirmMemberModal}
            onClose={funCloseConfirmMemberModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openConfirmMemberModal}>
              <div className="createNewTeamOuter">
                <div className="innerScroll">
                  <div
                    className="closeBtnProfileTeam"
                    onClick={funCloseConfirmMemberModal}
                  >
                    <i className="fa fa-close"></i>
                  </div>
                  <h5 id="transition-modal-title">Confirm box</h5>
                  <div className="dialogBody-27a2n">
                    <div className="createTeamTextBlock">
                      <p>Are you sure you want leave this organisation.</p>
                    </div>
                    <div className="dialogFooter">
                      <button
                        className="rtb-btn rtb-btn--primary rtb-btn--medium"
                        onClick={handelLeaveOrgMember}
                      >
                        Confirm
                      </button>
                      <button
                        className="rtb-btn rtb-btn--secondary rtb-btn--medium"
                        onClick={funCloseConfirmMemberModal}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </Modal>

          {/* Confirm Modal Delete Team*/}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="custommodal"
            open={openTeamDeleteConfirmModal}
            onClose={funCloseTeamDeleteConfirmModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openTeamDeleteConfirmModal}>
              <div className="createNewTeamOuter">
                <div className="innerScroll">
                  <div
                    className="closeBtnProfileTeam"
                    onClick={funCloseTeamDeleteConfirmModal}
                  >
                    <i className="fa fa-close"></i>
                  </div>
                  <h5 id="transition-modal-title">Confirm box</h5>
                  <div className="dialogBody-27a2n">
                    <div className="createTeamTextBlock">
                      <p>Are you sure you want leave this team.</p>
                    </div>
                    <div className="dialogFooter">
                      <button
                        className="rtb-btn rtb-btn--primary rtb-btn--medium"
                        onClick={deleteTeams}
                      >
                        Confirm
                      </button>
                      <button
                        className="rtb-btn rtb-btn--secondary rtb-btn--medium"
                        onClick={funCloseTeamDeleteConfirmModal}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </Modal>

          {/* Confirm Modal Remove Team Member*/}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="custommodal"
            open={openRemoveTeamMemberConfirmModal}
            onClose={funCloseRemoveTeamMemberConfirmModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openRemoveTeamMemberConfirmModal}>
              <div className="createNewTeamOuter">
                <div className="innerScroll">
                  <div
                    className="closeBtnProfileTeam"
                    onClick={funCloseRemoveTeamMemberConfirmModal}
                  >
                    <i className="fa fa-close"></i>
                  </div>
                  <h5 id="transition-modal-title">Confirm box</h5>
                  <div className="dialogBody-27a2n">
                    <div className="createTeamTextBlock">
                      <p>Are you sure you want remove user in this team.</p>
                    </div>
                    <div className="dialogFooter">
                      <button
                        className="rtb-btn rtb-btn--primary rtb-btn--medium"
                        onClick={funRemoveTeamMember}
                      >
                        Confirm
                      </button>
                      <button
                        className="rtb-btn rtb-btn--secondary rtb-btn--medium"
                        onClick={funCloseRemoveTeamMemberConfirmModal}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </Modal>

          {/* Team Details Model */}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="custommodal teamDetailsModal"
            open={openTeamModal}
            onClose={closeTeamDetailsModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openTeamModal}>
              <div className="createNewTeamOuter">
                <div className="innerScroll">
                  <div
                    className="closeBtnProfileTeam"
                    onClick={closeTeamDetailsModal}
                  >
                    <i className="fa fa-close"></i>
                  </div>
                  <h5 id="transition-modal-title">Team Details</h5>
                  <div className="dialogBody-27a2n">
                    <div className="">
                      {/* <div className="row">
                      <div className=" col-lg-2"></div>
                      <div className=" col-lg-10 search-box">
                        <input type="text"
                          placeholder="Search by email"
                          className="input-search"
                          value={searchValue}
                          onChange={(e) => setSearchValue(e.target.value)}
                        />
                      </div>
                    </div> */}
                      <div>
                        {/* {isAdmin && */}
                        {/*{(team?.createdBy == currentLoginUser?._id || isAdmin == true) &&*/}
                        {team?.createdBy == currentLoginUser?._id && (
                          <div className="row newInfoLine">
                            <div className="col-sm-1 add-member-plus">
                              <i className="fa fa-plus"></i>
                            </div>
                            <div
                              className="col-sm-5 add-new-participant"
                              onClick={(e) => openInviteTeamMemberModal()}
                            >
                              <a>
                                {/* <i className="fa fa-user-plus" aria-hidden="true"></i> */}
                                <strong className="add-new-member">
                                  Add new Participant
                                </strong>
                              </a>
                            </div>
                            <div
                              className="col-sm-5 add-new-participant text-end"
                              onClick={(e) => setTransferOwnership(true)}
                            >
                              <a>
                                <strong className="add-new-member">
                                  Transfer ownership
                                </strong>
                              </a>
                            </div>
                          </div>
                        )}

                        <ul className="user-list-ui">
                          {userList
                            .filter(leaveTeamAdminFilterSearch)
                            .map((member: any) => {
                              return (
                                <li key={member.users._id} className="user-row">
                                  <div className="row">
                                    {/* <div className="col-sm-2">
                                  <div className="form-check form-check-inline">
                                    <input
                                      id={member.users._id}
                                      name={member.users.user_name}
                                      type="checkbox"
                                      className="form-check-input"
                                      onChange={handleClickInputSearch}
                                      checked={isCheck.includes(member.users?._id)}
                                    />
                                  </div>
                                </div> */}
                                    <div className="col-sm-1 imgIconInfo">
                                      <img
                                        referrerPolicy="no-referrer"
                                        src={
                                          member.users.imageUrl
                                            ? member.users.imageUrl
                                            : `https://eu.ui-avatars.com/api/?name=${member.users.firstname} ${member.users.lastname}&size=200&background=random&rounded=true&color=fff`
                                        }
                                      ></img>
                                    </div>
                                    <div className="col-sm-8 user-detail">
                                      <div className="user-name">
                                        {`${member.users.firstname} ${member.users.lastname}`}
                                      </div>
                                      <div className="user-email">
                                        {member.users.user_name}
                                      </div>
                                    </div>
                                    <div className="col-sm-3">
                                      {/*(team?.createdBy == currentLoginUser?._id || isAdmin) ?*/}
                                      {team?.createdBy ==
                                      currentLoginUser?._id ? ( //(team.createdBy !== member.users._id )
                                        team.createdBy !== member.users._id ? (
                                          <i
                                            className="fa fa-trash-o teamMemberDelete"
                                            aria-hidden="true"
                                            onClick={(e) =>
                                              funOpenRemoveTeamMemberConfirmModal(
                                                member.users._id,
                                              )
                                            }
                                            title={`Remove ${member.users.firstname} ${member.users.lastname}`}
                                          ></i>
                                        ) : (
                                          ""
                                        )
                                      ) : (
                                        ""
                                      )}
                                      {/* <div className="dropdown">
                                  <i className="fa fa-ellipsis-h team-option-menu" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false"></i>
                                  <ul className="dropdown-menu dropdown-menu-lg-end" aria-labelledby="dropdownMenuButton1">
                                    <li>
                                      <a className="dropdown-item">
                                        <span className="selected-sidebar-team-name" onClick={(e) => funOpenRemoveTeamMemberConfirmModal(member.users._id)}>Remove</span>
                                      </a>
                                    </li>
                                  </ul>
                                </div> */}
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>

                    <div className="dialogFooter">
                      {/*(team?.createdBy == currentLoginUser?._id || isAdmin) ?*/}
                      {team?.createdBy == currentLoginUser?._id ? (
                        <button
                          className="rtb-btn btn-danger"
                          onClick={(e) => {
                            funOpenTeamDeleteConfirmModal();
                          }}
                        >
                          Delete Team
                        </button>
                      ) : (
                        <button
                          className="rtb-btn btn-secondary"
                          onClick={(e) => {
                            handelLeaveTeamMember();
                          }}
                        >
                          Leave Team
                        </button>
                      )}
                      {team?.createdBy == currentLoginUser?._id && (
                        <button
                          className="rtb-btn btn-secondary team-board-create-btn"
                          onClick={(e) => {
                            setOpenCreateTeamBoardModal(true);
                            closeTeamDetailsModal();
                          }}
                        >
                          Create Board
                        </button>
                      )}
                      {team?.createdBy == currentLoginUser?._id && (
                        <button
                          className="rtb-btn btn-secondary team-board-create-btn"
                          onClick={(e) => {
                            setOpenCreateTeamProjectModal(true);
                            closeTeamDetailsModal();
                          }}
                        >
                          Create Project
                        </button>
                      )}
                      {/* <button className="rtb-btn btn-primary" onClick={(e) => { }}>Leave team</button>
                    <button className="rtb-btn rtb-btn--secondary rtb-btn--medium" onClick={closeTeamDetailsModal}>Cancel</button> */}
                      {/* <button className="rtb-btn rtb-btn--primary rtb-btn--medium" onClick={(e) => { }}>Choose</button> */}
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </Modal>

          {/*Create Board From Team*/}
          {openCreateTeamBoardModal ? (
            <CreateTeamBoard
              openCreateTeamBoardModal={openCreateTeamBoardModal}
              setOpenCreateTeamBoardModal={setOpenCreateTeamBoardModal}
            />
          ) : null}

          {/*Create project from team*/}
          {openCreateTeamProjectModal ? (
            <CreateTeamProject
              openCreateTeamProjectModal={openCreateTeamProjectModal}
              setOpenCreateTeamProjectModal={setOpenCreateTeamProjectModal}
              getProjectList={getProjectList}
            />
          ) : null}

          {/*Team transfer ownership modal*/}

          <Modal
            open={transferOwnership}
            onClose={() => setTransferOwnership(false)}
            className="custommodal download-recording-modal"
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <Fade in={transferOwnership}>
              <div className="createNewTeamOuter search-org-main">
                <div className="innerScroll">
                  <div
                    className="closeBtnProfileTeam"
                    onClick={() => setTransferOwnership(false)}
                  >
                    <i className="fa fa-close"></i>
                  </div>
                  <div className="alert alert-danger">
                    Please select member to transfer the ownership.
                    <br />
                    By transferring ownership new user will have a full-control
                    on the team.
                  </div>

                  <div className="dialogBody-27a2n">
                    <div className="inputDataTeam">
                      <select
                        className="member-select"
                        onChange={(e) => setNewOwner(e.target.value)}
                      >
                        <option value="">Select member</option>
                        {userList
                          .filter((fl: any) => fl.users._id != team?.createdBy)
                          .map((member: any, key: any) => {
                            return (
                              <option value={member.users._id} key={key}>
                                {member.users.displayName}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div className="dialogFooter">
                      <button
                        disabled={!newOwner}
                        className="btn rtb-btn rtb-btn--primary rtb-btn--medium"
                        onClick={confirmTransferOwnership}
                      >
                        Confirm
                      </button>
                      <button
                        className="rtb-btn rtb-btn--secondary rtb-btn--medium"
                        onClick={() => setTransferOwnership(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </Modal>

          {/* Team Invitation Member Modal */}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="custommodal"
            open={openAddTeamMember}
            onClose={CloseInviteTeamMemberModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openAddTeamMember}>
              <div className="createNewTeamOuter">
                <div className="innerScroll">
                  <div
                    className="closeBtnProfileTeam"
                    onClick={CloseInviteTeamMemberModal}
                  >
                    <i className="fa fa-close"></i>
                  </div>
                  <h5 id="transition-modal-title">Invite Team Members</h5>
                  <div className="dialogBody-27a2n">
                    <div className="createTeamTextBlock">
                      {/* <p>You can select individual or multiple users</p> */}
                      <div className="row">
                        {/* <div className="col-sm-7">
                        <span>Members will get access to all boards in this project.</span>
                      </div> */}
                        {/* <div className="col-sm-5">
                        <select name="cars"
                          id="permission"
                          onChange={(e) => {
                            handlePermisionDropdown(e)
                          }}
                        >
                          <option value={BOARD_ACCESS.CAN_EDIT}>Can Edit</option>
                          <option value={BOARD_ACCESS.CAN_VIEW}>Can View</option>
                          <option value={BOARD_ACCESS.CAN_COMMENT}>Can Comment</option>
                        </select>
                      </div> */}
                      </div>
                    </div>
                    <div className="">
                      <div className="row">
                        <div className=" col-lg-4">
                          <input
                            type="checkbox"
                            name="selectAll"
                            className="form-check-input"
                            id="selectAll"
                            onChange={SelectAllTeamMembers}
                            checked={isCheckAllTeamMember}
                          ></input>
                          <label className="select-all" htmlFor="selectAll">
                            All
                          </label>
                        </div>
                        <div className=" col-lg-8 search-box">
                          <input
                            type="text"
                            placeholder="Search by name or email organisation users"
                            className="input-search"
                            value={addteamSearchValue}
                            onChange={(e) =>
                              setAddteamSearchValue(e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <div>
                        <ul className="user-list-ui">
                          {props?.organisationUserList
                            ?.filter(addTeamMemberFilter)
                            .map((member: any) => {
                              return (
                                <li key={member._id} className="user-row">
                                  <div className="row">
                                    <label
                                      className="user-check-label"
                                      htmlFor={member._id}
                                    >
                                      <div className="col-sm-2">
                                        <div className="form-check form-check-inline">
                                          <input
                                            id={member._id}
                                            name={member.user_name}
                                            type="checkbox"
                                            className="form-check-input"
                                            onChange={selectTeamMemberCheckBox}
                                            checked={isCheckTeamMember.includes(
                                              member?._id,
                                            )}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-sm-2">
                                        <img
                                          referrerPolicy="no-referrer"
                                          src={
                                            member.imageUrl
                                              ? member.imageUrl
                                              : `https://eu.ui-avatars.com/api/?name=${member.firstname} ${member.lastname}&size=200&background=random&rounded=false&color=fff`
                                          }
                                        ></img>
                                      </div>
                                      <div className="col-sm-8 user-detail">
                                        <div className="user-name">
                                          {`${member.firstname} ${member.lastname}`}
                                        </div>
                                        <div className="user-email">
                                          {member.user_name}
                                        </div>
                                      </div>
                                    </label>
                                  </div>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                    <div className="dialogFooter">
                      {addTeamButtonDisplay ? (
                        <button
                          className="rtb-btn rtb-btn--primary rtb-btn--medium"
                          onClick={(e) => {
                            addingTeamMember();
                          }}
                        >
                          Add Team Member
                        </button>
                      ) : (
                        <button className="rtb-btn btn btn-primary" disabled>
                          Add Team Member
                        </button>
                      )}
                      <button
                        className="rtb-btn rtb-btn--secondary rtb-btn--medium"
                        onClick={CloseInviteTeamMemberModal}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          </Modal>

          {/* Schedule Meeting Modal */}

          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="custommodal"
            open={open}
            onClose={handleCloseScheduleMeetingModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <div className="cmp-schedule-meeting w-45">
                <div
                  className="closeBtnProfile"
                  onClick={handleCloseScheduleMeetingModal}
                >
                  <i className="fa fa-close"></i>
                </div>
                <p id="transition-modal-title" className="headinglink mb-4">
                  Schedule Meeting {/* Please fill the meeting details  */}
                </p>

                <div className="row d-flex justify-content-center">
                  <div className="row w-100 d-flex justify-content-center">
                    <div className="mb-4 w-100">
                      <input
                        placeholder="Enter Meeting Name"
                        value={eventDetail.eventName}
                        onChange={(e) => {
                          setEventDetail({
                            ...eventDetail,
                            eventName: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row w-100 d-flex justify-content-center">
                    <div className="col-md-7">
                      <div className="mb-4 w-100">
                        <input
                          style={{ height: "38px" }}
                          type="datetime-local"
                          placeholder="Start Date"
                          value={eventDetail.eventDate}
                          onChange={(e) => {
                            setEventDetail({
                              ...eventDetail,
                              eventDate: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-5 member-access">
                      <select
                        name="timezone"
                        id="timezone"
                        defaultValue={eventDetail.timezone}
                        className="w-100 org-project-dropdown"
                        onChange={(e) => {
                          setEventDetail({
                            ...eventDetail,
                            timezone: e.target.value,
                          });
                        }}
                      >
                        <option value="">Select timezone</option>
                        {timeZoneList.map((z: any, k: any) => {
                          return (
                            <option value={z} key={k}>
                              {z}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  <div className="row w-100 d-flex justify-content-center">
                    <div className="todata row col-md-9">
                      <div className="row w-100 d-flex justify-content-center">
                        <div className="col-md-1 to-span-main">
                          <span className="to-span">To</span>
                        </div>
                        <div className="col-md-11">
                          <TagsInput
                            addOnBlur={true}
                            //value={selectedInvitationEmail}
                            value={eventDetail.email_invitations}
                            inputProps={{
                              className: "input-tag",
                              placeholder: "Enter email to invite members",
                            }}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 member-access">
                      <select
                        name="access"
                        id="access"
                        className="w-100 select-access"
                        onChange={(e) => {
                          setEventDetail({
                            ...eventDetail,
                            participant_access: e.target.value,
                          });
                        }}
                      >
                        <option key="select_participant_edit" value="can-edit">
                          Can Edit
                        </option>
                        <option key="select_participant_view" value="can-view">
                          Can View
                        </option>
                        <option
                          key="select_participant_comment"
                          value="can-comment"
                        >
                          Can Comment
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="row w-100 d-flex justify-content-center">
                    <div className="col-md-9">
                      <div className="mb-4 w-100">
                        <select
                          name="org_project_id"
                          id="org_project_id"
                          className="w-100 org-project-dropdown"
                          onChange={(e) => {
                            setEventDetail({
                              ...eventDetail,
                              org_project_id: e.target.value,
                            });
                            onOrgProjectChange(e.target.value);
                          }}
                        >
                          <option key="select_org_project" value="">
                            Select organisation or project
                          </option>
                          {orgListArr?.map((orgObj: any, index: number) => {
                            return (
                              <option
                                key={index}
                                value={orgObj?.organisationId?._id}
                                selected={
                                  organisation?.organisationId?._id ==
                                  orgObj?.organisationId?._id
                                }
                              >
                                {orgObj?.organisationId?.name}
                              </option>
                            );
                          })}
                          {projectList?.map(
                            (projectObj: any, index: number) => {
                              return (
                                <option
                                  key={index}
                                  value={projectObj._id}
                                  selected={project?._id == projectObj?._id}
                                >
                                  {projectObj.name}
                                </option>
                              );
                            },
                          )}
                        </select>
                      </div>
                    </div>
                    <div className="row col-md-3 member-access">
                      <div className="mb-4 w-100">
                        <select
                          name="access"
                          id="access"
                          className="w-100 org-project-dropdown"
                          onChange={(e) => {
                            setEventDetail({
                              ...eventDetail,
                              org_project_access: e.target.value,
                            });
                          }}
                        >
                          <option
                            key="select_org_project_access_edit"
                            value="can-edit"
                          >
                            Can Edit
                          </option>
                          <option
                            key="select_org_project_access_view"
                            value="can-view"
                          >
                            Can View
                          </option>
                          <option
                            key="select_org_project_access_comment"
                            value="can-comment"
                          >
                            Can Comment
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="row w-100 d-flex justify-content-center">
                    <div className="mb-2 w-100">
                      <textarea
                        cols={40}
                        placeholder="Describe your meeting here"
                        value={eventDetail.eventDescription}
                        onChange={(e) => {
                          setEventDetail({
                            ...eventDetail,
                            eventDescription: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="row w-100 d-flex justify-content-center">
                    <div className="col-md-12 mb-4">
                      <select
                        name="co-host"
                        id="co-host"
                        defaultValue={eventDetail.timezone}
                        className="w-100 org-project-dropdown"
                        onChange={(e) => {
                          setEventDetail({
                            ...eventDetail,
                            co_host: e.target.value,
                          });
                        }}
                      >
                        <option value="">Select co-host user</option>
                        {coHostUserList?.map((ch: any, k: any) => {
                          return (
                            <option value={ch} key={k}>
                              {ch}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="row w-100 d-flex justify-content-center reminder-notification-main">
                    <label>Reminder Notification</label>
                    <div className="col-md-6 duration-value">
                      <input
                        type="number"
                        step={1}
                        min={1}
                        value={eventDetail.notification_duration_value}
                        onChange={(e) => {
                          setEventDetail({
                            ...eventDetail,
                            notification_duration_value: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="col-md-6 duration-type">
                      <select
                        name="notification-duration"
                        id="notification-duration"
                        className="w-100 org-project-dropdown"
                        value={eventDetail.notification_duration_type}
                        onChange={(e) => {
                          setEventDetail({
                            ...eventDetail,
                            notification_duration_type: e.target.value,
                          });
                        }}
                      >
                        <option
                          key="notification-duration-minute"
                          value="minute"
                        >
                          Minutes
                        </option>
                        <option key="notification-duration-hour" value="hour">
                          Hours
                        </option>
                        <option key="notification-duration-day" value="day">
                          Days
                        </option>
                        <option key="notification-duration-week" value="week">
                          Weeks
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="row w-100 d-flex justify-content-center">
                    <div className="col-md-6">
                      <input
                        style={{ width: "auto" }}
                        type="radio"
                        id="personal"
                        className="access-type"
                        checked={eventDetail.schedule_meeting_access_type == 1}
                        name="access_type"
                        onChange={handleChangeScheduleAccessRadio}
                        value="1"
                      />
                      &nbsp;&nbsp;
                      <label htmlFor="personal">
                        Anyone with the link can join
                      </label>
                    </div>
                    <div className="col-md-6">
                      <input
                        style={{ width: "auto" }}
                        type="radio"
                        id="personal"
                        className="access-type"
                        checked={eventDetail.schedule_meeting_access_type == 2}
                        name="access_type"
                        onChange={handleChangeScheduleAccessRadio}
                        value="2"
                      />
                      &nbsp;&nbsp;
                      <label htmlFor="personal">
                        Only invited members can join
                      </label>
                    </div>
                  </div>
                </div>
                {requestingServer === false ? (
                  <button
                    className=" btn bluefill custbtn custombtn"
                    onClick={(e) => {
                      startMeet();
                    }}
                  >
                    Schedule meeting{" "}
                    <Link
                      to={ROUTES.SHAREBOARD}
                      className="bluefill noborder text-center"
                    ></Link>
                  </button>
                ) : (
                  <button className=" btn  bluefill custbtn custombtn">
                    Please wait...
                  </button>
                )}
              </div>
            </Fade>
          </Modal>

          {openRescheduleModal && (
            <RescheduleMeeting
              openRescheduleModal={openRescheduleModal}
              setOpenRescheduleModal={setOpenRescheduleModal}
              rescheduleMeetingId={rescheduleMeetingId}
            />
          )}

          <aside className="rightDataAside">
            <div className="menu-item">
              <Link to={USER_BOARDS.RECENT_BOARD}>
                <i className="fa fa-clock-o"></i> Recent boards
              </Link>
            </div>
            <div className="menu-item">
              <Link to={USER_BOARDS.STARRED_BOARD}>
                <i className="fa fa-star-o"></i>Starred boards
              </Link>
            </div>
            <div className="menu-item">
              <Link to={ROUTES.INVITATION_BOARDS}>
                <i className="fa fa-bars"></i>Invitation boards
              </Link>
            </div>
            <div className="menu-item">
              <Link to={ROUTES.TRASH_BOARDS}>
                <i className="fa fa-trash"></i>Trash
              </Link>
            </div>
            <hr></hr>
            {/* Organisation Dropdown */}
            {organisation && organisation?._id && (
              <>
                <div className="selected-team">
                  <div className="dropdown">
                    <button
                      type="button"
                      className="btn btn-secondary dropdown-toggle selected-teams"
                      data-bs-toggle="dropdown"
                      data-bs-display="static"
                      aria-expanded="false"
                    >
                      <div className="team-info-main">
                        <div className="team-info">
                          {organisation?.organisationId?.name}
                        </div>
                        <div className="team-info">
                          ({props?.organisationUserList.length} Users)
                        </div>
                      </div>
                    </button>
                    <ul
                      className="dropdown-menu dropdown-menu-lg-end"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <Link
                          to={ROUTES.ORGANISATION_PROFILE}
                          target=""
                          className="dropdown-item"
                        >
                          Organisation Profile
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={ROUTES.ORGANISATION_USERS}
                          target=""
                          className="dropdown-item"
                        >
                          Organisation Users
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={ROUTES.ORGANISATION_INVITATION}
                          target=""
                          className="dropdown-item"
                        >
                          Organisation Invitation
                        </Link>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() =>
                            isAdmin
                              ? handelLeaveOrgAdmin()
                              : funOpenConfirmMemberModal()
                          }
                        >
                          {isAdmin
                            ? "Leave this organisation"
                            : "Leave this organisation"}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <hr></hr>
              </>
            )}
            {/* <div className="menu-item"><Link to={ROUTES.DASHBOARD}>Boards in this Organisation</Link></div> */}
            <div className="menu-item" onClick={BoardInThisOrganisation}>
              Boards in this organization
            </div>
            <hr></hr>

            {/* Team Section Sidebar */}
            <div className="menu-item">
              <strong>Teams</strong>
              <span className="rightIcon">
                <i
                  className="fa fa-plus"
                  onClick={(e) => {
                    handleOpenCraeteTeam();
                  }}
                ></i>
              </span>
            </div>

            {/* {(team && team?._id) &&
            <>
              <hr></hr>
              <div className="selected-team">
                <div className="dropdown">
                  <button type="button" className="btn btn-secondary dropdown-toggle selected-teams" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">
                    <div className="team-info-main">
                      <div className="team-info">{team?.name}</div>
                      <div className="team-info">({team?.members?.length} Users)</div>
                    </div>
                  </button>
                  <ul className="dropdown-menu dropdown-menu-lg-end" aria-labelledby="dropdownMenuButton1">
                    <li><Link to={ROUTES.TEAM_PROFILE} target="" className="dropdown-item">Team profile</Link></li>
                    <li><Link to={ROUTES.TEAM_USERS} target="" className="dropdown-item">Users</Link></li>
                    <li><a className="dropdown-item" onClick={() => (isAdmin) ? handelLeaveTeamAdmin() : handelLeaveTeamMember()}>{isAdmin ? "Leave this team" : "Leave this team"}</a></li>
                  </ul>
                </div>
              </div>
              <hr></hr>
            </>
          } */}

            {teamListArr?.map((teamObj: any, i: number) => {
              return (
                <div
                  key={teamObj._id}
                  title={teamObj.name}
                  className={
                    (team.length == 0 ? i == 0 : team._id == teamObj._id)
                      ? "active-team menu-item"
                      : "menu-item"
                  }
                  onClick={(e) => {
                    changeTeam(teamObj);
                  }}
                >
                  {teamObj.name}
                </div>
              );
            })}
            <hr></hr>

            {/* Project Section Sidebar */}
            <div className="menu-item">
              <strong>Projects</strong>
              {
                //(team && team._id) &&
                <span className="rightIcon">
                  <i
                    className="fa fa-plus"
                    onClick={(e) => {
                      handleOpenCraeteProject();
                    }}
                  ></i>
                </span>
              }
            </div>
            {projectList?.map((projectObj: any, i) => {
              return (
                <div
                  key={projectObj._id}
                  title={projectObj.name}
                  className={
                    (
                      project.length == 0
                        ? i == 0
                        : project._id == projectObj._id
                    )
                      ? "active-project menu-item"
                      : "menu-item"
                  }
                  onClick={(e) => {
                    handleClickProject(projectObj);
                  }}
                >
                  {projectObj.name}
                </div>
              );
            })}
            <hr></hr>
            <strong className="menu-item">Scheduled</strong>
            <div className="w-100">
              {/*<Calendar*/}
              {/*  onChange={setCalenderData}*/}
              {/*  onClickDay={(currentDate: any, event: any) => {*/}
              {/*    setCurrentDate(currentDate);*/}
              {/*    changeScheduleMeetingDate(currentDate);*/}
              {/*  }}*/}
              {/*  value={calenderData}*/}
              {/*/>*/}
              <FullCalendar
                aspectRatio={1}
                moreLinkText={"event"}
                selectable={true}
                dayHeaders={true}
                dayMaxEvents={0}
                datesSet={handleDatesSet}
                plugins={[dayGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                weekends={true}
                events={calendarEvents}
                dateClick={onDateClick}
                eventContent={renderEventContent}
              />
            </div>
            <div className="borerline">
              <div className="col-7 pull-left">
                <img
                  className="meeting-icon"
                  src={`${window.location.origin}/meeting_icon.png`}
                  alt=""
                />{" "}
                <h5 style={{ fontWeight: 600 }}>Meetings</h5>
              </div>
              <div className="col-4 pull-right text-end">
                <button
                  type="button"
                  className=" btn btn-primary schedulebtn"
                  onClick={(e) => {
                    handleOpenScheduleMeetingModal();
                  }}
                >
                  Schedule
                </button>
              </div>
            </div>
            <div className="menu-item ">
              <strong>
                {new Date(calenderData).getUTCDate() >= new Date().getUTCDate()
                  ? "Upcoming Meetings"
                  : "Past Meetings"}
              </strong>
            </div>
            {scheduleMeetingList
              ?.slice(0, scheduleMeetingCount)
              .map((meeting: any, index: number) => {
                return (
                  <div
                    key={meeting._id}
                    className={"menu-item borerlinebottom"}
                  >
                    <div className="col-8 pull-left">
                      <span>{meeting.eventName}</span>
                      <br></br>
                      <span>
                        {moment(meeting.eventDate).format(
                          "MMMM Do YYYY, hh:mm A",
                        )}
                        {/*{UtilityService.formateDate(*/}
                        {/*  new Date(meeting.eventDate).toISOString(),*/}
                        {/*)}*/}
                      </span>
                    </div>
                    <div className="col-4 pull-right text-end">
                      {meeting.eventDate >= new Date() &&
                      (meeting.createdBy == getUserDetails()._id ||
                        meeting.co_host === getUserDetails().user_name) ? (
                        <a
                          id="reschedule_meeting"
                          title="Reschedule/Update Meeting"
                          className="reschedule_meeting_btn"
                          onClick={() =>
                            openRescheduleMeetingPopUp(meeting._id)
                          }
                        >
                          {editIcon}
                        </a>
                      ) : null}
                      <button
                        type="button"
                        className={
                          meeting.eventDate >= new Date()
                            ? "schedulemeetingbtn"
                            : "pastschedulemeetingbtn"
                        }
                        onClick={(e) => {
                          const boardLink = `${process.env.REACT_APP_DOMAIN}/board#room=${meeting?.meetingId}`;
                          window.open(boardLink, "_blank");
                        }}
                      >
                        Link
                      </button>
                    </div>
                  </div>
                );
              })}
            <div>
              <div className="col-8 pull-left"></div>
              <div className="col-4 pull-right text-end">
                {isDisplayViewAll && (
                  <a
                    className="view-all-schedule-meeting"
                    onClick={viewAllScheduleMeeting}
                  >
                    View All
                  </a>
                )}
              </div>
            </div>

            {/* {
            <div>
              <FriendsList />
            </div>
          } */}
          </aside>
        </div>
      )}
    </>
  );
};
