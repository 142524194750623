import {
  ExcalidrawElement, ExcalidrawEmbeddableElement,
  NonDeletedExcalidrawElement,
} from "../element/types";

import { getElementAbsoluteCoords } from "../element";
import { isEmbeddableElement, isTextBindableContainer } from "../element/typeChecks";

export const hasBackground = (type: string) =>
  type === "rectangle" ||
  type === "semicircle" ||
  type === "ellipse" ||
  type === "semicircle" ||
  type === "star" ||
  type === "diamond" ||
  type === "freedraw" ||
  type === "line" ||
  type === "stickyNote";

export const hasStroke = (type: string) =>
  type === "rectangle" ||
  type === "ellipse" ||
  type === "semicircle" ||
  type === "star" ||
  type === "diamond" ||
  type === "arrow" ||
  type === "freedraw" ||
  type === "highlighterPen" ||
  type === "line";

export const canChangeSharpness = (type: string) =>
  type === "rectangle" || type === "arrow" || type === "line";

export const hasText = (type: string) => type === "text";

export const canHaveArrowheads = (type: string) => type === "arrow";

export const getElementAtPosition = (
  elements: readonly NonDeletedExcalidrawElement[],
  isAtPositionFn: (element: NonDeletedExcalidrawElement) => boolean,
) => {
  let hitElement = null;
  // We need to to hit testing from front (end of the array) to back (beginning of the array)
  // because array is ordered from lower z-index to highest and we want element z-index
  // with higher z-index
  for (let index = elements.length - 1; index >= 0; --index) {
    const element = elements[index];
    if (element.isDeleted) {
      continue;
    }
    if (isAtPositionFn(element)) {
      hitElement = element;
      break;
    }
  }

  return hitElement;
};

export const getElementsAtPosition = (
  elements: readonly NonDeletedExcalidrawElement[],
  isAtPositionFn: (element: NonDeletedExcalidrawElement) => boolean,
) => {
  const embeddables: ExcalidrawEmbeddableElement[] = [];
  // The parameter elements comes ordered from lower z-index to higher.
  // We want to preserve that order on the returned array.
  // Exception being embeddables which should be on top of everything else in
  // terms of hit testing.
  const elsAtPos = elements.filter((element) => {
    const hit = !element.isDeleted && isAtPositionFn(element);
    if (hit) {
      if (isEmbeddableElement(element)) {
        embeddables.push(element);
        return false;
      }
      return true;
    }
    return false;
  });
  return elsAtPos.concat(embeddables);
};

export const getElementContainingPosition = (
  elements: readonly ExcalidrawElement[],
  x: number,
  y: number,
  excludedType?: ExcalidrawElement["type"],
) => {
  let hitElement = null;
  // We need to to hit testing from front (end of the array) to back (beginning of the array)
  for (let index = elements.length - 1; index >= 0; --index) {
    if (elements[index].isDeleted) {
      continue;
    }
    const [x1, y1, x2, y2] = getElementAbsoluteCoords(elements[index]);
    if (
      x1 < x &&
      x < x2 &&
      y1 < y &&
      y < y2 &&
      elements[index].type !== excludedType
    ) {
      hitElement = elements[index];
      break;
    }
  }
  return isTextBindableContainer(hitElement, false) ? hitElement : null;

};
