// @ts-nocheck
import React from "react";
import Slider from "react-slick";
import "./ImageSliderBanner.scss";

export default function SimpleSlider() {
  var settings = {
    dots:false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000
  };
  return (
    <Slider {...settings}>
      <div className="dataSliderTop">
        <h1>One stop for <br/> <b>collaborating </b>across the world</h1>
        <p>
          A collaborative whiteboard platform to bring
          teams together, anytime, anywhere.
        </p>
      </div>
      <div className="dataSliderTop">
        <h1>One stop for <br/> <b style={{color: "#35BC5B"}}>Designing </b>across the world</h1>
        <p>
        Join the eFLok community and accelerate your projects with powerful collaboration tool
        </p>
      </div>
      <div className="dataSliderTop">
        <h1>One stop for <br/> <b style={{color: "#4035BC"}}>Building  </b>across the world</h1>
        <p>
          Bring your team together and work seamlessly with eFLok's all-in-one collaboration platform
        </p>
      </div>
    </Slider>
  );
}
