import React from "react";
import "./ImageSliderClient.scss";

export const ImageSliderClient: React.FC = () => {
  return (
    <div
      id="carouselExampleIndicators"
      className="carousel slide"
      data-bs-ride="carousel"
    >
      <div className="carousel-indicators newslider">
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="0"
          className="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="3"
          aria-label="Slide 4"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="4"
          aria-label="Slide 5"
        ></button>
      </div>
      <div className="carousel-inner">
        <div className="carousel-item active">
          <div className="userInfoDataSlider">
            <div className="innerSlider">
              {/* <img
                src={`${window.location.origin}/shivani.png`}
                alt="EfloK canvas board"
              /> */}
              <div className="contentDataUsernew">
                <p>"eFlok is a game-changer for remote teams. The real-time collaboration and brainstorming features have made our meetings and workshops more productive and engaging. I can't imagine working without it now!" </p>
                <div className="userNamedata">
                  <div className="borderData"></div>
                  <h4>Shivani Khare</h4>
                  <h6>Product Manager at University of Emerging Technologies</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="carousel-item">
          <div className="userInfoDataSlider">
            <div className="innerSlider ownerImg">
              {/* <img
                src={`${window.location.origin}/ownerImg.jpeg`}
                alt="EfloK canvas board"
              /> */}
              <div className="contentDataUsernew">
                <p>" eFlok has revolutionized the way my team collaborates on projects. The integration with our LMS and productivity tools has made our workflow seamless. I highly recommend it! –"</p>
                <div className="userNamedata">
                  <div className="borderData"></div>
                  <h4>Gopi K</h4>
                  <h6>Project Manager at Data Magnum.</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="carousel-item">
          <div className="userInfoDataSlider">
            <div className="innerSlider ownerImg">
              {/* <img
                src={`${window.location.origin}/ownerImg.jpeg`}
                alt="EfloK canvas board"
              /> */}
              <div className="contentDataUsernew">
                <p>"eFlok has been a game changer for our group projects in college. The collaborative whiteboard and diagramming tools have made it easy for us to brainstorm ideas and stay on track with our progress. It's like having a virtual workspace that we can access from anywhere. Highly recommend!" –"</p>
                <div className="userNamedata">
                  <div className="borderData"></div>
                  <h4>Rebecaa M</h4>
                  <h6>College Student</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="carousel-item">
          <div className="userInfoDataSlider">
            <div className="innerSlider ownerImg">
              {/* <img
                src={`${window.location.origin}/ownerImg.jpeg`}
                alt="EfloK canvas board"
              /> */}
              <div className="contentDataUsernew">
                <p>"As a high school student, I've found eFlok to be an incredibly useful tool for my online classes. The video conferencing feature allows me to participate in group discussions and presentations from the comfort of my own home. The platform is easy to use and has really helped me stay organized and engaged with my classmates and teachers." –" </p>
                <div className="userNamedata">
                  <div className="borderData"></div>
                  <h4>David Jones</h4>
                  <h6>High School Student</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="carousel-item">
          <div className="userInfoDataSlider">
            <div className="innerSlider ownerImg">
              {/* <img
                src={`${window.location.origin}/ownerImg.jpeg`}
                alt="EfloK canvas board"
              /> */}
              <div className="contentDataUsernew">
                <p>Our online university has been using eFlok for the past year and it has transformed the way we collaborate and work together as a team. The integration with our Learning Management System has made it easy for students and faculty to seamlessly access and share information. We highly recommend eFlok to anyone looking for a comprehensive collaboration platform. – </p>
                <div className="userNamedata">
                  <div className="borderData"></div>
                  <h4>Prasad Mavuduri</h4>
                  <h6>University of Emerging Technologies</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
