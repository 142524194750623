// @ts-nocheck
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { AuthGuardedRoute } from "../guards/authGuard/AuthGuardedRoute";
import loadable from "@loadable/component";
import "./MainApp.scss";
import { DashboardLayout } from "./dashboard/dashboardlayout";
import { CUSTOM_EVENTS, ROUTES } from "../constants/constants";
import { Homescreen } from "../pages/home/home";
import { ProfileLayout } from "./profile/profilelayout";
import { APIService } from "../services/api/api-service";
import { CommonNotification } from "./common-notification/CommonNotification";
import { LTI } from "../components/Lti/Lti";
import { ThirdParty } from "./third-party/ThirdParty";
import { BotGuest } from "../pages/BotGuest";
import { TeamProvider } from "../context/TeamContext";
import { GoogleCalendarCallback } from "./callbacks/GoogleCalendarCallback";
import { OutlookCalendarCallback } from "./callbacks/OutlookCalendarCallback";
import { Okta } from "../components/Okta/Okta";
const ErrorPage = loadable(() => import("../pages/Error"));
const OktaSamlHelp = loadable(() => import("../pages/okta/ConfigurationStep"));

const ExcalidrawApp = loadable(() => import("../excalidraw-app"), {
  fallback: <div className="loader"/>,
});
const University = loadable(() => import("./university/login/University"));
const PasswordReset = loadable(() => import("./auth/password-reset/PasswordReset"));
const Guestscreen = loadable(() => import("../pages/guest/Guest"));
const TermsLayout = loadable(() => import("./terms/terms"));
const PrivacyLayout = loadable(() => import("./privacy/privacy"));
const ContactPage = loadable(() => import("./contact"));
const transformLearningNew = loadable(() => import("./transformLearning/transformLearning"));
const transformLearningNew1 = loadable(() => import("./transformLearning1/transformLearning1"));
const transformLearningNew2 = loadable(() => import("./transformLearning2/transformLearning2"));
const transformLearningNew3 = loadable(() => import("./transformLearning3/transformLearning3"));
const transformLearningNew4 = loadable(() => import("./transformLearning4/transformLearning4"));
const transformLearningNew5 = loadable(() => import("./transformLearning5/transformLearning5"));
const productDesign = loadable(() => import("./productDesign/productDesign"));
const productConsult = loadable(() => import("./productConsult/productConsult"));
const productProfessors = loadable(() => import("./productProfessors/productProfessors"));
const productStudent = loadable(() => import("./productStudent/productStudent"));
const productITNew = loadable(() => import("./productIT/productIT"));
const productExecutives = loadable(() => import("./productExecutives/productExecutives"));
const productMarketing  = loadable(() => import("./productMarketing/productMarketing"));

const transformBoostNew = loadable(() => import("./transformBoost/transformBoost"));
const HelpPage = loadable(() => import("./help"));
const AllVideo = loadable(() => import("./allVideo"));
const ZoomDocumentation = loadable(() => import("./zoomDocumentation"));
// const ThirdParty = loadable(() => import("./third-party/ThirdParty"));
const PricingLayout = loadable(() => import("./pricing/pricing"));

const RecentBoardlayout = loadable(() => import("./recent-board/recentboardlayout"));
const InvitationBoard = loadable(() => import("./invitation-board/invitationboardlayout"));
const TrashBoard = loadable(() => import("./trash-board/trashBoardLayout"));
const ProctoringBoard = loadable(() => import("./proctoring-board/ProctoringBoardlayout"));
const OrganisationUserslayout = loadable(() => import("./organisationUsers/organisationUserslayout"));
const OrganisationInvitationlayout = loadable(() => import("./organisationInvitation/organisationInvitationlayout"));
const OrganisationProfilelayout = loadable(() => import("./organisationProfile/organisationProfilelayout"));
const Projectlayout = loadable(() => import("./project/projectlayout"));
const CookiesLayout = loadable(() => import("./CookiesContent"));
const OrganisationUserIntegration = loadable(() => import("./organisationUserIntegration/OrganisationUserInteg"));
const SSOLogin = loadable(() => import("./ssoLogin"));
const HelpSearchPage = loadable(() => import("./helpSearch"));

export const MainApp: React.FC = () => {
  // Function to clear complete cache data

  const [isNotificaiton, setIsNotification] = useState<boolean>(false);
  const [notification, setNotification] = useState({
    message: "",
    type: "",
    timestamp: ""
  });


  const clearCacheData = () => {
    if (
      process.env.REACT_APP_APP_VERSION !== APIService.getLastVersionLoaded()
    ) {
      APIService.setLastVersionLoaded(process.env.REACT_APP_APP_VERSION);
      // eslint-disable-next-line no-console
      console.log("___CLEARING_CACHE");
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
      window.location.reload();
    } else {
      // eslint-disable-next-line no-console
      console.log(
        process.env.REACT_APP_APP_VERSION,
        "==",
        APIService.getLastVersionLoaded(),
      );
    }
  };

  useEffect(() => {
    document.addEventListener(CUSTOM_EVENTS.NOTIFICATION, (event: any) => {
      if (event?.detail && event?.detail?.message) {
        setNotification({ ...event?.detail, timestamp: Date.now() });
        setIsNotification(true);
        setTimeout(() => {
          setIsNotification(false);
        }, 3000);
      }
    });
  }, []);

  useEffect(() => {
    clearCacheData();
  }, []);

  return (
    <>
      {isNotificaiton && (
        <CommonNotification
          message={notification.message}
          type={notification.type}
          timestamp={notification.timestamp}
          closeNotification={setIsNotification}
        />
      )}
      <main>
        <BrowserRouter>
          <Switch>
            <Route exact path={ROUTES.THIRD_PARTY_LOGIN} component={ThirdParty} />
            <Route exact path={ROUTES.GOOGLE_CALENDAR_CALLBACK} component={GoogleCalendarCallback} />
            <Route exact path={ROUTES.MICROSOFT_CALLBACK} component={OutlookCalendarCallback} />
            <Route exact path={ROUTES.LTI} component={LTI} />
            <Route exact path={ROUTES.OKTA} component={Okta} />
            <Route exact path={ROUTES.BOTSHAREBOARD} component={BotGuest} />
            <Route exact path={ROUTES.ROOT} component={Homescreen} />
            <Route exact path={ROUTES.ERROR} component={ErrorPage} />
            <Route exact path={ROUTES.OKTA_HELP} component={OktaSamlHelp} />
            <Route exact path={ROUTES.GUEST_USER} component={Guestscreen} />
            <Route exact path={ROUTES.PASSWORD_LINK} component={PasswordReset} />
            <Route exact path={ROUTES.UNIVERSITY_LINK} component={University} />
            <Route exact path={ROUTES.PRICING} component={PricingLayout} />
            <Route exact path={ROUTES.TERMS} component={TermsLayout} />
            <Route exact path={ROUTES.PRIVACY} component={PrivacyLayout} />
            <Route exact path={ROUTES.LEARNING} component={transformLearningNew} />
            <Route exact path={ROUTES.LEARNING1} component={transformLearningNew1} />
            <Route exact path={ROUTES.LEARNING2} component={transformLearningNew2} />
            <Route exact path={ROUTES.LEARNING3} component={transformLearningNew3} />
            <Route exact path={ROUTES.LEARNING4} component={transformLearningNew4} />
            <Route exact path={ROUTES.LEARNING5} component={transformLearningNew5} />
            <Route exact path={ROUTES.product_design} component={productDesign} />
            <Route exact path={ROUTES.product_consult} component={productConsult} />
            <Route exact path={ROUTES.product_professors} component={productProfessors} />
            <Route exact path={ROUTES.product_student} component={productStudent} />
            <Route exact path={ROUTES.product_it} component={productITNew} />
            <Route exact path={ROUTES.product_executives} component={productExecutives} />
            <Route exact path={ROUTES.product_marketing_and_creative} component={productMarketing} />
            <Route exact path={ROUTES.BOOST} component={transformBoostNew} />
            <Route exact path={ROUTES.CONTACT} component={ContactPage} />
            <Route exact path={ROUTES.HELP} component={HelpPage} />
            <Route exact path={ROUTES.ALLVIDEO} component={AllVideo} />
            <Route exact path={ROUTES.ZOOMDOCUMENTATION} component={ZoomDocumentation} />
            <Route exact path={ROUTES.COOKIES} component={CookiesLayout} />
            <Route exact path={ROUTES.ORGANISATION_INTEGRATION} component={OrganisationUserIntegration} />
            <Route exact path={ROUTES.SSO_LOGIN} component={SSOLogin} />
            <Route exact path={ROUTES.HELP_SEARCH} component={HelpSearchPage} />
            <AuthGuardedRoute path={ROUTES.COLLAB_ROOM}>
              <ExcalidrawApp />
            </AuthGuardedRoute>
            <AuthGuardedRoute path={ROUTES.SHAREBOARD}>
              <ExcalidrawApp />
            </AuthGuardedRoute>
            <TeamProvider>
              <AuthGuardedRoute path={ROUTES.RECENT_BOARDS}>
                <RecentBoardlayout />
              </AuthGuardedRoute>
              <AuthGuardedRoute path={ROUTES.INVITATION_BOARDS}>
                <InvitationBoard />
              </AuthGuardedRoute>
              <AuthGuardedRoute path={ROUTES.TRASH_BOARDS}>
                <TrashBoard />
              </AuthGuardedRoute>
              <AuthGuardedRoute path={ROUTES.PROCTORING_BOARDS}>
                <ProctoringBoard />
              </AuthGuardedRoute>
              <AuthGuardedRoute path={ROUTES.ORGANISATION_USERS}>
                <OrganisationUserslayout />
              </AuthGuardedRoute>
              <AuthGuardedRoute path={ROUTES.ORGANISATION_INVITATION}>
                <OrganisationInvitationlayout />
              </AuthGuardedRoute>
              <AuthGuardedRoute path={ROUTES.ORGANISATION_PROFILE}>
                <OrganisationProfilelayout />
              </AuthGuardedRoute>
              <AuthGuardedRoute path={ROUTES.DASHBOARD}>
                <DashboardLayout />
              </AuthGuardedRoute>
              {/*<AuthGuardedRoute path={ROUTES.TEAM_PROFILE}>*/}
              {/*  <TeamLayout />*/}
              {/*</AuthGuardedRoute>*/}
              {/*<AuthGuardedRoute path={ROUTES.TEAM_USERS}>*/}
              {/*  <TeamUserslayout />*/}
              {/*</AuthGuardedRoute>*/}
              <AuthGuardedRoute path={ROUTES.SELECTED_PROJECT}>
                <Projectlayout />
              </AuthGuardedRoute>
            </TeamProvider>
            <AuthGuardedRoute path={ROUTES.PROFILE}>
              <ProfileLayout />
            </AuthGuardedRoute>
            <Route
              path="*"
              render={() => {
                return <Redirect to="/" />;
              }}
            />
          </Switch>
        </BrowserRouter>
      </main>
    </>
  );
};
