import { ALERT_TYPE, CONSTANT, ROLE_LIST, ORGANISATION_ACCESS } from "../../../constants/constants";
import { URLS } from "../../../constants/urls";
import { HTTP_RESPONSE } from "../../../enums/http-responses.enum";
import { APIService } from "../../api/api-service";
import { UtilityService } from "../../common/utilityService";
import { DeleteTeam, AddTeam, AddTeamResponse, AddTeamMembers, searchOrganisations } from "../../../models/team-group.model";
import { CommonSocketService } from "../../socket-services/common-socket.service";
import { getUserDetails } from "../../auth-service";
import { IRoomModel } from "../../../models/room.model";


//(CheckOnlyRoles:1 =>Check Roles And Org Access, checkOnlyRoles:2 =>Check only roles)
export const checkOrgAccess = (organisation: any, checkOnlyRoles: any = 1) => {
  return getCurrentUserOrgApi().then((response: any) => {
    if (response.data && response.data.length > 0 && response.status) {
      var currentUserRole = response.data.filter((cOrg: any) => cOrg?.organisationId?._id == organisation?.organisationId?._id);

      if (currentUserRole && currentUserRole[0]?.role === ROLE_LIST.ADMIN) {
        return true;
      } else {
        if (checkOnlyRoles == 2) {
          return false;
        }
        if (currentUserRole && currentUserRole[0]?.org_access === ORGANISATION_ACCESS.FULL_ACCESS) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  })
};

export const checkProjectAccess = () => {
  return false; //pendding

};

/*Teams API*/
export const addTeam = async (eventInfo: AddTeam) => {
  //UtilityService.showNotification(ALERT_TYPE.ERROR, "Hi");
  try {
    const config = {};
    const formData = { "name": eventInfo.name, "organisation_id": eventInfo.organisation_id };
    const response = await APIService.Instance.post(URLS.ADD_TEAMS, formData, config);
    let respdata: AddTeamResponse;

    if (response.status === HTTP_RESPONSE.SUCCESS) {
      UtilityService.showNotification(ALERT_TYPE.SUCCESS, response.data.message);
      return response.data;
    }

    if (response.status === HTTP_RESPONSE.CREATED) {
      UtilityService.showNotification(ALERT_TYPE.ERROR, response.data.message);
      return response.data;
    }

    return [];
  } catch (err) {
    UtilityService.showNotification(ALERT_TYPE.ERROR, "Somthing went wrong.");
    console.log("team-service.ts err", err);
    return false;
  }
};

export const getAllTeamList = async (organisationId: string) => {
  try {
    //const response = await APIService.Instance.get(`${URLS.GET_TEAMS_LIST}`,);
    if (!organisationId) {
      return [];
    }
    const response = await APIService.Instance.get(`${URLS.GET_TEAMS_LIST}?org_id=${organisationId}`,);
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const apiResponse = response.data;
      UtilityService.showNotification(ALERT_TYPE.SUCCESS, apiResponse.message);
      return apiResponse;
    }
    return [];
  } catch (error: any) {
    console.log("Error Fetching Profile Details", error);
    if (error.response) {
      UtilityService.showNotification(ALERT_TYPE.ERROR, error.response.data.message);
    } else {
      UtilityService.showNotification(ALERT_TYPE.ERROR, "something went wrong");
    }
    return false;
  }
};

export const addTeamMembers = async (eventInfo: AddTeamMembers) => {
  try {
    const config = {};
    const response = await APIService.Instance.post(URLS.ADD_TEAM_MEMBERS, eventInfo, config);
    let respdata: AddTeamResponse;
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      return response.data;
    }
    return [];
  } catch (err) {
    // TODO add error handling
    console.log("team-service.ts err", err);
    return false;
  }
};

export const setupSSOConfig = async (data) => {
  try {
    const config = {};
    const response = await APIService.Instance.post(URLS.SETUP_SSO, data, config);
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      return response.data;
    }
    return [];
  } catch (err) {
    if (err.response) {
      UtilityService.showNotification(ALERT_TYPE.ERROR, err.response.data.message);
    } else {
      UtilityService.showNotification(ALERT_TYPE.ERROR, "something went wrong");
    }
    console.log("save sso config err", err);
    return false;
  }
};


export const checkSSO = async (data) => {
  try {
    const config = {};
    const response = await APIService.Instance.post(
      URLS.CHECK_SSO,
      data,
      config,
    );
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      return response.data;
    }
    return [];
  } catch (err) {
    if (err.response) {
      UtilityService.showNotification(
        ALERT_TYPE.ERROR,
        err.response.data.message,
      );
    } else {
      UtilityService.showNotification(ALERT_TYPE.ERROR, "something went wrong");
    }
    console.log("save sso config err", err);
    return false;
  }
};

export const getSSODetails = async (data) => {
  try {
    const config = {};
    const response = await APIService.Instance.post(
      URLS.GET_SSO_DETAILS,
      data,
      config,
    );
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      return response.data;
    }
    return [];
  } catch (err) {
    if (err.response) {
      UtilityService.showNotification(
        ALERT_TYPE.ERROR,
        err.response.data.message,
      );
    } else {
      UtilityService.showNotification(ALERT_TYPE.ERROR, "something went wrong");
    }
    console.log("save sso config err", err);
    return false;
  }
};

export const getAllUserByOrganization = async (organisationId: string) => {
  try {
    if (!organisationId) {
      return [];
    }
    const response = await APIService.Instance.get(`${URLS.GET_USERS_LIST}?org_id=${organisationId}`,);
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const apiResponse = response.data;
      UtilityService.showNotification(ALERT_TYPE.SUCCESS, apiResponse.message);
      return apiResponse;
    }
    return [];
  } catch (error: any) {
    console.log("Error Fetching user list getting", error);
    if (error.response) {
      UtilityService.showNotification(ALERT_TYPE.ERROR, error.response.data.message);
    } else {
      UtilityService.showNotification(ALERT_TYPE.ERROR, "something went wrong");
    }
    return false;
  }
};

export const getAllUserOrganizationInvitation = async (organisationId: string) => {
  try {
    if (!organisationId) {
      return [];
    }
    const response = await APIService.Instance.get(`${URLS.GET_ORG_INVITATION_LIST}?org_id=${organisationId}`,);
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const apiResponse = response.data;
      return apiResponse;
    }
    return [];
  } catch (error: any) {
    console.log("Error Fetching user list getting", error);
    if (error.response) {
      UtilityService.showNotification(ALERT_TYPE.ERROR, error.response.data.message);
    } else {
      UtilityService.showNotification(ALERT_TYPE.ERROR, "something went wrong");
    }
    return false;
  }
};

export const deleteTeamApi = async (eventInfo: DeleteTeam) => {
  console.log("eventInfo eventInfo", eventInfo);
  try {
    const response = await APIService.Instance.delete(
      `${URLS.DELETE_TEAMS}?team_id=${eventInfo.team_id}`,
    );
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const data = response.data;
      if (data.status) {
        UtilityService.showNotification(
          ALERT_TYPE.SUCCESS,
          "Team Deleted Successfully",
        );
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);
      } else {
        UtilityService.showNotification(ALERT_TYPE.ERROR, data.message);
      }
    }
    return response;
  } catch (err) {
    // TODO add error handling

    UtilityService.showNotification(ALERT_TYPE.ERROR, "Delete team Failed");
    console.log("team-service.ts err", err);
    return false;
  }
};

export const updateTeamName = async (payload: any) => {
  try {
    const response = await APIService.Instance.put(
      URLS.UPDATE_TEAM_NAME,
      payload,
    );
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const apiResponse: any = response.data;
      UtilityService.showNotification(
        ALERT_TYPE.SUCCESS,
        apiResponse.message || "",
      );
      return apiResponse;
    }
    return null;
  } catch (err) {
    // TODO add error handling
    UtilityService.showNotification(
      ALERT_TYPE.ERROR,
      "Error update team name",
    );
  }
};

export const uploadImage = async (eventInfo: any) => {
  try {
    const config = {};
    const response = await APIService.Instance.post(URLS.UPLOAD_TEAM_LOGO, eventInfo, config);
    let respdata: AddTeamResponse;
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      return response.data;
    }
    return [];
  } catch (err) {
    // TODO add error handling
    console.log("team-service.ts err", err);
    return false;
  }
};

export const leaveTeamMember = async (eventInfo: any) => {
  try {
    const config = {};
    const response = await APIService.Instance.post(URLS.LEAVE_TEAM_MEMBER, eventInfo, config);
    let respdata: AddTeamResponse;
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      return response.data;
    }
    return [];
  } catch (err) {
    // TODO add error handling
    console.log("team-service.ts err", err);
    return false;
  }
};

export const transferTeamOwnership = async (eventInfo: any) => {
  try {
    const config = {};
    const response = await APIService.Instance.post(
      URLS.TRANSFER_TEAM_OWNERSHIP,
      eventInfo,
      config,
    );
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      if (response.data.status) {
        UtilityService.showNotification(ALERT_TYPE.SUCCESS, response.data.message);
      } else {
        UtilityService.showNotification(ALERT_TYPE.INFO, response.data.message);
      }
      return response.data;
    }
    return [];
  } catch (err) {
    console.log("team-service.ts err", err);
    return false;
  }
};

export const leaveTeamAdminApi = async (eventInfo: any) => {
  try {
    const config = {};
    const response = await APIService.Instance.post(URLS.LEAVE_TEAM_ADMIN, eventInfo, config);
    let respdata: AddTeamResponse;
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      return response.data;
    }
    return [];
  } catch (err) {
    // TODO add error handling
    console.log("team-service.ts err", err);
    return false;
  }
};

export const searchOrganisationsApi = async (eventInfo: searchOrganisations) => {
  try {
    const config = {};
    const response = await APIService.Instance.post(URLS.SEARCH_ORGANISATION, eventInfo, config);
    let respdata: any;
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      return response.data;
    }
    return [];
  } catch (err) {
    console.log("team-service.ts --> searchOrganisationsApi ---> err", err);
    return false;
  }
};

export const addUserOrganisationAndSendMailApi = async (eventInfo: any) => {
  try {
    const config = {};
    const response = await APIService.Instance.post(URLS.USER_ADD_ORGANISATION_AND_SEND_MAIL, eventInfo, config);
    console.log("response 123===>", response);
    let respdata: any;
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      if (response.data.status) {
        UtilityService.showNotification(ALERT_TYPE.SUCCESS, response.data.message);
      } else {
        UtilityService.showNotification(ALERT_TYPE.INFO, response.data.message);
      }

      return response.data;
    }
    return [];
  } catch (err) {
    UtilityService.showNotification(ALERT_TYPE.ERROR, "Somthing went wrong.");
    console.log("team-service.ts --> addUserOrganisationAndSendMailApi ---> err", err);
    return false;
  }
};

export const getCurrentUserOrgApi = async () => {
  try {
    const response = await APIService.Instance.get(`${URLS.GET_CURRENT_USER_ORGANISATION}`,);
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const apiResponse = response.data;
      UtilityService.showNotification(ALERT_TYPE.SUCCESS, apiResponse.message);
      return apiResponse;
    }
    return [];
  } catch (error: any) {
    console.log("Error Fetching get current user org api", error);
    if (error.response) {
      UtilityService.showNotification(ALERT_TYPE.ERROR, error.response.data.message);
    } else {
      UtilityService.showNotification(ALERT_TYPE.ERROR, "something went wrong");
    }
    return false;
  }
};

export const sendOrganisationUserInvitationApi = async (eventInfo: any) => {
  try {
    const config = {};
    const response = await APIService.Instance.post(URLS.INVITE_USER_TO_ORGANISATION, eventInfo, config);
    let respdata: any;
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      UtilityService.showNotification(ALERT_TYPE.SUCCESS, response.data.message);
      return response.data;
    }

    if (response.status === HTTP_RESPONSE.CREATED) {
      UtilityService.showNotification(ALERT_TYPE.ERROR, response.data.message);
    }
    return [];
  } catch (err) {
    UtilityService.showNotification(ALERT_TYPE.ERROR, "Somthing went wrong.");
    console.log("team-service.ts  sendOrganisationUserInvitationApi err", err);
    return false;
  }
};


export const changePasswordApi = async (eventInfo: any) => {
  try {
    const config = {};
    const response = await APIService.Instance.post(URLS.CHANGE_PASSWORD, eventInfo, config);
    let respdata: any;
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      UtilityService.showNotification(ALERT_TYPE.SUCCESS, response.data.message);
      return response.data;
    }

    if (response.status === HTTP_RESPONSE.CREATED) {
      UtilityService.showNotification(ALERT_TYPE.ERROR, response.data.message);
    }
    return [];
  } catch (err) {
    UtilityService.showNotification(ALERT_TYPE.ERROR, "Somthing went wrong.");
    console.log("team-service.ts  sendOrganisationUserInvitationApi err", err);
    return false;
  }
};

export const removeOrganisationFromUser = async (eventInfo: any) => {
  try {
    const config = {};
    const response = await APIService.Instance.post(URLS.DELETE_ORG_FROM_USER, eventInfo, config);
    let respdata: any;
    const currentLoginUser = getUserDetails();
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      UtilityService.showNotification(ALERT_TYPE.SUCCESS, response.data.message);

      CommonSocketService.commonEventService.updateOrganisationNotifications({
        group: "eflok_common_channel",
        _id: currentLoginUser._id,
        name: currentLoginUser.name,
        userName: currentLoginUser.username,
        data: response.data.data.users,
      });
      return response.data.data.data;
    }
    return [];
  } catch (err) {
    // TODO add error handling
    UtilityService.showNotification(ALERT_TYPE.ERROR, "Somthing went wrong.");
    console.log("team-service.ts removeOrganisationFromUser err", err);
    return false;
  }
};

export const removeOrganisationFromUserAdmin = async (eventInfo: any) => {
  try {
    const config = {};
    const response = await APIService.Instance.post(URLS.DELETE_ORG_FROM_USER_ADMIN, eventInfo, config);
    let respdata: any;
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const currentLoginUser = getUserDetails();
      UtilityService.showNotification(ALERT_TYPE.SUCCESS, response.data.message);
      CommonSocketService.commonEventService.updateOrganisationNotifications({
        group: "eflok_common_channel",
        _id: currentLoginUser._id,
        name: currentLoginUser.name,
        userName: currentLoginUser.username,
        data: response.data.data.users,
      });
      return response.data.data.data;
    }
    return [];
  } catch (err) {
    // TODO add error handling
    UtilityService.showNotification(ALERT_TYPE.ERROR, "Somthing went wrong.");
    console.log("team-service.ts removeOrganisationFromUserAdmin err", err);
    return false;
  }
};

export const getIsFavouriteBoardApi = async (organisationId: string) => {
  try {
    const response = await APIService.Instance.get(`${URLS.GET_IS_FAVOURITE_BOARD}?organisation_id=${organisationId}`,);
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const apiResponse = response.data;
      //UtilityService.showNotification(ALERT_TYPE.SUCCESS, apiResponse.message);
      return apiResponse;
    }
    return [];
  } catch (error: any) {
    console.log("Error Fetching user list getting", error);
    if (error.response) {
      UtilityService.showNotification(ALERT_TYPE.ERROR, error.response.data.message);
    } else {
      UtilityService.showNotification(ALERT_TYPE.ERROR, "something went wrong");
    }
    return false;
  }
};

export const addOrganisationApi = async (eventInfo: any) => {
  try {
    const config = {};
    const response = await APIService.Instance.post(URLS.ADD_ORGANISATION, eventInfo, config);
    console.log("response 123===>", response);
    let respdata: any;
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      if (response.data.status) {
        UtilityService.showNotification(ALERT_TYPE.SUCCESS, response.data.message);
      } else {
        UtilityService.showNotification(ALERT_TYPE.ERROR, response.data.message);
      }
      return response.data;
    }
    return [];
  } catch (err) {
    UtilityService.showNotification(ALERT_TYPE.ERROR, "Somthing went wrong.");
    console.log("team-service.ts --> addOrganisationApi ---> err", err);
    return false;
  }
}

export const getNotificationList = async () => {
  try {
    const config = {};
    const response = await APIService.Instance.get(URLS.GET_NOTIFICATION, config);
    let respdata: any;

    if (response.status === HTTP_RESPONSE.SUCCESS) {
      return response.data;
    }
    return [];
  } catch (err) {
    UtilityService.showNotification(ALERT_TYPE.ERROR, "Something went wrong.");
    return false;
  }
};

export const readNotification = async () => {
  try {
    const config = {};
    const response = await APIService.Instance.post(URLS.READ_NOTIFICATION, {}, config);
    let respdata: any;

    if (response.status === HTTP_RESPONSE.SUCCESS) {
      return response.data;
    }
    return [];
  } catch (err) {
    UtilityService.showNotification(ALERT_TYPE.ERROR, "Something went wrong.");
    return false;
  }
};

export const updateOrgName = async (payload: any) => {
  try {
    const response = await APIService.Instance.post(
      URLS.UPDATE_ORG_NAME,
      payload,
    );
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const apiResponse: any = response.data;
      UtilityService.showNotification(
        ALERT_TYPE.SUCCESS,
        apiResponse.message || "",
      );
      return apiResponse;
    }
    return null;
  } catch (err) {
    // TODO add error handling
    UtilityService.showNotification(
      ALERT_TYPE.ERROR,
      "Error update org name",
    );
  }
};

export const uploadOrgImage = async (eventInfo: any) => {
  try {
    const config = {};
    const response = await APIService.Instance.post(URLS.UPLOAD_ORG_LOGO, eventInfo, config);
    let respdata: AddTeamResponse;
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      return response.data;
    }
    return [];
  } catch (err) {
    // TODO add error handling
    console.log("team-service.ts  uploadOrgImage err", err);
    return false;
  }
};

export const deleteOrgApi = async (eventInfo: any) => {
  console.log("eventInfo eventInfo", eventInfo);
  try {
    const response = await APIService.Instance.post(
      URLS.DELET_ORG,
      eventInfo,
    );
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const data = response.data;
      if (data.status) {
        UtilityService.showNotification(
          ALERT_TYPE.SUCCESS,
          "Organisation Deleted Successfully",
        );
      } else {
        UtilityService.showNotification(ALERT_TYPE.ERROR, data.message);
      }
    }
    return response;
  } catch (err) {
    UtilityService.showNotification(ALERT_TYPE.ERROR, "Delete organisation Failed");
    console.log("team-service.ts deleteOrgApi err", err);
    return false;
  }
};

export const deleteOrgImage = async (eventInfo: any) => {
  console.log("eventInfo eventInfo", eventInfo);
  try {
    const response = await APIService.Instance.post(
      URLS.DELET_ORG_IMAGE,
      eventInfo,
    );
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const data = response.data;
      if (data.status) {
        UtilityService.showNotification(
          ALERT_TYPE.SUCCESS,
          "Image Deleted Successfully",
        );
      } else {
        UtilityService.showNotification(ALERT_TYPE.ERROR, data.message);
      }
    }
    return response;
  } catch (err) {
    UtilityService.showNotification(ALERT_TYPE.ERROR, "Delete Image Failed");
    return false;
  }
};

export const changeOrganisationAccessFromUser = async (eventInfo: any) => {
  try {
    const config = {};
    const response = await APIService.Instance.post(URLS.CHANGE_ORG_ACCESS_FROM_USER, eventInfo, config);
    let respdata: any;
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      UtilityService.showNotification(ALERT_TYPE.SUCCESS, response.data.message);
      return response.data;
    }
    return [];
  } catch (err) {
    // TODO add error handling
    UtilityService.showNotification(ALERT_TYPE.ERROR, "Somthing went wrong.");
    console.log("team-service.ts changeOrganisationAccessFromUser err", err);
    return false;
  }
};

export const getScheduleMeetingListApi = async (organisationId: string, selectedDate: any) => {
  try {
    //const response = await APIService.Instance.get(`${URLS.GET_TEAMS_LIST}`,);
    if (!organisationId) {
      return [];
    }
    const response = await APIService.Instance.get(`${URLS.GET_SCHEDULE_MEETING_LIST}?org_id=${organisationId}&selectedDate=${selectedDate}`,);
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const apiResponse = response.data;
      return apiResponse;
    }
    return [];
  } catch (error: any) {
    console.log("Error Fetching Schedule Metting", error);
    if (error.response) {
      UtilityService.showNotification(ALERT_TYPE.ERROR, error.response.data.message);
    } else {
      UtilityService.showNotification(ALERT_TYPE.ERROR, "something went wrong");
    }
    return false;
  }
};

export const getMonthlyScheduleMeetingListApi = async (
  startDate: any,
  endDate: any,
) => {
  try {
    // if (!organisationId) {
    //   return [];
    // }
    const response = await APIService.Instance.get(`${URLS.GET_MONTHLY_SCHEDULE_MEETING_LIST}?startDate=${startDate}&endDate=${endDate}`,);
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const apiResponse = response.data;
      return apiResponse;
    }
    return [];
  } catch (error: any) {
    console.log("Error Fetching Schedule Metting", error);
    if (error.response) {
      UtilityService.showNotification(ALERT_TYPE.ERROR, error.response.data.message);
    } else {
      UtilityService.showNotification(ALERT_TYPE.ERROR, "something went wrong");
    }
    return false;
  }
};

export const getGoogleCalenderEventsApi = async (startDate: any, endDate: any) => {
  try {
    // if (!organisationId) {
    //   return [];
    // }
    const response = await APIService.Instance.get(`${URLS.GET_GOOGLE_CALENDAR_EVENTS}?startDate=${startDate}&endDate=${endDate}`,);
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const apiResponse = response.data;
      return apiResponse;
    }
    return [];
  } catch (error: any) {
    console.log("Error fetching data", error);
    if (error.response) {
      // UtilityService.showNotification(
      //   ALERT_TYPE.ERROR,
      //   error.response.data.message,
      // );
    } else {
      UtilityService.showNotification(ALERT_TYPE.ERROR, "something went wrong.");
    }
    return false;
  }
}

export const getOutlookCalenderEventsApi = async (startDate: any, endDate: any) => {
  try {
    // if (!organisationId) {
    //   return [];
    // }
    const response = await APIService.Instance.get(`${URLS.GET_OUTLOOK_CALENDAR_EVENTS}?startDate=${startDate}&endDate=${endDate}`);
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const apiResponse = response.data;
      return apiResponse;
    }
    return [];
  } catch (error: any) {
    console.log("Error fetching data", error);
    if (error.response) {
      // UtilityService.showNotification(
      //   ALERT_TYPE.ERROR,
      //   error.response.data.message,
      // );
    } else {
      UtilityService.showNotification(ALERT_TYPE.ERROR, "something went wrong.");
    }
    return false;
  }
}

export const moveBoard = async (eventInfo: any) => {
  try {
    const config = {};
    const response = await APIService.Instance.post(URLS.MOVE_BOARD, eventInfo, config);
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      return response.data;
    }
    return [];
  } catch (err) {
    // TODO add error handling
    UtilityService.showNotification(ALERT_TYPE.ERROR, "Somthing went wrong.");
    console.log("team-service.ts moveBoard err", err);
    return false;
  }
};


export const sendOrganisationUserInvitationApiCSV = async (eventInfo: any) => {
  try {
    const config = {};
    const response = await APIService.Instance.post(URLS.INVITE_USER_TO_ORGANISATION_CSV, eventInfo, config);
    let respdata: any;
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      UtilityService.showNotification(ALERT_TYPE.SUCCESS, response.data.message);
      return response.data;
    }

    if (response.status === HTTP_RESPONSE.CREATED) {
      UtilityService.showNotification(ALERT_TYPE.ERROR, response.data.message);
    }
    return [];
  } catch (err) {
    UtilityService.showNotification(ALERT_TYPE.ERROR, "Somthing went wrong.");
    console.log("team-service.ts  sendOrganisationUserInvitationApi err", err);
    return false;
  }
};

export const startTeamBoard = async (
  eventInfo: any,
  members: any,
  permissionType: any,
) => {
  try {
    const config = {};
    const formData = new FormData();
    formData.append("eventName", eventInfo.eventName);
    formData.append("eventDescription", eventInfo.eventDescription);
    formData.append("eventDate", eventInfo.eventDate);
    formData.append("organisation_id", eventInfo.organisation_id);
    formData.append("team_id", "");
    formData.append("project_id", eventInfo?.project_id ? eventInfo.project_id : "");
    formData.append("org_project_id", eventInfo?.org_project_id ? eventInfo.org_project_id : "");
    formData.append("added_users", members);
    formData.append("permissionType", permissionType);

    const response = await APIService.Instance.post(
      URLS.ROOM_CREATE_FROM_TEAM,
      formData,
      config,
    );

    let respdata: IRoomModel;
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      respdata = response.data.roomInfo;
      respdata.boardLink = response.data.boardLink;
      return respdata;
    }
    return [];
  } catch (err: any) {
    console.log("err:: ", err.response);
    if (err.response) {
      UtilityService.showNotification(ALERT_TYPE.ERROR, err.response.data.message,);
    } else {
      UtilityService.showNotification(ALERT_TYPE.ERROR, "something went wrong");
    }
    return false;
  }
};
